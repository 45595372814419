import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
    GET_SUBJECT_LIST,
    GET_PARENT_SUBJECTS,
    ADD_NEW_SUBJECT,
    DELETE_SUBJECT,
    GET_SUBJECT_DATA,
    UPDATE_SUBJECT,
    UPDATE_SUBJECT_SUCCESS,
    UPDATE_SUBJECT_FAIL,
    DELETE_SUBJECT_SUCCESS,
    DELETE_SUBJECT_FAIL,
    DELETE_MULTIPLE_SUBJECTS_SUCCESS,
    DELETE_MULTIPLE_SUBJECTS_FAIL,
    DELETE_MULTIPLE_SUBJECTS,
    GET_CONTENT_SUBJECTS,
    GET_SEO_SUBJECTS,
    UPDATE_CONTENT_SUBJECTS_SUCCESS,
    UPDATE_CONTENT_SUBJECTS_FAIL,
    UPDATE_CONTENT_SUBJECTS,
    UPDATE_SEO_SUBJECTS_SUCCESS,
    UPDATE_SEO_SUBJECTS_FAIL,
    UPDATE_SEO_SUBJECTS,
    GET_CONTENT_BOOKS_SEARCH,
    DELETE_SEO_SUBJECT,
    DELETE_SEO_SUBJECT_SUCESS,
    DELETE_SEO_SUBJECT_FAIL,
    DELETE_CONTENT_SUBJECT_SUCESS,
    DELETE_CONTENT_SUBJECT_FAIL,
    DELETE_CONTENT_SUBJECT
} from "./actionType";

import {
    SubjectApiResponseSuccess, SubjectApiResponseError,
    addSubjectSuccess,
    addSubjectFail,
    updateSubjectSuccess,
    updateSubjectFail,
    deleteSubjectSuccess,
    deleteSubjectFail,
    deleteMultipleSubjectsSuccess,
    deleteMultipleSubjectsFail,
    updateContentSubjectsSuccess,
    updateContentSubjectsFail,
    updateSeoSubjectsSuccess,
    updateSeoSubjectsFail,
    deleteSeoSubjectSuccess,
    deleteSeoSubjectFail,
    deleteContentSubjectSuccess,
    deleteContentSubjectFail
} from "./actions";

import { 
    getSubjectListApi, 
    getParentSubjectsApi,
    addNewSubjectData,
    getSubjectDataApi,
    updateSubjectData,
    deleteSubjectData,
    deleteMultipledata,
    getContentSubjectsApi,
    getSeoUrlApi,
    updateContentSubjectsApi,
    updateSeoSubjectsApi,
    getBooksApi,
    deleteSeoUrlApi,
    deleteContentSubjectApi,
} from "../../../helpers/stison_api_helper";

// get subject list
function* getSubjectList({ params }) {
    let filters = {};

    // map object for filter parameters in API
    if (params) Object.entries(params).map(([k,v]) => filters[k] = v);

    try {
        const response = yield call(getSubjectListApi, filters);
        yield put(SubjectApiResponseSuccess(GET_SUBJECT_LIST, response));
    } catch (error) {
        yield put(SubjectApiResponseError(GET_SUBJECT_LIST, error));
    }
}

function* getParentSubjects() {

    const authOrg = JSON.parse(localStorage.getItem("authUserOrganisation"));
    const organisations = (authOrg !== null) ? authOrg : null;
    
    let filters = {};

    if (Array.isArray(authOrg))
        Object.entries(organisations).map(([k,v]) => filters["organisation[]"] = v.OrganisationId);
    else 
        filters["organisation[]"] = authOrg.OrganisationId;

    try {
        const response = yield call(getParentSubjectsApi, filters);
        yield put(SubjectApiResponseSuccess(GET_PARENT_SUBJECTS, response));
    } catch (error) {
        yield put(SubjectApiResponseError(GET_PARENT_SUBJECTS, error));
    }
}

// get single subject 
function* getSubjectData({ payload: subject }) {
    try {
        const response = yield call(getSubjectDataApi, subject);
        yield put(SubjectApiResponseSuccess(GET_SUBJECT_DATA, response));
    } catch (error) {
        yield put(SubjectApiResponseError(GET_SUBJECT_DATA, error));
    }
}


// create
function* onAddSubject({ payload: subject }) {
    try {
        const response = yield call(addNewSubjectData, subject);
        yield put(addSubjectSuccess(response));
    } catch (error) {
        yield put(addSubjectFail(error));
    }
}

// update subject
function* onUpdateSubject({ payload: subject }) {
    try {
        const response = yield call(updateSubjectData, subject);
        yield put(updateSubjectSuccess(UPDATE_SUBJECT_SUCCESS, response));
    } catch (error) {
        yield put(updateSubjectFail(UPDATE_SUBJECT_FAIL, error));
    }
}

// delete subject
function* onDeleteSubject({ payload: subject }) {
    try {
        const response = yield call(deleteSubjectData, subject);
        yield put(deleteSubjectSuccess(DELETE_SUBJECT_SUCCESS, response));
    } catch (error) {
        yield put(deleteSubjectFail(DELETE_SUBJECT_FAIL, error));
    }
}

function* deleteMultipleSubjects({ payload: subjects }) {
    try {
        const response = yield call(deleteMultipledata, subjects);
        yield put(deleteMultipleSubjectsSuccess(DELETE_MULTIPLE_SUBJECTS_SUCCESS, response));
    } catch (error) {
        yield put(deleteMultipleSubjectsFail(DELETE_MULTIPLE_SUBJECTS_FAIL, error));
    }
}

function* getContentSubjects({ params }) {
    let filters = {};

    // map object for filter parameters in API
    if (params) Object.entries(params).map(([k,v]) => filters[k] = v);
    try {
        const response = yield call(getContentSubjectsApi, filters);
        
        yield put(SubjectApiResponseSuccess(GET_CONTENT_SUBJECTS, response));
    } catch (error) {
        yield put(SubjectApiResponseError(GET_CONTENT_SUBJECTS, error));
    }
}

function* getContentBooksSearch({ params }) {
    let filters = {};

    // map object for filter parameters in API
    if (params) Object.entries(params).map(([k,v]) => filters[k] = v);
    try {
        const response = yield call(getBooksApi, filters);
        
        yield put(SubjectApiResponseSuccess(GET_CONTENT_BOOKS_SEARCH, response));
    } catch (error) {
        yield put(SubjectApiResponseError(GET_CONTENT_BOOKS_SEARCH, error));
    }
}

function* getSeoSubjects({ params }) {
    let filters = {};

    // map object for filter parameters in API
    if (params) Object.entries(params).map(([k,v]) => filters[k] = v);
    try {
        const response = yield call(getSeoUrlApi, filters);
        yield put(SubjectApiResponseSuccess(GET_SEO_SUBJECTS, response));
    } catch (error) {
        yield put(SubjectApiResponseError(GET_SEO_SUBJECTS, error));
    }
}

function* updateContentSubjects({ payload: content_subjects }) {

    try {
        const response = yield call(updateContentSubjectsApi, content_subjects);
        yield put(updateContentSubjectsSuccess(UPDATE_CONTENT_SUBJECTS_SUCCESS, response));
    } catch (error) {
        yield put(updateContentSubjectsFail(error));
    }
}

function* updateSeoSubjects({ payload: seo_subjects }) {

    try {
        const response = yield call(updateSeoSubjectsApi, seo_subjects);
        yield put(updateSeoSubjectsSuccess(UPDATE_SEO_SUBJECTS_SUCCESS, response));
    } catch (error) {
        yield put(updateSeoSubjectsFail(UPDATE_SEO_SUBJECTS_FAIL, error));
    }
}

function* deleteContentSubject({ payload: content_subject })
{
    try {
        const response = yield call(deleteContentSubjectApi, content_subject);
        yield put(deleteContentSubjectSuccess(DELETE_CONTENT_SUBJECT_SUCESS, response));
    } catch (error) {
        yield put(deleteContentSubjectFail(DELETE_CONTENT_SUBJECT_FAIL, error));
    }
}

function* deleteSeoSubject({ payload: seo })
{
    try {
        const response = yield call(deleteSeoUrlApi, seo);
        yield put(deleteSeoSubjectSuccess(DELETE_SEO_SUBJECT_SUCESS, response));
    } catch (error) {
        yield put(deleteSeoSubjectFail(DELETE_SEO_SUBJECT_FAIL, error));
    }
}

export function* watchGetSubjectList() {
    yield takeEvery(GET_SUBJECT_LIST, getSubjectList);
}

export function* watchGetParentSubject() {
    yield takeEvery(GET_PARENT_SUBJECTS, getParentSubjects);
}

export function* watchGetSubjectData() {
    yield takeEvery(GET_SUBJECT_DATA, getSubjectData);
}

export function* watchAddSubject() {
    yield takeEvery(ADD_NEW_SUBJECT, onAddSubject);
}

export function* watchUpdateSubject() {
    yield takeEvery(UPDATE_SUBJECT, onUpdateSubject);
}

export function* watchDeleteSubject() {
    yield takeEvery(DELETE_SUBJECT, onDeleteSubject);
}

export function* watchDeleteMultipleSubject() {
    yield takeEvery(DELETE_MULTIPLE_SUBJECTS, deleteMultipleSubjects);
}

export function* watchGetContentSubjects() {
    yield takeEvery(GET_CONTENT_SUBJECTS, getContentSubjects);
}

export function* watchGetContentBooksSearch() {
    yield takeEvery(GET_CONTENT_BOOKS_SEARCH, getContentBooksSearch);
}

export function* watchGetSeoSubjects() {
    yield takeEvery(GET_SEO_SUBJECTS, getSeoSubjects);
}

export function* watchUpdateContentSubjects() {
    yield takeEvery(UPDATE_CONTENT_SUBJECTS, updateContentSubjects);
}

export function* watchUpdateSeoSubjects() {
    yield takeEvery(UPDATE_SEO_SUBJECTS, updateSeoSubjects);
}

export function* watchDeleteSeoSubjects() {
    yield takeEvery(DELETE_SEO_SUBJECT, deleteSeoSubject);
}

export function* watchDeleteContentSubjects() {
    yield takeEvery(DELETE_CONTENT_SUBJECT, deleteContentSubject);
}

function* SubjectsSaga() {

    yield all([
        fork(watchGetSubjectList),
        fork(watchGetParentSubject),
        fork(watchGetSubjectData),
        fork(watchAddSubject),
        fork(watchUpdateSubject),
        fork(watchDeleteSubject),
        fork(watchDeleteMultipleSubject),
        fork(watchGetContentSubjects),
        fork(watchGetSeoSubjects),
        fork(watchUpdateContentSubjects),
        fork(watchUpdateSeoSubjects),
        fork(watchGetContentBooksSearch),
        fork(watchDeleteSeoSubjects),
        fork(watchDeleteContentSubjects)
    ]);
}
  
export default SubjectsSaga;