import React, { useState, useEffect} from "react";
import { Link } from 'react-router-dom';

//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {getKanbanTask} from '../../store/actions';
import {Card, Row, Col, CardBody, Container, Button, CardHeader} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import SearchForm from "../../Components/Common/SearchForm";
import KanbanList from "./KanbanList";
import {useTranslation} from "react-i18next";


const TaskKanban = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [lists, setList] = useState([]);

    const { TaskLists, response, error } = useSelector((state) => ({
        TaskLists: state.Task.taskList,
        response: state.Task.response,
        error: state.Task.error
    }));
    
    const [keywords, setKeywords] = useState('');
    const [queryParams, setQueryParams] = useState({});

    useEffect(() => {
        setTimeout(()=> {
            dispatch(getKanbanTask(queryParams));
        }, 100);
    }, [queryParams,dispatch]);

    useEffect(() =>
    {
        if (TaskLists)
        {
            setList(TaskLists);
        }

    }, [TaskLists]);
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Tasks" pageTitle="List" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <div className="flex-shrink-0 float-end">
                                    <CardHeader>
                                        <div className="d-flex align-items-center">
                                            <h4 className="card-title mb-0 flex-grow-1" style={{'textTransform' : 'none'}}>{t("Tasks")}</h4>
                                            <div className="flex-shrink-0">
                                                <Link className="btn btn-md btn-primary add-btn me-1" id="create-btn" to="/tasks/create"><i className="ri-add-line align-bottom me-1"></i> {t("Create")} </Link>

                                            </div>
                                        </div>
                                    </CardHeader>

                                    <CardBody className="pb-0 px-4">
                                        <Row className="mb-1">
                                            <div className="col-md">
                                                <Row className="align-items-center g-3">
                                                    <div className="col-md-12  search-box">
                                                        <SearchForm
                                                            typeFunction={getKanbanTask}
                                                            setKeywords={setKeywords}
                                                            queryParams={queryParams}
                                                            setQueryParams={setQueryParams}
                                                        />

                                                    </div>
                                                </Row>
                                            </div>

                                        </Row>
                                    </CardBody>
                                </div>
                            </Card>

                        </Col>
                    </Row>
                        {lists.length === 0 &&
                        <Col className="col-12">
                            <Card>
                                <CardBody className="pb-0 px-4 bg-soft-warning">
                                    <Row className="mb-2">
                                        <h4>
                                            No Result found for <b>{keywords}</b>
                                        </h4>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        }
                        <KanbanList lists={lists}  />
                </Container>
            </div>
        </React.Fragment>
    );
}
export default TaskKanban;