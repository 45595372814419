import React, { useEffect, useState } from "react";
import { Row, Col, CardBody, Card, Alert, Container, Input, Label, Form, FormFeedback, Button } from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import 'react-toastify/dist/ReactToastify.css';

// action
import { registerUser, apiError, resetRegisterFlag } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useHistory } from "react-router-dom";

//import images 

import logoDefault from "../../assets/images/stison-main-small.png";

const Register = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    const [termsAndCondition, setTermsAndCondition] = useState(false);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            email: '',
            username: '',
            password: '',
            firstName: '',
            lastName: '',
            contactNumber: '',
            publisher: '',
            publisherWebsite: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Email is required."),
            password: Yup.string().required("Please Enter Your Password."),
            firstName: Yup.string().required("Firstname is required."),
            lastName: Yup.string().required("Lastname is required."),
            contactNumber: Yup.number().required("Contact Number is required."),
            publisher: Yup.string().required("Publisher name is required."),
            publisherWebsite: Yup.string().required("Publisher website name is required."),
        }),
        onSubmit: (values) => {
            // terms and condition checking
            if (!termsAndCondition) {
                document.getElementById("termsAndConditionValidation").style.display = 'block';
                return false;
            }

            let data = {
                Fname: values.firstName,
                Surname: values.lastName,
                Password: values.password,
                ScreenName: values.firstName,
                Email: values.email,
                Phone: values.contactNumber,
                Organisation: values.publisher,
                Site: values.publisherWebsite
            };

            dispatch(registerUser(data));

            setTimeout(() => {
                validation.resetForm();
                document.getElementById('terms').checked = false;
            }, 1000);
        }
    });

    const { user, error, registrationError, success } = useSelector(state => ({
        registrationError: state.Account.registrationError,
        success: state.Account.success,
        error: state.Account.error,
        user: state.Account.user,
    }));

    useEffect(() => {
        dispatch(apiError(""));
    }, [dispatch]);

    useEffect(() => {
        setTimeout(() => {
            dispatch(resetRegisterFlag());
        }, 3000);

    }, [dispatch, success, error, history]);

    const changeChecked = (e) =>  {
        setTermsAndCondition(e.target.checked);
    };

    document.title="Stison Publishing Solutions | Signup";

    return (
        <React.Fragment>
            <div className="auth-page-content">                
                <Container className="register-wrapper">
                    <Row className="justify-content-center">
                        <Col md={10}>
                            <Link to="/login" className="d-block auth-logo text-center">
                                <img src={logoDefault} alt="" height="80" />
                            </Link>
                            <Card className="mt-3">
                                <CardBody className="p-4">
                                    <div className="p-2 mt-4">
                                        <Row className="g-3">
                                            <Col lg={12}>
                                                <p>
                                                    Please fill in the following details to register your request. Please note that you will receive an email asking you to confirm that you wish to create a new account on the system. Your account should then be made live within a maximum of 24 hours. If you have any problems then please do call the office.
                                                </p>
                                            </Col>
                                        </Row>
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                            className="needs-validation" action="#">
                                            {user && user ? (
                                                <Alert color="success">
                                                    <h3 style={{'color': '#299cdb'}}>Thanks for your registration!</h3>We have sent a link to the email you provided containing instructions on verifying your account.
                                                </Alert>
                                            ) : null}

                                            {registrationError && registrationError ? (
                                                <Alert color="danger"><div>{registrationError}</div></Alert>
                                            ) : null}
                                            <Row className="g-3">
                                                <Col lg={6}>
                                                    <div className="form-floating">
                                                        <Input 
                                                            id="firstName"
                                                            name="firstName"
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Enter your firstname"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.firstName || ""}
                                                            invalid={
                                                                validation.touched.firstName && validation.errors.firstName ? true : false
                                                            }
                                                        />
                                                        <Label htmlFor="firstName">First Name <span className="text-danger">*</span></Label>

                                                        {validation.touched.firstName && validation.errors.firstName ? (
                                                            <FormFeedback type="invalid"><div>{validation.errors.firstName}</div></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="form-floating">
                                                        <Input 
                                                            id="lastName"
                                                            name="lastName"
                                                            type="text" 
                                                            className="form-control"
                                                            placeholder="Enter your Lastname"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.lastName || ""}
                                                            invalid={
                                                                validation.touched.lastName && validation.errors.lastName ? true : false
                                                            }
                                                            />
                                                        <Label htmlFor="lastName">Last Name <span className="text-danger">*</span></Label>

                                                        {validation.touched.lastName && validation.errors.lastName ? (
                                                            <FormFeedback type="invalid"><div>{validation.errors.lastName}</div></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="form-floating">
                                                        <Input 
                                                            id="contactNumber"
                                                            name="contactNumber"
                                                            type="number" 
                                                            className="form-control"
                                                            placeholder="+91 1234567890"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.contactNumber || ""}
                                                            invalid={
                                                                validation.touched.contactNumber && validation.errors.contactNumber ? true : false
                                                            }
                                                            />
                                                        <Label htmlFor="contactNumber">Contact Number <span className="text-danger">*</span></Label>

                                                        {validation.touched.contactNumber && validation.errors.contactNumber ? (
                                                            <FormFeedback type="invalid"><div>{validation.errors.contactNumber}</div></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                                <Col lg={6}>
                                                    <div className="form-floating">
                                                        <Input 
                                                            id="publisher"
                                                            name="publisher"
                                                            type="text" 
                                                            className="form-control"  
                                                            placeholder="Publisher"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.publisher || ""}
                                                            invalid={
                                                                validation.touched.publisher && validation.errors.publisher ? true : false
                                                            }
                                                            />
                                                        <Label htmlFor="publisher">Publisher <span className="text-danger">*</span></Label>

                                                        {validation.touched.publisher && validation.errors.publisher ? (
                                                            <FormFeedback type="invalid"><div>{validation.errors.publisher}</div></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col lg={12}>
                                                    <div className="form-floating">
                                                        <Input 
                                                            id="publisherWebsite"
                                                            name="publisherWebsite"
                                                            type="text" 
                                                            className="form-control"  
                                                            placeholder="Publisher Website"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.publisherWebsite || ""}
                                                            invalid={
                                                                validation.touched.publisherWebsite && validation.errors.publisherWebsite ? true : false
                                                            }
                                                            />
                                                        <Label htmlFor="publisherWebsite">Publisher Website<span className="text-danger">*</span></Label>

                                                        {validation.touched.publisherWebsite && validation.errors.publisherWebsite ? (
                                                            <FormFeedback type="invalid"><div>{validation.errors.publisherWebsite}</div></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col lg={12}>
                                                    <div className="form-floating">
                                                        <Input
                                                            id="email"
                                                            name="email"
                                                            className="form-control"
                                                            placeholder="Enter email address"
                                                            type="email"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.email || ""}
                                                            invalid={
                                                                validation.touched.email && validation.errors.email ? true : false
                                                            }
                                                        />
                                                        <Label htmlFor="email" className="form-label">Email Address<span className="text-danger">*</span></Label>
                                                        {validation.touched.email && validation.errors.email ? (
                                                            <FormFeedback type="invalid"><div>{validation.errors.email}</div></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>

                                                <Col lg={12}>
                                                    <div className="form-floating">
                                                        <Input
                                                            id="password"
                                                            name="password"
                                                            type="password"
                                                            placeholder="Enter Password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.password || ""}
                                                            invalid={
                                                                validation.touched.password && validation.errors.password ? true : false
                                                            }
                                                        />
                                                        <Label htmlFor="userpassword" className="form-label">Password <span className="text-danger">*</span></Label>
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid"><div>{validation.errors.password}</div></FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </Col>
                                            </Row>
                                            
                                            <div className="mb-4 mt-2">
                                                <p className="mb-0 fs-12 text-muted fst-italic"> 
                                                <Input 
                                                    id="terms"
                                                    name="terms" 
                                                    className="form-check-input" 
                                                    type="checkbox"
                                                    onClick={changeChecked}
                                                    style={{'marginLeft': '5px' }}
                                                    defaultChecked={false}
                                                />  I confirm that signing up for an account implies that our organisation agrees to abide by 
                                                <Link to="#" className="text-primary text-decoration-underline fst-normal fw-medium"> Stison's Terms and Conditions</Link></p>

                                                <div id="termsAndConditionValidation" type="invalid" className="invalid-feedback"><div>Please accept Stison's Terms and Condition.</div></div>
                                            </div>

                                            <div className="mt-4 text-end">
                                                <Button color="info" className="btn btn-animation btn-md" type="submit" data-text="Sign Up"><span>Sign Up</span></Button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>

                            <div className="mt-4 text-center">
                                <p className="mb-0">Already have an account ? <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Signin </Link> </p>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Register;
