import {
    GET_GROUP_ADMIN_LIST,
    GET_GROUP_ADMIN_DATA,
    API_RESPONSE_SUCCESS,
    UPDATE_GROUP_ADMIN,
    UPDATE_GROUP_ADMIN_SUCCESS,
    UPDATE_GROUP_ADMIN_FAIL,
    API_RESPONSE_ERROR,
    RESET_GA_VALUES,
} from "./actionType";

const INIT_STATE = {
    groupAdminList: [],
    groupAdminData: [],
    error: "",
    response: "",
    success: false,
    isLoading: true,
};

const GroupAdmin = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_GROUP_ADMIN_LIST:
                    return {
                        ...state,
                        groupAdminList: action.payload.data,
                    };
                case GET_GROUP_ADMIN_DATA: 
                    return {
                        ...state,
                        groupAdminData: action.payload.data,
                    };
                default:
                    return { ...state };
            }

        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_GROUP_ADMIN_LIST:
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                case GET_GROUP_ADMIN_DATA: 
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                default:
                    return { ...state };
            }

        case GET_GROUP_ADMIN_LIST: {
            return {
                ...state,
            };
        }

        case UPDATE_GROUP_ADMIN_SUCCESS:
            return {
                ...state,
                groupAdminData: action.payload.data,
                success: true,
            };

        case UPDATE_GROUP_ADMIN_FAIL:
            return {
                ...state,
                error: action.payload,
                success: false,
            };

        case RESET_GA_VALUES :
            return {
                ...state,
                groupAdminList: [],
                groupAdminData: [],
                error: "",
                response: "",
                success: false,
                isLoading: true,
            };
        default:
            return { ...state };
    }
};

export default GroupAdmin;