import {
    GET_CONTENT_LIST,
    RESET_CONTENT_VALUES,
    CONTENT_RESPONSE_SUCCESS,
    CONTENT_RESPONSE_ERROR,
} from "./actionType";

const INIT_STATE = {
    contentList: [],
    error: "",
    response: "",
    success: false,
};

const Content = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CONTENT_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_CONTENT_LIST:
                    return {
                        ...state,
                        contentList: action.payload.data,
                    };

                default:
                    return { ...state };
            }

        case CONTENT_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_CONTENT_LIST:
                    return {
                        ...state,
                        error: action.payload.error,
                    };

                default:
                    return { ...state };
            }
        case RESET_CONTENT_VALUES :
                return {
                    ...state,
                    contentList: [],
                    error: "",
                    response: "",
                    success: false,
                };
        default:
            return { ...state };
    }
};

export default Content;