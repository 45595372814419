import React, { useEffect, useState } from "react";
import { 
    Row, 
    Col, 
    CardBody, 
    Card,
    Alert, 
    Container, 
    Input, 
    Label, 
    Form, 
    FormFeedback,
    Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";

import BreadCrumb from '../../../../Components/Common/BreadCrumb';

// import {
//     getSubjectData, 
//     getParentSubjects,
//     updateSubject,
//     resetFlagValues,
//     resetSubjectValues
// } from "../../../../store/titles/subjects/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";

import { Editor} from '@tinymce/tinymce-react';

import FailedModal from "../../../../Components/Common/FailedModal";
import SuccessModal from "../../../../Components/Common/SuccessModal";

import GroupAdminEdit from "../GroupAdminEdit";

const GroupAdminUserDetails = (props) => {
    const dispatch  = useDispatch();
    const { t, i18n } = useTranslation();
    const { history, id, setTitle } = props;

    return (
        <React.Fragment>
            <GroupAdminEdit>
                {t("Group Admin User Details")}
            </GroupAdminEdit>
        </React.Fragment>
    );
};

export default GroupAdminUserDetails;
