import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD, PASSWORD_RESET } from "./actionTypes";
import { 
  userForgetPasswordSuccess, 
  userForgetPasswordError, 
  userPasswordResetSuccess, 
  userPasswordResetError 
} from "./actions";

import { postForgetPwd, postPwdReset } from "../../../helpers/stison_api_helper";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    const response = yield call(postForgetPwd, user);
    if (response) {
      yield put(
        userForgetPasswordSuccess(response.message)
      );
    }
  } catch (error) {
    yield put(userForgetPasswordError("Email not found!"));
  }
}

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* passwordReset({ payload: { user, history } }) {
  try {
    const response = yield call(postPwdReset, user);
    if (response) {
      yield put(
        userPasswordResetSuccess(response.message)
      );
    }
  } catch (error) {
    yield put(userPasswordResetError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

export function* watchUserPasswordReset() {
  yield takeEvery(PASSWORD_RESET, passwordReset);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
  yield all([fork(watchUserPasswordReset)]);
}

export default forgetPasswordSaga;
