import React, { useState, useEffect } from 'react';

//redux
import { useSelector, useDispatch } from "react-redux";

import { 
  Card, 
  CardBody,
  CardHeader,
  Col, 
  Container, 
  Row,
  Input, 
  Label, 
  Table,
  Button,
  Modal, 
  ModalBody, 
  ModalHeader,
  Form,
  Spinner
} from 'reactstrap';

import { Link } from 'react-router-dom';   
// Formik validation
import { useFormik } from "formik";
import { useProfile } from '../../../Components/Hooks/UserHooks';

import * as Yup from "yup";
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Pagination from '../../../Components/Common/Pagination';

import { getOrganisationPendingList } from '../../../store/general/organisation/actions';

const OrganisationPending = (props) => {
    const dispatch = useDispatch();

    const { userProfile, loading } = useProfile();
    const { pendingList, response, error } = useSelector((state) => ({
        pendingList: state.Organisation.pendingList,
        response: state.Organisation.response,
    }));

    const [list, setList] = useState([]);
    const [orgSelected, setOrgSelected] = useState([]);

    const [orgPendingModal, setOrgPendingModal] = useState(false);
    const [org, setOrg] = useState([]);

    // get Organisation Pending Data
    useEffect(() => {
        dispatch(getOrganisationPendingList());
    }, [dispatch]);

    useEffect(() => {
        if (pendingList.data && pendingList.data.length) setList(pendingList.data);
    }, [pendingList.data]);

    // Checked All Orgpending
    const checkOrgPendingAll = () => {

    };

    const checkOrgPendingItem = () => {

    };

    const removeOrgPendingSelected = () => {

    }; 

    const openOrgPendingDetails = tmpOrg => {
        setOrgPendingModal(!orgPendingModal);
        if (tmpOrg)
        {
            setOrg({
                TempRegistrationId: tmpOrg.TempRegistrationId || '' ,
                Organisation: tmpOrg.Organisation || '',
                OrganisationCode: tmpOrg.OrganisationCode || '',
                Fname: tmpOrg.Fname || '',
                Surname: tmpOrg.Surname || '',
                ScreenName: tmpOrg.ScreenName || '',
                Email: tmpOrg.Email || '',
                Phone: tmpOrg.Phone || '',
                Site: tmpOrg.Site || '',
            });
        }
    };

    const orgAction = (...params) => {

        let id = params[0];
        let type = params[1];

        //add loading to have process
        document.getElementById('spinner'+type).classList.remove('d-none');
        
        
    };

    document.title="Stison Publishing Solutions | Organisation Pending";
    
    return (
        <React.Fragment>      
        <div className="page-content">
            <Container fluid>
            <BreadCrumb title="Organisation Pending" pageTitle="Organisation" />
            <Row>
                <Col xs={12}>
                    <Card>
                        <CardHeader>
                            <div className="d-flex align-items-center">
                                <h4 className="card-title mb-0 flex-grow-1">Organisation Pending</h4>
                                <div className="flex-shrink-0">
                                    <Button className="btn btn-soft-danger"
                                    ><i className="ri-delete-bin-2-line"></i></Button>
                                </div>
                            </div>
                        </CardHeader>
                        <CardBody >
                        <div id="pendingList">
                            <Row className="g-4 mb-3 ">
                                <form>
                                    <div className="row g-3">
                                    <div className="col-xxl-5 col-sm-12">
                                        <div className="search-box">
                                        <input type="text" className="form-control search bg-light border-light" placeholder="Search for organisation" />
                                        <i className="ri-search-line search-icon"></i>
                                        </div>
                                    </div>

                                    <div className="col-xxl-1 col-sm-4">
                                        <button type="button" className="btn btn-primary"> <i className="ri-equalizer-fill me-1 align-bottom"></i>
                                            Search
                                        </button>
                                    </div>
                                    </div>
                                </form>
                            </Row>

                            <div className="table-responsive table-card mt-3 mb-1">
                                <table className="table align-middle table-nowrap table-striped " id="organisationPendingTable">
                                    <thead className="table-light">
                                        <tr>
                                            <th scope="col" style={{ width: "50px" }}>
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="checkAll" value="option" 
                                                    onChange={e => {
                                                        checkOrgPendingAll(e.target.value);
                                                    }}
                                                    />
                                                </div>
                                            </th>
                                            <th className="sort" data-sort="organisation">Organisation</th>
                                            <th className="sort" data-sort="requestor">Requestor</th>
                                            <th className="sort" data-sort="status">Status</th>
                                            <th className="sort" data-sort="phone">Phone</th>
                                            <th className="sort" data-sort="created">Created</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className="list form-check-all">
                                        {list && list.map(tmpOrganisation => (
                                            <tr key={tmpOrganisation.TempRegistrationId}>
                                                <th scope="row">
                                                    <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" name="chk_child" value={tmpOrganisation.TempRegistrationId}
                                                            onChange={e => {
                                                                checkOrgPendingItem(e);
                                                            }}
                                                        />
                                                    </div>
                                                </th>
                                                <td><Link className="btn btn-link p-0" to="#" onClick={() => openOrgPendingDetails(tmpOrganisation)}>{tmpOrganisation.Organisation}</Link></td>
                                                <td>{tmpOrganisation.Fname} {tmpOrganisation.Surname}</td>
                                                <td >
                                                {(tmpOrganisation && tmpOrganisation.VerifyTime ?
                                                    <div>
                                                        <i className="ri-checkbox-circle-line align-middle text-success"></i> Verified
                                                    </div>
                                                    : 
                                                    <div>
                                                        <i className="ri-close-circle-line align-middle text-danger"></i> Not Verified
                                                    </div>
                                                )}
                                                </td>
                                                <td >{tmpOrganisation.Phone}</td>
                                                <td >{tmpOrganisation.Created}</td>
                                                <td>
                                                    <div className="hstack gap-3 flex-wrap">
                                                        <Link to="#" className="link-success fs-15" 
                                                            onClick={() => openOrgPendingDetails(tmpOrganisation)}
                                                        ><i className="ri-edit-2-line"></i></Link>
                                                        <Link to="#" className="link-danger fs-15"><i className="ri-delete-bin-line"></i></Link>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className="noresult" style={{ display: "none" }}>
                                    <div className="text-center">
                                        <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                            colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                        </lord-icon>
                                        <h5 className="mt-2">Sorry! No Result Found</h5>
                                        <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                            orders for you search.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <Pagination 
                                typeFunction={getOrganisationPendingList}
                                currentPage={pendingList.current_page}
                                firstPageUrl={pendingList.first_page_url}
                                from={pendingList.from}
                                lastPage={pendingList.last_page}
                                lastPageUrl={pendingList.last_page_url}
                                links={pendingList.links}
                                nextPageUrl={pendingList.next_page_url}
                                path={pendingList.path}
                                perPage={pendingList.per_page}
                                prevPageUrl={pendingList.prev_page_url}
                                to={pendingList.to}
                                total={pendingList.total}
                            />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            </Container>
        </div>

        {/* ZoomIn Animation */}
        <Modal size="lg" id="flipModal" isOpen={orgPendingModal} toggle={() => { openOrgPendingDetails(); }} modalClassName="zoomIn" >
                <ModalHeader className="p-3 bg-soft-info" id="flipModalLabel" toggle={() => { openOrgPendingDetails(); }}>
                    Editing - {org.Organisation || ""}
                </ModalHeader>
                <ModalBody>
                    <Row className='mb-3'>
                        <Col lg={3}>
                            <Label htmlFor="Organisation" className=" col-form-label">Organisation</Label>
                        </Col>
                        <Col lg={9}>
                            <Input
                                name="Organisation"
                                className="form-control-plaintext p-2"
                                type="text"
                                value={org.Organisation || ""}
                                readOnly
                            />
                        </Col>
                    </Row>

                    <Row className='mb-3'>
                        <Col lg={3}>
                            <Label htmlFor="OrganisationCode" className=" col-form-label">Organisation Code</Label>
                        </Col>
                        <Col lg={9}>
                            <Input
                                name="OrganisationCode"
                                className="form-control-plaintext p-2"
                                type="text"
                                value={org.OrganisationCode || ""}
                                readOnly
                            />
                        </Col>
                    </Row>

                    <Row className='mb-3'>
                        <Col lg={3}>
                            <Label htmlFor="Name" className=" col-form-label">Name</Label>
                        </Col>
                        <Col lg={9}>
                            <Input
                                name="Name"
                                className="form-control-plaintext p-2"
                                type="text"
                                value={org.Fname + ' ' + org.Surname || ""}
                                readOnly
                            />
                        </Col>
                    </Row>
                    
                    <Row className='mb-3'>
                        <Col lg={3}>
                            <Label htmlFor="ScreenName" className=" col-form-label">Screen Name</Label>
                        </Col>
                        <Col lg={9}>
                            <Input
                                name="ScreenName"
                                className="form-control-plaintext p-2"
                                type="text"
                                value={org.ScreenName || ""}
                                readOnly
                            />
                        </Col>
                    </Row>

                    <Row className='mb-3'>
                        <Col lg={3}>
                            <Label htmlFor="Email" className=" col-form-label">Email</Label>
                        </Col>
                        <Col lg={9}>
                            <Input
                                name="Email"
                                className="form-control-plaintext p-2"
                                type="text"
                                value={org.Email || ""}
                                readOnly
                            />
                        </Col>
                    </Row>

                    <Row className='mb-3'>
                        <Col lg={3}>
                            <Label htmlFor="Phone" className=" col-form-label">Phone</Label>
                        </Col>
                        <Col lg={9}>
                            <Input
                                name="Phone"
                                className="form-control-plaintext p-2"
                                type="text"
                                value={org.Phone || ""}
                                readOnly
                            />
                        </Col>
                    </Row>

                    <Row className='mb-3'>
                        <Col lg={3}>
                            <Label htmlFor="Site" className=" col-form-label">Site</Label>
                        </Col>
                        <Col lg={9}>
                            <Input
                                name="Site"
                                className="form-control-plaintext p-2"
                                type="text"
                                value={org.Site || ""}
                                readOnly
                            />
                        </Col>
                    </Row>

                </ModalBody>
                <div className="modal-footer">
                    <Button color="info" className="btn-load" type="button" onClick={() => orgAction(org.TempRegistrationId, 'Approve')}>
                        <span className="d-flex align-items-center">
                            <Spinner id='spinnerApprove' size="sm" className="flex-shrink-0 d-none" > Approve </Spinner>
                            <span className="flex-grow-1 ms-2">
                                Approve 
                            </span>
                        </span>
                    </Button>
                    <Button color="danger" className="btn-load" type="button" onClick={() => orgAction(org.TempRegistrationId, 'Reject')}>
                        <span className="d-flex align-items-center">
                            <Spinner id='spinnerReject' size="sm" className="flex-shrink-0 d-none" > Reject </Spinner>
                            <span className="flex-grow-1 ms-2">
                                Reject 
                            </span>
                        </span>
                    </Button>

                    <Button color="light" onClick={() => { openOrgPendingDetails(); }}> Close </Button>
                </div>
        </Modal>
        </React.Fragment>
    );
};

export default OrganisationPending;