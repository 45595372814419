import React, { useState, useEffect } from 'react';

//redux
import { useSelector, useDispatch} from "react-redux";

import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Input,
    Label,
    Table,
    Button, Nav, NavItem, NavLink, TabContent, TabPane, Form, FormFeedback
} from 'reactstrap';
import BreadCrumb from "../../Components/Common/BreadCrumb";
import SuccessModal from "../../Components/Common/SuccessModal";
import FailedModal from "../../Components/Common/FailedModal";
import classnames from "classnames";
import {Link, useParams} from "react-router-dom";
import {useQuill} from "react-quilljs";
import {addNewTask, getTaskData, resetFlagValues, resetTaskValues, updateTask} from "../../store/task/actions";
import {isInteger, useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";




const TaskForm = (props) => {

    const dispatch = useDispatch();
    const { id } = useParams();
    const { quill, quillRef } = useQuill();
    const { t } = useTranslation();

    const {history} = props;
    const [task, setTask] = useState('');
    const currentOrg   = localStorage.getItem("authUserOrganisation");
    const organisation = JSON.parse(currentOrg);

    const [failedModal, setFailedModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);



    const { taskData ,error, success} = useSelector((state) => ({
        taskData:state.Task.taskData,
        error:state.Task.error,
        success:state.Task.success,
    }));


    // get Task Data
    useEffect(() =>
    {
        if(isInteger(id)) dispatch(getTaskData(id));
        else
        {
            setTask('');
            if (quill) quill.clipboard.dangerouslyPasteHTML('');
        }
    }, [dispatch,id,quill]);


    useEffect(() =>
    {
        if (typeof taskData !== 'undefined' && taskData.TaskName)
        {
            setTask(taskData);
            if (quill) quill.clipboard.dangerouslyPasteHTML(taskData.TaskDescription);
        }
    }, [taskData,quill]);

    const initialV =
        {
        taskName:  task.TaskName || '',
        taskDescription: task.TaskDescription || '',
        taskStatus: task.Status || '',
        taskDueDate: task.DueDate || '',
        taskStartDate: task.StartDate || '',
    }

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: initialV,

        validationSchema: Yup.object({
            taskName: Yup.string().required("Please Enter Task"),
            taskStatus: Yup.string().required("Please Select Status"),
        }),
        onSubmit: (values) => {
            let data = {
                OrganisationId: organisation.OrganisationId,
                TaskName: values.taskName,
                TaskDescription: quillRef.current.firstChild.innerHTML,
                TaskStatus: values.taskStatus,
                TaskDueDate: values.taskDueDate,
                TaskStartDate: values.taskStartDate
            };

            if (task.TaskId && isInteger(id))
            {
                data['_id'] = id;
                dispatch(updateTask(data));

            }
            else dispatch(addNewTask(data));


        }
    });

    useEffect(() => {

        if (error && !success) {
            setFailedModal(true);
        }

        if (success && !error ) {
            setSuccessModal(true);
        }

        setTimeout(() => {
            dispatch(resetTaskValues());
        }, 1000);

    }, [dispatch, error, success]);

    const successModalAction = () => {

        setSuccessModal(false);
        setTimeout(() => { history.push("/tasks") }, 500);
    };

    //Tab
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const taskStatus = [
            {value:'pending',label:'To do'},
            {value:'remind',label:'Reminder'},
            {value:'done',label:'Done'}
    ]


        document.title = "Stison Publishing Solutions | Task Edit";

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <BreadCrumb title={ task ? t('Edit Task') : t('Create Task') } pageTitle="Create" />
                        <SuccessModal
                            show={successModal}
                            onCloseClick={successModalAction}
                            Message={task ? 'Successfully added update task.' : 'Successfully added new task.'}
                        />

                        <FailedModal
                            show={failedModal}
                            onCloseClick={() => setFailedModal(false) }
                        />

                        <Row>
                            <Col lg={12}>
                                <Card className="mt-n4 mx-n4">
                                    <div className="bg-soft-warning">
                                        <CardBody className="pb-0 px-4">
                                            <Row className="mb-3">
                                                <div className="col-md">
                                                    <Row className="align-items-center g-3">
                                                        {/* <div className="col-md-auto">
                                                        <div className="avatar-md">
                                                            <div className="avatar-title bg-white rounded-circle">
                                                                <i className=' ri-book-mark-fill' style={{'color' : '#495057', 'fontSize' : '2em'}}></i>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                        <div className="col-md mt-4">
                                                            <div>
                                                                <h4 className="fw-bold">{ task ? t('Editing') + ' - ' + t(task.TaskName) : t('Create Task') }</h4>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </div>
                                                <div className="col-md-auto">
                                                    <div className="hstack gap-1 flex-wrap">
                                                        <button type="button" className="btn py-0 fs-20 text-body" style={{'padding': '0.2rem 0.5rem'}}>
                                                            <i className="ri-dashboard-2-line"></i>
                                                        </button>
                                                        <button type="button" className="btn py-0 fs-20" style={{'padding': '0.2rem 0.5rem'}}>
                                                            <i className=" ri-image-add-fill"></i>
                                                        </button>

                                                        <button type="button" className="btn py-0 fs-20 text-body" style={{'padding': '0.2rem 0.5rem'}}>
                                                            <i className="ri-download-cloud-fill"></i>
                                                        </button>

                                                        <button type="button" className="btn py-0 fs-20 text-body" style={{'padding': '0.2rem 0.5rem'}}>
                                                            <Link  to="/tasks/create"><i className=" ri-file-add-line"></i> </Link>
                                                        </button>
                                                    </div>
                                                </div>
                                            </Row>

                                            <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                                                <NavItem>
                                                    <NavLink
                                                        className={classnames({ active: activeTab === '1' }, "fw-semibold")}
                                                        onClick={() => { toggleTab('1'); }}
                                                        href="#">
                                                        <i className=" ri-add-fill me-1 align-bottom"></i>{" "}
                                                        { task ? t('Editing'): t('Create') }
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </CardBody>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <TabContent activeTab={activeTab} className="text-muted">
                                    <TabPane tabId="1">
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                            className="needs-validation" >

                                            <Row>
                                                <Col lg={8}>
                                                    <Card>
                                                        <CardBody>
                                                            <Row >
                                                            <Col lg={4}>
                                                                <div className="mb-3">
                                                                    <Label className="form-label" htmlFor="taskName">Task<span className="text-danger">*</span></Label>
                                                                    <Input
                                                                        id="taskName"
                                                                        name="taskName"
                                                                        className="form-control"
                                                                        placeholder="Enter Task name"
                                                                        type="text"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        defaultValue={validation.values.taskName}
                                                                        invalid={
                                                                            validation.touched.taskName && validation.errors.taskName ? true : false
                                                                        }
                                                                    />
                                                                    {validation.touched.taskName && validation.errors.taskName ? (
                                                                        <FormFeedback type="invalid"><div>{validation.errors.taskName}</div></FormFeedback>
                                                                    ) : null}
                                                                </div>
                                                            </Col>

                                                                <Col lg={8}>
                                                                    <div className="float-end">
                                                                        <Label className="form-label" htmlFor="taskName">Status<span className="text-danger">*</span></Label>
                                                                        <Input
                                                                            name="taskStatus"
                                                                            type="select"
                                                                            className="form-select"
                                                                            id="taskStatus"
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            value={
                                                                                validation.values.taskStatus || ''
                                                                            }
                                                                            invalid={
                                                                                validation.touched.taskStatus && validation.errors.taskStatus ? true : false
                                                                            }
                                                                        >
                                                                            <option value="">Select Status</option>
                                                                            {taskStatus && taskStatus.map(task => (
                                                                                <option key={task.label}  value={task.value}>{task.label}</option>
                                                                            ))};
                                                                        </Input>

                                                                        {validation.touched.taskStatus && validation.errors.taskStatus ? (
                                                                            <FormFeedback type="invalid"><div>{validation.errors.taskStatus}</div></FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                            </Row>

                                                            <Row>
                                                            <div className="mb-3">
                                                                <Label className="form-label">Description</Label>
                                                                <div>
                                                                    <div ref={quillRef} />
                                                                </div>
                                                            </div>
                                                            </Row>
                                                           
                                                        </CardBody>
                                                        <div className="text-end mb-4">
                                                            <button type="submit" className="btn btn-success w-sm mr-2" style={{ 'marginRight' : '10px'}} disabled={validation.isSubmitting}>  { task ? t('Update'): t('Create') }</button>

                                                            <Link className="btn btn-warning w-sm me-1" to="/tasks">Cancel</Link>
                                                        </div>
                                                    </Card>
                                                </Col>

                                                <Col lg={4}>
                                                    <div className="card subjects-action">
                                                        <div className="card-header">
                                                            <h5 className="card-title mb-0">Parent</h5>
                                                        </div>
                                                        <div className="card-body">  
                                                            <Row >
                                                                <Col lg={6}>
                                                                    <div className="  mb-3">
                                                                        <Label className="form-label" htmlFor="taskStartDate">Start Date</Label>
                                                                        <Input
                                                                            name="taskStartDate"
                                                                            type="date"
                                                                            className="form-select"
                                                                            id="taskStartDate"
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            value={
                                                                                validation.values.taskStartDate || ''
                                                                            }
                                                                        >

                                                                        </Input>
                                                                        {validation.touched.taskStartDate &&
                                                                        validation.errors.taskStartDate ? (
                                                                            <FormFeedback type="invalid">
                                                                                {validation.errors.taskStartDate}
                                                                            </FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <div className=" mb-3">
                                                                        <Label className="form-label" htmlFor="taskDueDate">Due Date</Label>
                                                                        <Input
                                                                            name="taskDueDate"
                                                                            type="date"
                                                                            className="form-select"
                                                                            id="taskDueDate"
                                                                            onChange={validation.handleChange}
                                                                            onBlur={validation.handleBlur}
                                                                            value={
                                                                                validation.values.taskDueDate || ''
                                                                            }
                                                                        >

                                                                        </Input>
                                                                        {validation.touched.taskDueDate &&
                                                                        validation.errors.taskDueDate ? (
                                                                            <FormFeedback type="invalid">
                                                                                {validation.errors.taskDueDate}
                                                                            </FormFeedback>
                                                                        ) : null}
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
}
export default TaskForm;