import { 
    GET_MAIL_USER,
    COMMON_RESPONSE_SUCCESS,
    COMMON_RESPONSE_ERROR,
    UPDATE_MAIL_USER_SUCCESS,
    UPDATE_MAIL_USER_FAIL,
    UPDATE_MAIL_USER,
    RESET_FLAG_NOTIFICATION,
    GET_REPORTS_VIEW,
    GET_ADMIN_MENUS,
    GET_ADMIN_TAB_MENUS,
    UPDATE_MULTIPLE_MAIL_USER,
    DELETE_MAIL_USER
} from "./actionType";

export const getMailUser = (filters) => ({
    type: GET_MAIL_USER,
    params: filters
});

export const getReportsView = (filters) => ({
    type: GET_REPORTS_VIEW,
    params: filters
});

export const getAdminMenus = (filters) => ({
    type: GET_ADMIN_MENUS,
    params: filters
});

export const getAdminTabMenus = (filters) => ({
    type: GET_ADMIN_TAB_MENUS,
    params: filters
});

// common success
export const CommonApiResponseSuccess = (actionType, data) => ({
    type: COMMON_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const CommonApiResponseError = (actionType, error) => ({
    type: COMMON_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const updateMailUser = mail => ({
    type: UPDATE_MAIL_USER,
    payload: mail,
});

export const updateMultipleMailUser = mail => ({
    type: UPDATE_MULTIPLE_MAIL_USER,
    payload: mail,
});

export const deleteMailUser = mail => ({
    type: DELETE_MAIL_USER,
    payload: mail,
});

export const updateMailUserSuccess = mail => ({
    type: UPDATE_MAIL_USER_SUCCESS,
    payload: mail,
});

export const updateMailUserFail = error => ({
    type: UPDATE_MAIL_USER_FAIL,
    payload: error,
});

export const resetFlagNotification = () => ({
    type: RESET_FLAG_NOTIFICATION
});