import React, { useState, useEffect} from "react";
import { Link } from 'react-router-dom';

//redux
import { useSelector, useDispatch } from "react-redux";

import DeleteModal from "../../Components/Common/DeleteModal";
import {deleteTask} from '../../store/actions';
import {Card, CardBody, Col, Collapse} from "reactstrap";
import moment from "moment";
import SuccessModal from "../../Components/Common/SuccessModal";
import SimpleBar from "simplebar-react";

const KanbanList = (props) => {
    const dispatch = useDispatch();
    const {  lists } = props;
    const [task, setTask] = useState([]);
    const [taskList, setTaskList] = useState([]);


    const [showItemTask,setShowItemTask] =  useState([]);
    const [showTask,setShowTask] =  useState([]);
    // Delete Subjects
    const [deleteModal, setDeleteModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);

    const onClickDelete = (task) => {
        setTask(task);
        setDeleteModal(true);
    };

    useEffect(() =>
    {
        if (lists)
        {
            setTaskList(lists);
        }

    }, [lists]);


    const handleDeleteTask = () => {
        if (task.TaskId)
        {

            dispatch(deleteTask(task));
            setDeleteModal(false);

            lists[task.Status].map((item, index) => {
                if (task.TaskId === item.TaskId) lists[task.Status].splice(index, 1);

                return true;
            });
            setTaskList(lists);
            setTimeout(() => {
               setSuccessModal(true)
            },300);
        }
    };


    const successModalAction = () => {
        // reset all after success
        setSuccessModal(false);
    };


    const setTaskBackground = (task) =>
    {
        let main_css = 'card-body  nav-link menu-link ';

        if (task === 'pending') main_css += 'bg-soft-danger';
        else if (task === 'remind') main_css +='bg-soft-info';
        else if (task === 'done') main_css +='bg-soft-success';
        return main_css;
    }
    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteTask}
                onCloseClick={() => setDeleteModal(false)}
            />
            <SuccessModal
                show={successModal}
                onCloseClick={successModalAction}
                Message="Successfully deleted."
            />
            <div className="tasks-board mb-lg-3"  id="kanbanboard">
            {Object.keys(taskList).length > 0 && Object.keys(taskList).map((list,index) => (
                <div key={index} className="tasks-list"  style={{minWidth:'550px'}}>
                    <div className="d-flex mb-3">
                        <div className="flex-grow-1">
                            <h6 className="fs-14 text-uppercase fw-semibold mb-0">{list} <small
                                className="badge bg-success align-bottom ms-1 totaltask-badge">{list.length}</small></h6>
                        </div>
                        <div className="flex-shrink-0">
                            <div className="dropdown card-header-dropdown">
                                <a className="text-reset dropdown-btn"  href="/" data-bs-toggle="dropdown"
                                    aria-expanded="false">
                                    <span className="fw-medium text-muted fs-12">Priority<i
                                        className="mdi mdi-chevron-down ms-1"></i></span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-end">
                                    <a className="dropdown-item" href="/">Priority</a>
                                    <a className="dropdown-item" href="/">Date Added</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <SimpleBar style={{ height: "100%" }} className="tasks-wrapper px-3 mx-n3">
                        <div id="unassigned-task" className="tasks">
   {taskList[list].map((item,index) => (
                <div key={index}  className="card tasks-box">
                    <div className="card-body ">
                        <div className="d-flex mb-3">
                            <h6 className="fs-15 mb-0 flex-grow-1 text-truncate task-title"> {item.TaskName}</h6>
                            <div >
                                <button onClick={() => { onClickDelete(item); }}   className=" btn btn-soft-danger btn btn-secondary text-muted"
                                        aria-expanded="false"><i className="ri-delete-bin-5-fill"></i></button>
                                &nbsp;
                                <Link  to={`/tasks/${item.TaskId}`}  className="text-muted btn btn-soft-success btn btn-outline-secondary"
                                      aria-expanded="false"><i className="ri-edit-line"></i>
                                </Link>
                            </div>
                        </div>
                        <p className="text-muted" dangerouslySetInnerHTML={{__html: item.TaskDescription}}/>
                        <div className="mb-3">
                            <div className="d-flex mb-1">
                                <div className="flex-grow-1">
                                    {/* <h6 className="text-muted mb-0"><span className="text-secondary">15%</span> of 100%
                                    </h6> */}
                                </div>
                                <div className="flex-shrink-0">
                                    <span className="text-muted">Start Date : {item.StartDate} - End Date : {item.DueDate}</span>
                                </div>
                            </div>
                            <div className="progress rounded-3 progress-sm">
                                <div className="progress-bar bg-danger" role="progressbar"
                                     aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="flex-grow-1">
                                {/*<span className="badge badge-soft-primary">Admin</span>*/}
                            </div>
                            <div className="flex-shrink-0">
                                {/*<div className="avatar-group">*/}
                                {/*    <a  href="/" className="avatar-group-item"*/}
                                {/*       data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top"*/}
                                {/*       aria-label="Alexis" data-bs-original-title="Alexis">*/}
                                {/*        <img src="assets/images/users/avatar-6.jpg" alt=""*/}
                                {/*             className="rounded-circle avatar-xxs"/>*/}
                                {/*    </a>*/}
                                {/*    <a  href="/" className="avatar-group-item"*/}
                                {/*       data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top"*/}
                                {/*       aria-label="Nancy" data-bs-original-title="Nancy">*/}
                                {/*        <img src="assets/images/users/avatar-5.jpg" alt=""*/}
                                {/*             className="rounded-circle avatar-xxs"/>*/}
                                {/*    </a>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                    
                &nbsp;
            </div>
       ))}
            </div>
        </SimpleBar>
        </div>
            ))}
            </div>

            <div className="modal fade" id="creatertaskModal" tabIndex="-1" aria-labelledby="creatertaskModalLabel"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content border-0">
                        <div className="modal-header p-3 bg-soft-info">
                            <h5 className="modal-title" id="creatertaskModalLabel">Create New Task</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"
                                    aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form action="#">
                                <div className="row g-3">
                                    <div className="col-lg-12">
                                        <label htmlFor="projectName" className="form-label">Project Name</label>
                                        <input type="text" className="form-control" id="projectName"
                                               placeholder="Enter project name"/>
                                    </div>
                                    <div className="col-lg-12">
                                        <label htmlFor="sub-tasks" className="form-label">Task Title</label>
                                        <input type="text" className="form-control" id="sub-tasks"
                                               placeholder="Task title"/>
                                    </div>

                                    <div className="col-lg-12">
                                        <label htmlFor="task-description" className="form-label">Task
                                            Description</label>
                                        <textarea className="form-control" id="task-description" rows="3"
                                                  placeholder="Task description"></textarea>
                                    </div>

                                    <div className="col-lg-12">
                                        <label htmlFor="formFile" className="form-label">Tasks Images</label>
                                        <input className="form-control" type="file" id="formFile"/>
                                    </div>

                                    <div className="col-lg-12">
                                        <label htmlFor="tasks-progress" className="form-label">Add Team Member</label>
                                        <div data-simplebar  >
                                            <ul className="list-unstyled vstack gap-2 mb-0">
                                                <li>
                                                    <div className="form-check d-flex align-items-center">
                                                        <input className="form-check-input me-3" type="checkbox"
                                                               value="" id="anna-adame"/>
                                                            <label
                                                                className="form-check-label d-flex align-items-center"
                                                                htmlFor="anna-adame">
                                                                    <span className="flex-shrink-0">
                                                                        <img src="assets/images/users/avatar-1.jpg"
                                                                             alt=""
                                                                             className="avatar-xxs rounded-circle"/>
                                                                    </span>
                                                                <span className="flex-grow-1 ms-2">
                                                                        Anna Adame
                                                                    </span>
                                                            </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check d-flex align-items-center">
                                                        <input className="form-check-input me-3" type="checkbox"
                                                               value="" id="frank-hook"/>
                                                            <label
                                                                className="form-check-label d-flex align-items-center"
                                                                htmlFor="frank-hook">
                                                                    <span className="flex-shrink-0">
                                                                        <img src="assets/images/users/avatar-3.jpg"
                                                                             alt=""
                                                                             className="avatar-xxs rounded-circle"/>
                                                                    </span>
                                                                <span className="flex-grow-1 ms-2">
                                                                        Frank Hook
                                                                    </span>
                                                            </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check d-flex align-items-center">
                                                        <input className="form-check-input me-3" type="checkbox"
                                                               value="" id="alexis-clarke"/>
                                                            <label
                                                                className="form-check-label d-flex align-items-center"
                                                                htmlFor="alexis-clarke">
                                                                    <span className="flex-shrink-0">
                                                                        <img src="assets/images/users/avatar-6.jpg"
                                                                             alt=""
                                                                             className="avatar-xxs rounded-circle"/>
                                                                    </span>
                                                                <span className="flex-grow-1 ms-2">
                                                                        Alexis Clarke
                                                                    </span>
                                                            </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check d-flex align-items-center">
                                                        <input className="form-check-input me-3" type="checkbox"
                                                               value="" id="herbert-stokes"/>
                                                            <label
                                                                className="form-check-label d-flex align-items-center"
                                                                htmlFor="herbert-stokes">
                                                                    <span className="flex-shrink-0">
                                                                        <img src="assets/images/users/avatar-2.jpg"
                                                                             alt=""
                                                                             className="avatar-xxs rounded-circle"/>
                                                                    </span>
                                                                <span className="flex-grow-1 ms-2">
                                                                        Herbert Stokes
                                                                    </span>
                                                            </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check d-flex align-items-center">
                                                        <input className="form-check-input me-3" type="checkbox"
                                                               value="" id="michael-morris"/>
                                                            <label
                                                                className="form-check-label d-flex align-items-center"
                                                                htmlFor="michael-morris">
                                                                    <span className="flex-shrink-0">
                                                                        <img src="assets/images/users/avatar-7.jpg"
                                                                             alt=""
                                                                             className="avatar-xxs rounded-circle"/>
                                                                    </span>
                                                                <span className="flex-grow-1 ms-2">
                                                                        Michael Morris
                                                                    </span>
                                                            </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check d-flex align-items-center">
                                                        <input className="form-check-input me-3" type="checkbox"
                                                               value="" id="nancy-martino"/>
                                                            <label
                                                                className="form-check-label d-flex align-items-center"
                                                                htmlFor="nancy-martino">
                                                                    <span className="flex-shrink-0">
                                                                        <img src="assets/images/users/avatar-5.jpg"
                                                                             alt=""
                                                                             className="avatar-xxs rounded-circle"/>
                                                                    </span>
                                                                <span className="flex-grow-1 ms-2">
                                                                        Nancy Martino
                                                                    </span>
                                                            </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check d-flex align-items-center">
                                                        <input className="form-check-input me-3" type="checkbox"
                                                               value="" id="thomas-taylor"/>
                                                            <label
                                                                className="form-check-label d-flex align-items-center"
                                                                htmlFor="thomas-taylor">
                                                                    <span className="flex-shrink-0">
                                                                        <img src="assets/images/users/avatar-8.jpg"
                                                                             alt=""
                                                                             className="avatar-xxs rounded-circle"/>
                                                                    </span>
                                                                <span className="flex-grow-1 ms-2">
                                                                        Thomas Taylor
                                                                    </span>
                                                            </label>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="form-check d-flex align-items-center">
                                                        <input className="form-check-input me-3" type="checkbox"
                                                               value="" id="tonya-noble"/>
                                                            <label
                                                                className="form-check-label d-flex align-items-center"
                                                                htmlFor="tonya-noble">
                                                                    <span className="flex-shrink-0">
                                                                        <img src="assets/images/users/avatar-10.jpg"
                                                                             alt=""
                                                                             className="avatar-xxs rounded-circle"/>
                                                                    </span>
                                                                <span className="flex-grow-1 ms-2">
                                                                        Tonya Noble
                                                                    </span>
                                                            </label>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="col-lg-4">
                                        <label htmlFor="due-date" className="form-label">Due Date</label>
                                        <input type="text" className="form-control" id="due-date"
                                               data-provider="flatpickr" placeholder="Select date"/>
                                    </div>

                                    <div className="col-lg-4">
                                        <label htmlFor="categories" className="form-label">Tags</label>
                                        <input type="text" className="form-control" id="categories"
                                               placeholder="Enter tag"/>
                                    </div>

                                    <div className="col-lg-4">
                                        <label htmlFor="tasks-progress" className="form-label">Tasks Progress</label>
                                        <input type="text" className="form-control" maxLength="3" id="tasks-progress"
                                               placeholder="Enter progress"/>
                                    </div>
                                    <div className="mt-4">
                                        <div className="hstack gap-2 justify-content-end">
                                            <button type="button" className="btn btn-light"
                                                    data-bs-dismiss="modal">Close
                                            </button>
                                            <button type="button" className="btn btn-success">Add Task</button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    );
};
export default KanbanList;

