import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';

import { useProfile } from '../../Components/Hooks/UserHooks';

import { useTranslation } from "react-i18next";

const Dashboard = () => {
  const { t, i18n } = useTranslation();
  document.title="Stison Publishing Solutions | Dashboard";
  
  return (
    <React.Fragment>      
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Dashboard')} pageTitle={t('Page')} />
          <Row>
            <Col xs={12}>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;