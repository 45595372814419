import { setAuthorization } from "../api_helper";

/**
 * API access token
 * @returns accessToken
 */
const authHeaderMiddleWare = store => next => action => {
    const obj = JSON.parse(localStorage.getItem("authUser"))
    const accessToken = JSON.parse(localStorage.getItem('accessToken'));

    if (obj && accessToken) {
        setAuthorization(accessToken);
    }
    next(action);
}
export default authHeaderMiddleWare;