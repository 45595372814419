// Actions
export const COMMON_RESPONSE_SUCCESS = "COMMON_RESPONSE_SUCCESS";
export const COMMON_RESPONSE_ERROR = "COMMON_RESPONSE_ERROR";

export const UPDATE_MAIL_USER = "UPDATE_MAIL_USER";
export const UPDATE_MAIL_USER_SUCCESS = "UPDATE_MAIL_USER_SUCCESS";
export const UPDATE_MAIL_USER_FAIL = "UPDATE_MAIL_USER_FAIL";
export const UPDATE_MULTIPLE_MAIL_USER = "UPDATE_MULTIPLE_MAIL_USER";
export const DELETE_MAIL_USER = "DELETE_MAIL_USER";

export const GET_MAIL_USER = "GET_MAIL_USER";

export const RESET_FLAG_NOTIFICATION = "RESET_FLAG_NOTIFICATION";

export const GET_REPORTS_VIEW = 'GET_REPORTS_VIEW';

export const GET_ADMIN_MENUS  = 'GET_ADMIN_MENUS';
export const GET_ADMIN_TAB_MENUS  = 'GET_ADMIN_TAB_MENUS';