import {
    COMMON_RESPONSE_SUCCESS,
    COMMON_RESPONSE_ERROR,
    UPDATE_MAIL_USER_SUCCESS,
    GET_MAIL_USER,
    UPDATE_MAIL_USER_FAIL,
    RESET_FLAG_NOTIFICATION,
    GET_REPORTS_VIEW,
    GET_ADMIN_MENUS,
    GET_ADMIN_TAB_MENUS
  } from "./actionType";
  
  const INIT_STATE = {
        mailUserList: [],
        error: "",
        response: "",
        success: false,
        reportsView: null,
        adminMenus: [],
        adminTabMenus: [],
        isUpdate: false,
  };
  
    const Common = (state = INIT_STATE, action) => {
        switch (action.type) {
            case COMMON_RESPONSE_SUCCESS:
                switch (action.payload.actionType) {
                        case GET_MAIL_USER:
                            return {
                                ...state,
                                mailUserList: action.payload.data,
                            };

                        case GET_REPORTS_VIEW:
                            return {
                                ...state,
                                reportsView: action.payload.data,
                            };

                        case GET_ADMIN_MENUS:
                            return {
                                ...state,
                                adminMenus: action.payload.data,
                            };

                        case GET_ADMIN_TAB_MENUS:
                            return {
                                ...state,
                                adminTabMenus: action.payload.data,
                            };
                            
                    default:
                        return { ...state };
                }
  
            case COMMON_RESPONSE_ERROR:
                switch (action.payload.actionType) {
                    case GET_MAIL_USER:
                        return {
                            ...state,
                            error: action.payload.error,
                        };

                    case GET_REPORTS_VIEW:
                        return {
                            ...state,
                            reportsView: action.payload.error,
                        };

                    case GET_ADMIN_MENUS:
                        return {
                            ...state,
                            adminMenus: action.payload.error,
                        };

                    case GET_ADMIN_TAB_MENUS:
                        return {
                            ...state,
                            adminTabMenus: action.payload.error,
                        };

                    default:
                        return { ...state };
                    }

            case UPDATE_MAIL_USER_SUCCESS:
                return {
                    ...state,
                    success: true,
                    isUpdate: true,
                };
    
            case UPDATE_MAIL_USER_FAIL:
                return {
                    ...state,
                    error: action.payload,
                    success: false,
                };

            case RESET_FLAG_NOTIFICATION :
                return {
                    ...state,
                    error: "",
                    response: "",
                    success: false,
                    mailUserList: [],
                    adminTabMenus : [],
                    isUpdate: false
                };
            default:
            return { ...state };
        }
  };
  
  export default Common;