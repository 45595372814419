import React, { useState, useEffect, useRef } from "react";

import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";

import { Card, CardBody,  CardHeader,  Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';

import BreadCrumb from '../../Components/Common/BreadCrumb';

import { useProfile } from '../../Components/Hooks/UserHooks';
import { useTranslation } from "react-i18next";

import { getReportsView } from '../../store/actions';

const ReportOutput = () => {
    const { t, i18n } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();

    const { reportsView } = useSelector((state) => ({
        reportsView: state.Common.reportsView
    }));
    
    useEffect(() => {
        dispatch(getReportsView({'id' : id}));
    }, [dispatch, id]);

    const getParsedDate = (strDate) => {
        if (!strDate) return;
        
        var strSplitDate = String(strDate).split(' ');
        var date = new Date(strSplitDate[0]);
        // alert(date);
        var dd = date.getDate();
        var mm = date.getMonth() + 1; //January is 0!
    
        var yyyy = date.getFullYear();
        if (dd < 10) {
            dd = '0' + dd;
        }
        if (mm < 10) {
            mm = '0' + mm;
        }
        date =  dd + "/" + mm + "/" + yyyy + " " + date.toLocaleTimeString();
        return date.toString();
    }
    
    document.title="Stison Publishing Solutions | Report Output";
    
    return (
        <React.Fragment>      
            <div className="page-content">
                <Container fluid>
                <BreadCrumb title={t('Report Output')} pageTitle={t('Page')} />
                <Row>
                    <Col xs={12}>
                        <div className="text-left mb-4">
                            <h4 className="fw-semibold fs-22">{!!reportsView ? t(reportsView?reportsView.ReportName:'') : ''}</h4>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xxl={12} lg={12} >
                        <Card id="reports-list" className="pricing-box ribbon-box right" >
                            <CardBody className="m-2 p-4"> 
                                <div className="flex-grow-1 mb-3">
                                    <h5 className="mb-0 fw-semibold">Generated : {!!reportsView ? getParsedDate(reportsView.Created) : ''}</h5>
                                </div>
                                <div className="report-output-process">
                                    {!!reportsView ? <div dangerouslySetInnerHTML={{__html:reportsView.Output}}></div> : ''}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ReportOutput;