import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
    GET_PARENT_THEMES,
    GET_THEMES_LIST,
    GET_THEME_DATA, 
    UPDATE_THEME, 
    THEME_FAIL, 
    THEME_SUCCESS,
    DELETE_THEME
} from "./actionType";

import {
    ThemesApiResponseSuccess, ThemesApiResponseError, updateThemeSuccess, updateThemeFail
} from "./actions";


import { 
    deleteThemeData,
    getParentThemesApi,
    getThemeDataApi,
    getThemesListApi,
    updateThemeDataApi,
} from "../../../helpers/stison_api_helper";

// get subject list
function* getThemesList({ params }) {
    let filters = {};

    // map object for filter parameters in API
    if (params) Object.entries(params).map(([k,v]) => filters[k] = v);

    try {
        const response = yield call(getThemesListApi, filters);
        yield put(ThemesApiResponseSuccess(GET_THEMES_LIST, response));
    } catch (error) {
        yield put(ThemesApiResponseError(GET_THEMES_LIST, error));
    }
}

// get single theme 
function* getThemeData({ payload: theme }) {
    try {
        const response = yield call(getThemeDataApi, theme);
        yield put(ThemesApiResponseSuccess(GET_THEME_DATA, response));
    } catch (error) {
        yield put(ThemesApiResponseError(GET_THEME_DATA, error));
    }
}

function* getParentThemes() {

    const authOrg = JSON.parse(localStorage.getItem("authUserOrganisation"));
    const organisations = (authOrg !== null) ? authOrg : null;
    
    let filters = {};

    if (Array.isArray(authOrg))
        Object.entries(organisations).map(([k,v]) => filters["organisation[]"] = v.OrganisationId);
    else 
        filters["organisation[]"] = authOrg.OrganisationId;

    try {
        const response = yield call(getParentThemesApi, filters);
        yield put(ThemesApiResponseSuccess(GET_PARENT_THEMES, response));
    } catch (error) {
        yield put(ThemesApiResponseError(GET_PARENT_THEMES, error));
    }
}

// create
// function* onAddSubject({ payload: subject }) {
//     try {
//         const response = yield call(addNewSubjectData, subject);
//         yield put(addSubjectSuccess(response));
//     } catch (error) {
//         yield put(addSubjectFail(error));
//     }
// }

// update subject
function* onUpdateTheme({ payload: theme }) {
    try {
        const response = yield call(updateThemeDataApi, theme);
        yield put(updateThemeSuccess(THEME_SUCCESS, response));
    } catch (error) {
        yield put(updateThemeFail(THEME_FAIL, error));
    }
}

// delete theme
function* onDeleteTheme({ payload: theme }) {
    try {
        const response = yield call(deleteThemeData, theme);
        yield put(ThemesApiResponseSuccess(THEME_SUCCESS, response));
    } catch (error) {
        yield put(ThemesApiResponseError(THEME_FAIL, error));
    }
}

export function* watchGetThemesList() {
    yield takeEvery(GET_THEMES_LIST, getThemesList);
}

export function* watchGetThemeData() {
    yield takeEvery(GET_THEME_DATA, getThemeData);
}

export function* watchGetParentTheme() {
    yield takeEvery(GET_PARENT_THEMES, getParentThemes);
}

export function* watchUpdateTheme() {
    yield takeEvery(UPDATE_THEME, onUpdateTheme);
}

export function* watchDeleteTheme() {
    yield takeEvery(DELETE_THEME, onDeleteTheme);
}


function* ThemesSaga() {

    yield all([
        fork(watchGetThemesList),
        fork(watchGetThemeData),
        fork(watchGetParentTheme),
        fork(watchUpdateTheme),
        fork(watchDeleteTheme),
    ]);
}
  
export default ThemesSaga;

