import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

import Subjects from "./titles/subjects/reducer";
import Organisation from "./general/organisation/reducer";
import Task from "./task/reducer";
import Content from "./titles/content/reducer";
import Common from "./common/reducer";
import GroupAdmin from "./general/groupadmin/reducer";
import Themes from "./titles/themes/reducer";

const rootReducer = combineReducers({
    // public
    Layout,
    Login,
    Account,
    ForgetPassword,
    Profile,
    Subjects,
    Organisation,
    Task,
    Content,
    Common,
    GroupAdmin,
    Themes,
});

export default rootReducer;