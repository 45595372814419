import React, { useState, useEffect} from "react";
import { Link } from 'react-router-dom';

//redux
import { useSelector, useDispatch } from "react-redux";

//i18n
import { useTranslation } from "react-i18next";

import DeleteModal from "../../../Components/Common/DeleteModal";
import SuccessModal from "../../../Components/Common/SuccessModal";

const GroupList = (props) => {
    const dispatch = useDispatch();
    const { history, list } = props;
    const { t } = useTranslation();

    const [groupList, setGroupList] = useState([]);
    const [group, setGroup] = useState([]);

    const [groupSelected, setGroupSelected] = useState([]);

    // Delete Group
    const [deleteModal, setDeleteModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);

    const onClickDelete = (group) => {
        setGroup(group);
        setDeleteModal(true);
    };

    const handleDeleteGroup = () => {
        if (group.GroupId) {
            // dispatch(deleteGroup(group));
            setDeleteModal(false);

            list.map((item, index) => { 
                if (group.GroupId === item.SubjectId) {
                    list.splice(index, 1);
                }

                return true;
            });
            
            setGroupList(list);

            setTimeout(() => { 
                setSuccessModal(true);
            }, 500);
        }
    };

    const successModalAction = () => {
        // reset all after success
        setSuccessModal(false);
        setTimeout( () => {window.location.reload(true)}, 500);
    };

    const multipleItems = e => {
        props.groupAdminArray.push(e.target.value);
    };

    useEffect(() => {
        setGroupList(list);
    }, [list]);

    return (
      <React.Fragment>      
         <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteGroup}
                onCloseClick={() => setDeleteModal(false)}
            />
          <SuccessModal
              show={successModal}
              onCloseClick={successModalAction}
              Message="Successfully deleted."
          />
          {groupList && groupList.map((group, index) => (
              <tr key={index}>
                  <th scope="row">
                      <div className="form-check">
                          <input className="form-check-input" type="checkbox" name="chk_child" value={group.GroupId}
                              onChange={e => {
                                  multipleItems(e);
                              }}
                            />
                      </div>
                  </th>
                  <td>
                      <Link className="" to={`/group-admin/group-details/${group.GroupId}`} >{group.GroupName}</Link>
                  </td>
                  <td>
                      <div className="d-inline-flex gap-2">
                          <div className="edit">
                              <Link className="btn btn-sm btn-success edit-item-btn" to={`/group-admin/group-details/${group.GroupId}`} >{t("Edit")}</Link>
                          </div>
                          <div className="remove">
                              <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" onClick={() => { onClickDelete(group); }}>{t("Remove")}</button>
                          </div>
                      </div>
                  </td>
              </tr>
          ))}
      </React.Fragment>
    );
};

export default GroupList;