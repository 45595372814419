import {
    GET_SUBJECT_LIST,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    ADD_NEW_SUBJECT,
    ADD_SUBJECT_SUCCESS,
    ADD_SUBJECT_FAIL,
    GET_SUBJECT_DATA,
    GET_PARENT_SUBJECTS,
    UPDATE_SUBJECT,
    UPDATE_SUBJECT_SUCCESS,
    UPDATE_SUBJECT_FAIL,
    DELETE_SUBJECT,
    DELETE_SUBJECT_SUCCESS,
    DELETE_SUBJECT_FAIL,
    DELETE_MULTIPLE_SUBJECTS,
    DELETE_MULTIPLE_SUBJECTS_SUCCESS,
    DELETE_MULTIPLE_SUBJECTS_FAIL,
    RESET_FLAG_VALUES,
    GET_CONTENT_SUBJECTS,
    UPDATE_CONTENT_SUBJECTS,
    GET_SEO_SUBJECTS,
    UPDATE_CONTENT_SUBJECTS_SUCCESS,
    UPDATE_CONTENT_SUBJECTS_FAIL,
    UPDATE_SEO_SUBJECTS,
    UPDATE_SEO_SUBJECTS_SUCCESS,
    UPDATE_SEO_SUBJECTS_FAIL,
    RESET_SUBJECT_VALUES,
    GET_CONTENT_BOOKS_SEARCH,
    DELETE_SEO_SUBJECT,
    DELETE_SEO_SUBJECT_SUCESS,
    DELETE_SEO_SUBJECT_FAIL,
    DELETE_CONTENT_SUBJECT,
    DELETE_CONTENT_SUBJECT_SUCESS,
    DELETE_CONTENT_SUBJECT_FAIL
} from "./actionType";

export const getSubjectList = filters => ({
    type: GET_SUBJECT_LIST,
    params: filters
});

export const getSubjectData = subject => ({
    type: GET_SUBJECT_DATA,
    payload: subject
});

export const getParentSubjects = subject => ({
    type: GET_PARENT_SUBJECTS,
    payload: subject
});

export const getContentSubjects = filters => ({
    type: GET_CONTENT_SUBJECTS,
    params: filters
});

export const getContentBooksSearch = filters => ({
    type: GET_CONTENT_BOOKS_SEARCH,
    params: filters
});

export const getSeoSubjects = filters => ({
    type: GET_SEO_SUBJECTS,
    params: filters
});

// common success
export const SubjectApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const SubjectApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const addNewSubject = subject => ({
    type: ADD_NEW_SUBJECT,
    payload: subject,
});

export const addSubjectSuccess = subject => ({
    type: ADD_SUBJECT_SUCCESS,
    payload: subject,
});

export const addSubjectFail = error => ({
    type: ADD_SUBJECT_FAIL,
    payload: error,
});

export const updateSubject = subject => ({
    type: UPDATE_SUBJECT,
    payload: subject,
});

export const updateSubjectSuccess = subject => ({
    type: UPDATE_SUBJECT_SUCCESS,
    payload: subject,
});

export const updateSubjectFail = error => ({
    type: UPDATE_SUBJECT_FAIL,
    payload: error,
});

export const deleteSubject = subject => ({
    type: DELETE_SUBJECT,
    payload: subject,
});

export const deleteSubjectSuccess = (actionType, data) => ({
    type: DELETE_SUBJECT_SUCCESS,
    payload: {actionType, data},
});
  
export const deleteSubjectFail = (actionType, error) => ({
    type: DELETE_SUBJECT_FAIL,
    payload: {actionType, error},
});

export const deleteSeoSubject = subject => ({
    type: DELETE_SEO_SUBJECT,
    payload: subject,
});

export const deleteSeoSubjectSuccess = (actionType, data) => ({
    type: DELETE_SEO_SUBJECT_SUCESS,
    payload: {actionType, data},
});
  
export const deleteSeoSubjectFail = (actionType, error) => ({
    type: DELETE_SEO_SUBJECT_FAIL,
    payload: {actionType, error},
});

export const deleteContentSubject = subject => ({
    type: DELETE_CONTENT_SUBJECT,
    payload: subject,
});

export const deleteContentSubjectSuccess = (actionType, data) => ({
    type: DELETE_CONTENT_SUBJECT_SUCESS,
    payload: {actionType, data},
});
  
export const deleteContentSubjectFail = (actionType, error) => ({
    type: DELETE_CONTENT_SUBJECT_FAIL,
    payload: {actionType, error},
});


export const deleteMultipleSubjects = subject => ({
    type: DELETE_MULTIPLE_SUBJECTS,
    payload: subject,
});

export const deleteMultipleSubjectsSuccess = (actionType, data) => ({
    type: DELETE_MULTIPLE_SUBJECTS_SUCCESS,
    payload: {actionType, data},
});

export const deleteMultipleSubjectsFail = (actionType, error) => ({
    type: DELETE_MULTIPLE_SUBJECTS_FAIL,
    payload: {actionType, error},
});

export const updateContentSubjects = content_subjects => ({
    type: UPDATE_CONTENT_SUBJECTS,
    payload: content_subjects,
});

export const updateContentSubjectsSuccess = content_subjects => ({
    type: UPDATE_CONTENT_SUBJECTS_SUCCESS,
    payload: content_subjects,
});

export const updateContentSubjectsFail = error => ({
    type: UPDATE_CONTENT_SUBJECTS_FAIL,
    payload: error,
});

export const updateSeoSubjects = seo_subjects => ({
    type: UPDATE_SEO_SUBJECTS,
    payload: seo_subjects,
});

export const updateSeoSubjectsSuccess = seo_subjects => ({
    type: UPDATE_SEO_SUBJECTS_SUCCESS,
    payload: seo_subjects,
});

export const updateSeoSubjectsFail = error => ({
    type: UPDATE_SEO_SUBJECTS_FAIL,
    payload: error,
});

export const resetFlagValues = () => ({
    type: RESET_FLAG_VALUES
});

export const resetSubjectValues = () => ({
    type: RESET_SUBJECT_VALUES
});

