import { useEffect } from 'react';
import {
  Modal,
  ModalBody
} from 'reactstrap';

const CustomModal = ({
  body,
  header,
  footer,
  callBack,
  isOpen,
  ...props
}) => {
  useEffect(() => {
    callBack();
  }, [callBack]);

  return (<Modal isOpen={isOpen} {...props}>
      {!!header && header}
      <ModalBody>
        {!!body && body}
      </ModalBody>
      {!!footer && footer}
  </Modal>)
}

export default CustomModal;