// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_SUBJECT_LIST = "GET_SUBJECT_LIST";

/**
 * GET SUBJECT DATA
 */
export const GET_SUBJECT_DATA = "GET_SUBJECT_DATA";
export const GET_PARENT_SUBJECTS = "GET_PARENT_SUBJECTS";

/**
* Add SUBJECT
*/
export const ADD_NEW_SUBJECT = "ADD_NEW_SUBJECT";
export const ADD_SUBJECT_SUCCESS = "ADD_SUBJECT_SUCCESS";
export const ADD_SUBJECT_FAIL = "ADD_SUBJECT_FAIL";

/**
 * Edit SUBJECT
 */
export const UPDATE_SUBJECT = "UPDATE_SUBJECT";
export const UPDATE_SUBJECT_SUCCESS = "UPDATE_SUBJECT_SUCCESS";
export const UPDATE_SUBJECT_FAIL = "UPDATE_SUBJECT_FAIL";

/**
 * Get Content Subjects
 */
export const GET_CONTENT_SUBJECTS = "GET_CONTENT_SUBJECTS";
export const UPDATE_CONTENT_SUBJECTS = "UPDATE_CONTENT_SUBJECTS";
export const UPDATE_CONTENT_SUBJECTS_SUCCESS = "UPDATE_CONTENT_SUBJECTS_SUCCESS";
export const UPDATE_CONTENT_SUBJECTS_FAIL = "UPDATE_CONTENT_SUBJECTS_FAIL";

export const DELETE_CONTENT_SUBJECT = "DELETE_CONTENT_SUBJECT";
export const DELETE_CONTENT_SUBJECT_SUCESS = "DELETE_CONTENT_SUBJECT_SUCESS";
export const DELETE_CONTENT_SUBJECT_FAIL = "DELETE_CONTENT_SUBJECT_FAIL";

export const GET_CONTENT_BOOKS_SEARCH = "GET_CONTENT_BOOKS_SEARCH";
/**
 * Get Subject SEO
 */
export const GET_SEO_SUBJECTS = "GET_SEO_SUBJECTS";
export const UPDATE_SEO_SUBJECTS = "UPDATE_SEO_SUBJECTS";
export const UPDATE_SEO_SUBJECTS_SUCCESS = "UPDATE_SEO_SUBJECTS_SUCCESS";
export const UPDATE_SEO_SUBJECTS_FAIL = "UPDATE_SEO_SUBJECTS_FAIL";

export const DELETE_SEO_SUBJECT = "DELETE_SEO_SUBJECT";
export const DELETE_SEO_SUBJECT_SUCESS = "DELETE_SEO_SUBJECT_SUCESS";
export const DELETE_SEO_SUBJECT_FAIL = "DELETE_SEO_SUBJECT_FAIL";

/**
 * Delete SUBJECT
 */
export const DELETE_SUBJECT = "DELETE_SUBJECT";
export const DELETE_SUBJECT_SUCCESS = "DELETE_SUBJECT_SUCCESS";
export const DELETE_SUBJECT_FAIL = "DELETE_SUBJECT_FAIL";

export const DELETE_MULTIPLE_SUBJECTS = "DELETE_MULTIPLE_SUBJECTS";
export const DELETE_MULTIPLE_SUBJECTS_SUCCESS = "DELETE_MULTIPLE_SUBJECTS_SUCCESS";
export const DELETE_MULTIPLE_SUBJECTS_FAIL = "DELETE_MULTIPLE_SUBJECTS_FAIL";

export const RESET_FLAG_VALUES = "RESET_FLAG_VALUES";
export const RESET_SUBJECT_VALUES = "RESET_SUBJECT_VALUES";