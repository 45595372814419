import {
    GET_THEMES_LIST,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    RESET_THEMES_VALUES,
    GET_THEME_DATA,
    GET_PARENT_THEMES,
    DELETE_THEME,
    UPDATE_THEME,
    THEME_SUCCESS,
    THEME_FAIL
} from "./actionType";

export const getThemesList = filters => ({
    type: GET_THEMES_LIST,
    params: filters
});

export const getThemeData = theme => ({
    type: GET_THEME_DATA,
    payload: theme
});

export const getParentThemes = theme => ({
    type: GET_PARENT_THEMES,
    payload: theme
});

export const updateTheme = theme => ({
    type: UPDATE_THEME,
    payload: theme,
});

export const updateThemeSuccess = theme => ({
    type: THEME_SUCCESS,
    payload: theme,
});

export const updateThemeFail = error => ({
    type: THEME_FAIL,
    payload: error,
});

export const deleteTheme = theme => ({
    type: DELETE_THEME,
    payload: theme,
});

export const resetThemesValues = () => ({
    type: RESET_THEMES_VALUES
});

// common success
export const ThemesApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const ThemesApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

