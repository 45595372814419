import React, { useEffect, useState } from "react";
import { 
    Row, 
    Col, 
    CardBody, 
    Card,
    Alert, 
    Container, 
    Input, 
    Label, 
    Form, 
    FormFeedback,
    Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";

import BreadCrumb from '../../../../Components/Common/BreadCrumb';

import { getGroupAdminData, resetGaValues, updateGroupAdmin } from "../../../../store/general/groupadmin/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";

import { Editor} from '@tinymce/tinymce-react';

import FailedModal from "../../../../Components/Common/FailedModal";
import SuccessModal from "../../../../Components/Common/SuccessModal";

import GroupAdminEdit from "../GroupAdminEdit";

const GroupAdminGroupDetails = (props) => {
    const dispatch  = useDispatch();
    const { t, i18n } = useTranslation();
    const { history, id, setTitle } = props;

     // subjectList Temporary Parent Data
     const { groupAdminData, error, success } = useSelector((state) => ({
        groupAdminData: state.GroupAdmin.groupAdminData,
        error: state.GroupAdmin.error,
        success: state.GroupAdmin.success
    }));
    
    const groupAdminId = props.match.params.id;

    const [failedModal, setFailedModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);

    useEffect(() => {
        dispatch(getGroupAdminData(groupAdminId));
    }, [dispatch, groupAdminId]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            groupname:  groupAdminData?.GroupName || "",
        },
        validationSchema: Yup.object({
            groupname: Yup.string().required("Please Enter Group Name"),
        }),
        onSubmit: (values) => {

            let data = {
                GroupId: groupAdminData.GroupId,
                GroupName: values.groupname
            };

            console.log(data);
            dispatch(updateGroupAdmin(data));
        }
    });
    
    useEffect(() => {
        if (error && groupAdminData) {
            setFailedModal(true);
            setTimeout(() => {
                dispatch(resetGaValues());
            }, 1000);
        }
        
        if (success && !error) {
            setSuccessModal(true);
            dispatch(getGroupAdminData(groupAdminId));
        }

    }, [dispatch, error, success, groupAdminData, groupAdminId]);

    const successModalAction = () => {
        setSuccessModal(false);
        dispatch(resetGaValues());
    }

    return (
        <React.Fragment>
            <GroupAdminEdit>
                <FailedModal
                        show={failedModal}
                        onCloseClick={() => setFailedModal(false)}
                    /> 

                    <SuccessModal
                        show={successModal}
                        onCloseClick={successModalAction}
                        Message="Successfully updated subject."
                    />

                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                        }} 
                        className="needs-validation" action="#">

                        <Row>
                            <Col lg={8}>
                                <Card>
                                    <CardBody>

                                        <div className="mb-3">
                                            <Label className="form-label" htmlFor="groupname">{t('Group Name')}<span className="text-danger">*</span></Label>
                                            <Input
                                                id="groupname"
                                                name="groupname"
                                                className="form-control"
                                                placeholder={t('Group Name')}
                                                type="text"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.groupname || ""}
                                                invalid={
                                                    validation.touched.groupname && validation.errors.groupname ? true : false
                                                }
                                            />
                                            {validation.touched.groupname && validation.errors.groupname ? (
                                                <FormFeedback type="invalid"><div>{validation.errors.groupname}</div></FormFeedback>
                                            ) : null}
                                        </div>
                                    </CardBody>
                                    <div className="mb-3" style={{ 'marginLeft' : '10px'}}>
                                        <button type="submit" className="btn btn-primary w-sm mr-2" style={{ 'marginRight' : '10px'}}>
                                            {t('Update')}
                                        </button>

                                        <Link className="btn btn-warning w-sm me-1" to="/subjects">{t('Cancel')}</Link>
                                    </div>
                                </Card>
                            </Col>
                        </Row>

                    </Form>
            </GroupAdminEdit>
        </React.Fragment>
    );
};

export default GroupAdminGroupDetails;
