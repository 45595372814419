import {
    GET_SUBJECT_LIST,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    ADD_SUBJECT_SUCCESS,
    ADD_SUBJECT_FAIL,
    GET_SUBJECT_DATA,
    GET_PARENT_SUBJECTS,
    UPDATE_SUBJECT_SUCCESS,
    UPDATE_SUBJECT_FAIL,
    DELETE_SUBJECT_SUCCESS,
    DELETE_SUBJECT_FAIL,
    DELETE_MULTIPLE_SUBJECTS,
    DELETE_MULTIPLE_SUBJECTS_SUCCESS,
    DELETE_MULTIPLE_SUBJECTS_FAIL,
    RESET_FLAG_VALUES,
    GET_CONTENT_SUBJECTS,
    GET_SEO_SUBJECTS,
    UPDATE_CONTENT_SUBJECTS_SUCCESS,
    UPDATE_CONTENT_SUBJECTS_FAIL,
    UPDATE_SEO_SUBJECTS_SUCCESS,
    UPDATE_SEO_SUBJECTS_FAIL,
    RESET_SUBJECT_VALUES,
    GET_CONTENT_BOOKS_SEARCH,
    DELETE_SEO_SUBJECT_SUCESS,
    DELETE_SEO_SUBJECT_FAIL,
    DELETE_CONTENT_SUBJECT_SUCESS,
    DELETE_CONTENT_SUBJECT_FAIL
} from "./actionType";

const INIT_STATE = {
    subjectList: [],
    subjectData: [],
    parentData: [],
    contentSubjects: [],
    seoSubjects: [],
    error: "",
    response: "",
    success: false,
    isSubjectUpdate: false,
    isLoading: true,
    isContentSubjectsError: false,
    contentBooksSearch: []
};

const Subjects = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_SUBJECT_LIST:
                    return {
                        ...state,
                        subjectList: action.payload.data,
                    };
     
                case GET_SUBJECT_DATA: 
                    return {
                        ...state,
                        subjectData: action.payload.data,
                    };

                case GET_PARENT_SUBJECTS: 
                    return {
                        ...state,
                        parentData: action.payload.data,
                    };

                case GET_CONTENT_SUBJECTS:
                    return {
                        ...state,
                        contentSubjects: action.payload.data,
                        isLoading: false
                    };

                case GET_CONTENT_BOOKS_SEARCH:
                    return {
                        ...state,
                        contentBooksSearch: action.payload.data
                    };

                case GET_SEO_SUBJECTS:
                    return {
                        ...state,
                        seoSubjects: action.payload.data,
                    };

                default:
                    return { ...state };
            }

        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_SUBJECT_LIST:
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                case GET_SUBJECT_DATA: 
                    return {
                        ...state,
                        error: action.payload.error,
                    };

                case GET_PARENT_SUBJECTS: 
                    return {
                        ...state,
                        error: action.payload.error,
                    };

                case GET_CONTENT_SUBJECTS:
                    return {
                        ...state,
                        error: action.payload.error,
                        isLoading: false
                    };

                case GET_CONTENT_BOOKS_SEARCH:
                    return {
                        ...state,
                        error: action.payload.error,
                    };

                case GET_SEO_SUBJECTS:
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                
                default:
                    return { ...state };
            }

        case GET_SUBJECT_LIST: {
            return {
                ...state,
            };
        }

        case ADD_SUBJECT_SUCCESS:
            return {
                ...state,
                success: true,
            };

        case ADD_SUBJECT_FAIL:
            return {
                ...state,
                error: action.payload,
                success: false,
            };
        
        case UPDATE_SUBJECT_SUCCESS:
            return {
                ...state,
                subjectData: action.payload.data,
                success: true,
                isSubjectUpdate: true
            };

        case UPDATE_SUBJECT_FAIL:
            return {
                ...state,
                error: action.payload,
                success: false
            };

        case UPDATE_CONTENT_SUBJECTS_SUCCESS:
            return {
                ...state,
                response: action.payload.data,
                success: true,
                isSubjectUpdate: true
            };
        case UPDATE_CONTENT_SUBJECTS_FAIL:
            return {
                ...state,
                error: action.payload,
                isContentSubjectsError: true,
                success: false,
            };

        case UPDATE_SEO_SUBJECTS_SUCCESS:
            return {
                ...state,
                response: action.payload.data,
                success: true,
                isSubjectUpdate: true
            };

        case UPDATE_SEO_SUBJECTS_FAIL:
            return {
                ...state,
                error: action.payload,
                success: false,
            };

        case DELETE_SUBJECT_SUCCESS:
            return {
                ...state,
                response: action.payload.data,
            };

        case DELETE_SUBJECT_FAIL:
            return {
                ...state,
                error: action.payload.data,
            };

        case DELETE_MULTIPLE_SUBJECTS_SUCCESS:
            return {
                ...state,
                response: action.payload.data,
                success: true,
            };

        case DELETE_MULTIPLE_SUBJECTS_FAIL:
            return {
                ...state,
                error: action.payload.data,
            };
        
        case DELETE_SEO_SUBJECT_SUCESS:
            return {
                ...state,
                response: action.payload.data,
                isSubjectUpdate: true
            };

        case DELETE_SEO_SUBJECT_FAIL:
            return {
                ...state,
                error: action.payload.data,
            };
        
        case DELETE_CONTENT_SUBJECT_SUCESS:
            return {
                ...state,
                response: action.payload.data,
                isSubjectUpdate: true
            };

        case DELETE_CONTENT_SUBJECT_FAIL:
            return {
                ...state,
                error: action.payload.data,
            };

        case RESET_FLAG_VALUES :
                return {
                    ...state,
                    subjectList: [],
                    subjectData: [],
                    parentData: [],
                    contentSubjects: [],
                    seoSubjects: [],
                    error: "",
                    response: "",
                    success: false,
                    isSubjectUpdate: false,
                    isLoading: true,
                    isContentSubjectsError: false,
                    contentBooksSearch: []
                };
        case RESET_SUBJECT_VALUES :
            return {
                ...state,
                error: "",
                response: "",
                success: false,
            };
        default:
            return { ...state };
    }
};

export default Subjects;