import { 
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    GET_ORGANISATION_PENDING_LIST,
    GET_ORGANISATION
} from "./actionType";

export const getOrganisation = (filters) => ({
    type: GET_ORGANISATION,
    params: filters
});

export const getOrganisationPendingList = (filters) => ({
    type: GET_ORGANISATION_PENDING_LIST,
    params: filters
});

// common success
export const OrganisationApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const OrganisationApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});