// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_THEMES_LIST = "GET_THEMES_LIST";

export const GET_THEME_DATA = "GET_THEME_DATA";
export const GET_PARENT_THEMES = "GET_PARENT_THEMES";

export const UPDATE_THEME = "UPDATE_THEME";
export const DELETE_THEME = "DELETE_THEME";

export const THEME_SUCCESS = "UPDATE_THEME_SUCCESS";
export const THEME_FAIL = "UPDATE_THEME_FAIL";

export const RESET_THEMES_VALUES = "RESET_THEMES_VALUES";