import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody } from "reactstrap";

const FailedModal = ({ show, onCloseClick, failedMesssage }) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
      <ModalBody className="py-3 px-5">
        <div className="mt-2 text-center">
            <lord-icon
                src="https://cdn.lordicon.com/tdrtiskw.json"
                trigger="loop"
                colors="primary:#f7b84b,secondary:#405189"
                style={{ width: "130px", height: "130px" }}>
            </lord-icon>
            <div className="mt-4 pt-4">
                <h5 className="mb-3">{failedMesssage}</h5>
                <p className="text-muted"> Please try again.</p>
            </div>
        </div>
        <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
          <button
            type="button"
            className="btn w-sm btn-danger"
            data-bs-dismiss="modal"
            onClick={onCloseClick}
          >
            Close
          </button>
        </div>
      </ModalBody>
    </Modal>
  );
};

FailedModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
};

export default FailedModal;
