import React, {useState, useEffect, useRef, useCallback} from "react";
import { api } from "../../config";

import { Link } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

//redux
import { useSelector, useDispatch } from "react-redux";
import { 
    Col, 
    Row,
    Input,
    List,
    ListGroup,
    ListGroupItem
} from 'reactstrap';

//i18n
import { useTranslation } from "react-i18next";
import { getContentBooksSearch } from "../../store/actions";

const AutoCompleteInput = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { response, success, error, isLoading, contentBooksSearch } = useSelector((state) => ({
        contentBooksSearch: state.Subjects.contentBooksSearch,
        response : state.Subjects.response,
        success : state.Subjects.success,
        error : state.Subjects.error,
        isLoading: state.Subjects.isLoading
    }));

    const {
        customInputClass,
        customInputName,
        customInputId,
        customInputValue,
        customInputIndex,
        customId,
        placeholder,
        customFormFields,
        setFormFields,
        typeModal,
        setTypeModal,
        typeaheadRef
    } = props;

    const [books, setBooks] = useState([]);
    const [indexElement, setIndexElement] = useState(0);
    const [searchString, setSearchString] = useState('');

    const [load, setLoading] = useState(false);

    useEffect(() => {
        let matches = [];
        if (contentBooksSearch.data && contentBooksSearch.data.length > 0) {
            // reset books after every search
            matches = contentBooksSearch.data.filter(contentBooks => {
                const regex = new RegExp(`${searchString}`, "gi");
                return contentBooks && contentBooks.Title.match(regex);
            });

            if (matches.length > 0) {
                setTimeout( () => {
                    setBooks(matches);
                }, 500);
            }
        }
    }, [contentBooksSearch, searchString]);

    const handleFormInputChange = (event, customInputIndex) => {
        let string = event;

         // manual request for now
        const token = JSON.parse(localStorage.getItem("accessToken"));
        var opts = {  
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token 
            }
        }

        // manual for now
        fetch(`${api.API_URL}/books?keywords=${string}`, opts)
            .then((resp) => resp.json())
            .then((response) => {
                setBooks(response.data);
            });
        setSearchString(string);
        setIndexElement(customInputIndex ? customInputIndex : indexElement);
    }

    const handleOnClick = (book) => {
        let data = [...customFormFields];

        if (book) {
            data[indexElement]['content_id'] = book.ContentId;
            data[indexElement]['title'] = book.Title;
            setFormFields(data);
        }

        setBooks([]);
    };

    const filterBy = () => true;

    return (
        <React.Fragment>
            <AsyncTypeahead
                style={{'width' : '100%', 'marginRight': '15px'}}
                filterBy={filterBy}
                id={`title_${customInputIndex}`}
                labelKey="Title"
                isLoading={load}
                minLength={3}
                onSearch={event => handleFormInputChange(event, customInputIndex)}
                options={books}
                placeholder="Search for a Titles..."
                defaultInputValue={`${customInputValue}`}
                ref={typeaheadRef}
                renderMenuItemChildren={(book) => (
                    <>
                        <span
                            style={{'display' : 'block', 'padding' : '0.55rem 1.2rem', 'borderBottom' : '1px solid #eee'}}
                            onClick={() => handleOnClick(book)}
                        >{book.Title}</span>
                    </>
                )}
            />
        </React.Fragment>
    );
};

export default AutoCompleteInput;
