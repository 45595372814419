import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useProfile } from "../Components/Hooks/UserHooks";

const Navdata = () => {
    const history = useHistory();
    //state data
    const [isTitles, setIsTitles] = useState(false);
    const [isCategorisation, setIsCategorisation] = useState(false);

    const [isGeneral, setIsGeneral] = useState(false);
    const [isOrganisation, setIsOrganisation] = useState(false);

    const [isProduction, setIsProduction] = useState(false);
    
    const [isTask, setIsTask] = useState(false);
    const [iscurrentState, setIscurrentState] = useState('Titles');

    // const { sidebarMenus } = useProfile();

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
    
        if (iscurrentState !== 'Titles') {
            setIsTitles(false);
        }
    
        if (iscurrentState !== 'General') {
            setIsGeneral(false);
        }
    
        if (iscurrentState !== 'Production') {
            setIsProduction(false);
        }
    
        if (iscurrentState !== 'Task') {
            setIsTask(false);
        }
    }, [iscurrentState]);

    const menuItems = [
        {
            label: "Navigation",
            isHeader: true,
        },
        {
            id: "titles",
            label: "Title Management",
            icon: "ri-book-2-line",
            link: "/#",
            stateVariables: isTitles,
            click: function (e) {
                e.preventDefault();
                setIsTitles(!isTitles);
                setIscurrentState('Titles');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "books",
                    label: "Books",
                    link: "/books",
                    parentId: "titles",
                },
                {
                    id: "contributors",
                    label: "Contributors",
                    link: "/contributors",
                    parentId: "titles",
                },
                {
                    id: "listworks",
                    label: "List of Works",
                    link: "/list-of-works",
                    parentId: "titles",
                },
                {
                    id: "categorisation",
                    label: "Categorisation",
                    link: "/#",
                    parentId: "titles",
                    isChildItem: true,
                    click: function (e) {
                        e.preventDefault();
                        setIsCategorisation(!isCategorisation);
                    },
                    stateVariables: isCategorisation,
                    childItems: [
                        {
                            id: 1,
                            label: "Subjects",
                            link: "/subjects",
                            parentId: "titles",
                        },
                        {
                            id: 2,
                            label: "Themes",
                            link: "/themes",
                            parentId: "titles",
                        },
                        {
                            id: 3,
                            label: "Categories",
                            link: "/categories",
                            parentId: "titles",
                        },
                        {
                            id: 4,
                            label: "Classifications",
                            link: "/classifications",
                            parentId: "titles",
                        },
                        {
                            id: 5,
                            label: "Pub. Season",
                            link: "/publication-season",
                            parentId: "titles",
                        },
                    ]
                },
            ],
        },
        {
            id: "production",
            label: "Production",
            icon: "ri-book-2-line",
            link: "/#",
            stateVariables: null,
            click: function (e) {
                
            }
        },
        {
            id: "task",
            label: "Task",
            icon: "ri-list-check",
            link: "/#",
            stateVariables: isTask,
            click: function (e) {
                e.preventDefault();
                setIsTask(!isTask);
                setIscurrentState('Task');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "task",
                    label: "List",
                    link: "/tasks",
                    parentId: "tasksList",
                },
            ],
        },
        {
            id: "general",
            label: "General",
            icon: "ri-list-settings-fill",
            link: "/#",
            stateVariables: isGeneral,
            click: function (e) {
                e.preventDefault();
                setIsGeneral(!isGeneral);
                setIscurrentState('General');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "organisation",
                    label: "Organisation Admin",
                    link: "/#",
                    parentId: "general",
                    isChildItem: true,
                    click: function (e) {
                        e.preventDefault();
                        setIsOrganisation(!isOrganisation);
                    },
                    stateVariables: isOrganisation,
                    childItems: [
                        {
                            id: 1,
                            label: "Organisation",
                            link: "/organisation",
                            parentId: "general",
                        },
                        {
                            id: 1,
                            label: "Organisation Pending",
                            link: "/organisation/pending",
                            parentId: "general",
                        },
                    ]
                },
                {
                    id: "group",
                    label: "Group Admin",
                    link: "/group-admin",
                    parentId: "general",
                },
            ],
        },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;