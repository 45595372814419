import {
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    GET_ORGANISATION_PENDING_LIST,
    GET_ORGANISATION
  } from "./actionType";
  
  const INIT_STATE = {
      organisationList: [],
      pendingList: [],
      error: "",
      response: "",
  };
  
  const Organisation = (state = INIT_STATE, action) => {
      switch (action.type) {
          case API_RESPONSE_SUCCESS:
              switch (action.payload.actionType) {
                    case GET_ORGANISATION:
                        return {
                            ...state,
                            organisationList: action.payload.data,
                        };

                    case GET_ORGANISATION_PENDING_LIST:
                        return {
                            ...state,
                            pendingList: action.payload.data,
                        };
        
                  default:
                      return { ...state };
              }
  
          case API_RESPONSE_ERROR:
              switch (action.payload.actionType) {
                  case GET_ORGANISATION_PENDING_LIST:
                      return {
                          ...state,
                          error: action.payload.error,
                      };
                  default:
                      return { ...state };
                }
            default:
                return { ...state };
        }
  };
  
  export default Organisation;