import React, { useEffect, useState } from 'react';
import { 
    Card, 
    CardBody, 
    Col, 
    Container, 
    Input, 
    Label, 
    Row, 
    Button, 
    Form, 
    FormFeedback, 
    Alert,
    Spinner
} from 'reactstrap';

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { loginUser, resetLoginFlag } from "../../store/actions";
import { useProfile } from '../../Components/Hooks/UserHooks';

//import images
import logoDefault from "../../assets/images/logo-default.png";
import { t } from 'i18next';

import FailedModal from '../../Components/Common/FailedModal';

const Login = (props) => {
    const dispatch = useDispatch();

    const [failedModal, setFailedModal] = useState(false);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            username: '',
            password: '',
        },
        validationSchema: Yup.object({
            username: Yup.string().required("Please Enter Your username"),
            password: Yup.string().required("Please Enter Your Password"),
        }),
        onSubmit: (values) => {
            //add loading to have process
            document.getElementById('spinnerLogin').classList.remove('d-none');
            dispatch(loginUser(values, props.history));
        }
    });

    const { error, isUserLogout } = useSelector(state => ({
        error: state.Login.error,
        isUserLogout: state.Login.isUserLogout
    }));

    useEffect(() => {
        if (error) document.getElementById('spinnerLogin').classList.add('d-none');

        setTimeout(()=> {
            dispatch(resetLoginFlag())
        } , 5000);
    }, [dispatch, error]);

    document.title="Stison Publishing Solutions | Sign in";
    return (
        <React.Fragment>
            <div className="auth-page-content">                
                <Container className="login-wrapper">
                <FailedModal
                        show={failedModal}
                        onCloseClick={() => setFailedModal(false) }
                    />
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Link to="/" className="d-inline-block auth-logo">
                                <img src={logoDefault} alt="" height="50" />
                            </Link>
                            <Card className="mt-2">
                                <CardBody className="p-4">
                                    <div className="p-2 mt-4">
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                            action="#">

                                            <div className="mb-3">
                                                <Label htmlFor="username" className="form-label">{t('Username')}</Label>
                                                <Input
                                                    name="username"
                                                    className="form-control"
                                                    placeholder="Enter username"
                                                    type="text"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.username || ""}
                                                    invalid={
                                                        validation.touched.username && validation.errors.username ? true : false
                                                    }
                                                />
                                                {validation.touched.username && validation.errors.username ? (
                                                    <FormFeedback type="invalid">{validation.errors.username}</FormFeedback>
                                                ) : null}
                                            </div>

                                            <div className="mb-3">
                                                <Label className="form-label" htmlFor="password-input">{t('Password')}</Label>
                                                <div className="position-relative auth-pass-inputgroup mb-3">
                                                    <Input
                                                        name="password"
                                                        value={validation.values.password || ""}
                                                        type="password"
                                                        className="form-control pe-5"
                                                        placeholder="Enter Password"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        invalid={
                                                            validation.touched.password && validation.errors.password ? true : false
                                                        }
                                                    />
                                                    {validation.touched.password && validation.errors.password ? (
                                                        <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="form-check">
                                                <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>

                                                <div className="float-end">
                                                    <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                                </div>
                                            </div>

                                            <div className="mt-4">
                                                <Button color="info" className="btn-load w-100" type="submit">
                                                    <span className="d-flex align-items-center">
                                                        <Spinner id='spinnerLogin' size="sm" className="flex-shrink-0 d-none"> Sign In </Spinner>
                                                        <span className="flex-grow-1 ms-2">
                                                            Sign In
                                                        </span>
                                                    </span>
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                </CardBody>
                            </Card>
                            {error ? (
                                <Alert color="danger" >
                                    {"Invalid User Name / Password entered."}
                                </Alert>
                            ) : null}

                            <div className="mt-4 text-center">
                                <p className="mb-0">Don't have an account ? <Link to="/register" className="fw-semibold text-primary text-decoration-underline"> Signup </Link> </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withRouter(Login);