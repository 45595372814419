import React, { useEffect, useState } from "react";
import { 
    Row, 
    Col, 
    CardBody, 
    Card,
    Alert, 
    Container, 
    Input, 
    Label, 
    Form, 
    FormFeedback,
    Nav, NavItem, NavLink, TabContent, TabPane,
    CardHeader
} from "reactstrap";

import BreadCrumb from '../../../../Components/Common/BreadCrumb';

import {
    deleteSeoSubject,
    getSeoSubjects,
    resetFlagValues,
    updateSeoSubjects
} from "../../../../store/titles/subjects/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useParams } from "react-router-dom";

import { v4 as uuidv4 } from "uuid";

import "quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";

import FailedModal from "../../../../Components/Common/FailedModal";
import SuccessModal from "../../../../Components/Common/SuccessModal";
import DeleteModal from "../../../../Components/Common/DeleteModal";

import SubjectEdit from "../SubjectEdit";

const SubjectsSeo = (props) => {
    const dispatch  = useDispatch();
    const { t, i18n } = useTranslation();
    const { history } = props;
    const { id } = useParams();
    const { seoSubjects, response, success, error, isSubjectUpdate } = useSelector((state) => state.Subjects);

    const [successModal, setSuccessModal] = useState(false);
    const [failedModal, setFailedModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const [queryParams, setQueryParams] = useState({type_id: id, type: 'Subject'});

    const [orgId, setOrgId] = useState(null);
    const [seoType, setSeoType] = useState(null);
    const [deleteIndex, setDeleteIndex] = useState();
    const [loading, setLoading] = useState(true);

    const languages = {
        'en' : 'English',
        'fr' : 'French',
        'de' : 'Dutch',
        'ru' : 'Russian'
    };

    const [formFields, setFormFields] = useState([]);

    useEffect(() => {
        dispatch(getSeoSubjects(queryParams));
    }, [dispatch, queryParams]);

    useEffect(() => {
        if (seoSubjects.length > 0) {
            const fields = seoSubjects.map((r) => ({
                seo_id: r.SEOURLId,
                url: r.URL,
                language: r.Language,
                master: r.Master,
                cache: r.IsCached,
                published: r.Published,
                type: r.Type,
                organisation_id: r.OrganisationId,
            }));
        
            if (!orgId) setOrgId(seoSubjects[0].OrganisationId);
            if (!seoType) setSeoType(seoSubjects[0].Type);
        
            setFormFields(fields);
            setLoading(false);
        }
    }, [orgId, seoType, seoSubjects]);

    useEffect(() => {
        if (success && !error) {
            setSuccessModal(true);
        }

        if (isSubjectUpdate) {
            dispatch(getSeoSubjects(queryParams))
        }

    }, [dispatch, error, success, queryParams, isSubjectUpdate]);

    const handleAddFields = () => {
        let fields = {url: '',
        language: '',
        master: 0,
        cache: 0,
        published: 0,
        type: seoType,
        organisation_id: orgId,
        seo_id: uuidv4()};
        const newFormFields = [...formFields, fields];
        setFormFields(newFormFields);
    }

    const handleFormCheckChange = (event, index) => {
        let data = [...formFields];
        data[index][event.target.name] = event.target.checked;
        setFormFields(data);
    }

    const handleFormInputChange = (event, index) => {
        let data = [...formFields];
        data[index][event.target.name] = event.target.value;
        setFormFields(data);
    }

    const removeFields = () => {
        const newFields = [...formFields];
        setDeleteModal(false);

        if (Object.keys(newFields[deleteIndex]).length) {
            const fetchData = async () => {
                dispatch(deleteSeoSubject({ SEOURLId : newFields[deleteIndex].seo_id }))
                await dispatch(resetFlagValues())
            };
        
            fetchData();

            setSuccessModal( true );
        }
    }

    const handSubmit = (e) => {
        e.preventDefault();

        const data = formFields.map((item) => ({
            seo_id: typeof item.seo_id !== 'undefined' && typeof item.seo_id !== 'string' ? item.seo_id : 0,
            url: item.url,
            language: typeof item.language !== 'undefined' ? item.language : '',
            master: item.master ? 1 : 0,
            published: item.published ? 1 : 0,
            cache: item.cache ? 1 : 0,
            type: item.type,
            organisation_id: item.organisation_id,
            type_id: parseInt(id),
        }));
        
        if (data.length > 0) {
            dispatch(updateSeoSubjects(data));
        }

        setSuccessModal( true );
    }

    const successModalAction = () => {
        setSuccessModal(false);
        dispatch(resetFlagValues())
    };

    const onClickDelete = (index) => {
        setDeleteIndex(index);
        setDeleteModal(true);
    };

    return (
        <React.Fragment>
            <SubjectEdit>

                <SuccessModal
                    show={successModal}
                    onCloseClick={successModalAction}
                    Message="Successfully updated subject."
                />

                <DeleteModal
                        show={deleteModal}
                        onDeleteClick={removeFields}
                        onCloseClick={() => setDeleteModal(false)}
                        Messsage="Are you sure you wish to remove this row?"
                    />

                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        return false;
                    }} 
                    className="needs-validation" action="#">
                    <Card>
                        <CardHeader>
                            <h5 className="card-title mb-0">{t('SEO URLs')}</h5>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <Row className="align-items-start mt-2">
                                    <Col xxl={2} md={2} >
                                        <div className="mb-3 d-flex">
                                            <div className="d-block flex-shrink-0" style={{'opacity': '0'}}>
                                               <h6>{t('Delete')}</h6>
                                            </div>
                                            <div className="d-block flex-shrink-0">
                                                <h6>{t('Master')}</h6>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xxl={4} md={4} >
                                        <div className="mb-3 d-flex">
                                            <h6>{t('URL')}</h6>
                                        </div>
                                    </Col>
                                    <Col xxl={3} md={3} >
                                        <div className="mb-3 d-flex">
                                            <h6>{t('Language')}</h6>
                                        </div>
                                    </Col>

                                    <Col xxl={1} md={1} >
                                        <div className="mb-3 d-flex">
                                            <h6>{t('Published')}</h6>
                                        </div>
                                    </Col>
                                    <Col xxl={1} md={1} >
                                        <div className="mb-3 d-flex">
                                           <h6>{t('Cached')}</h6>
                                        </div>
                                    </Col>
                                </Row>
                                {formFields.map((form, index) => {
                                    return (
                                        <div id="seo-items" key={index}>
                                            <Row className="align-items-start mt-2">
                                                <Col xxl={2} md={2} >
                                                    <div className="mb-3 d-flex">
                                                        <div className="d-block flex-shrink-0">
                                                            <button className="me-3 btn btn-sm btn-soft-danger align-bottom" onClick={() => onClickDelete(index)} >
                                                                <i className="ri-delete-bin-5-fill fs-16"></i>
                                                            </button>
                                                        </div>
                                                        <div className="form-check form-radio-outline form-radio-primary align-vertical mt-2">
                                                            <Input 
                                                                name="master" 
                                                                id="master"
                                                                type="radio" 
                                                                className="form-check-input" 
                                                                checked={form.master}
                                                                onChange={event => handleFormCheckChange(event, index)}
                                                                />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col xxl={4} md={4} >
                                                    <div className="mb-3 d-flex">
                                                        <Input 
                                                            type="hidden" 
                                                            name="type"
                                                            id="type"
                                                            value={form.type || ''} 
                                                            />
                                                        <Input 
                                                            type="hidden" 
                                                            name="organisation_id"
                                                            id="organisation_id"
                                                            value={form.organisation_id || ''} 
                                                            />
                                                        <Input 
                                                            type="hidden" 
                                                            name="seo_id"
                                                            id="seo_id"
                                                            value={form.seo_id || ''} 
                                                            />
                                                        <Input 
                                                            type="text" 
                                                            name="url" 
                                                            className="form-control" 
                                                            id="url" 
                                                            placeholder="Seo URL" 
                                                            value={form.url || ''}
                                                            onChange={event => handleFormInputChange(event, index)} 
                                                            />
                                                    </div>
                                                </Col>
                                                <Col xxl={3} md={3} >
                                                    <div className="mb-3 d-flex">
                                                        <Input
                                                            name="language"
                                                            type="select"
                                                            className="form-select"
                                                            id="language"
                                                            onChange={event => handleFormInputChange(event, index)}
                                                            defaultValue={form.language}
                                                            >
                                                            <option value="">Select Language</option>
                                                            {Object.keys(languages).map(key => (
                                                                <option key={`${key}`} value={`${key}`} 
                                                                > {languages[key]}</option>
                                                            ))}
                                                        </Input>
                                                    </div>
                                                </Col>

                                                <Col xxl={1} md={1} >
                                                    <div className="form-check form-check-outline form-check-primary mt-2 d-flex text-center">
                                                        <Input 
                                                            type="checkbox" 
                                                            name="published" 
                                                            className="form-check-input" 
                                                            checked={form.published}
                                                            onChange={event => handleFormCheckChange(event, index)}
                                                              />
                                                    </div>
                                                </Col>
                                                <Col xxl={1} md={1} >
                                                    <div className="form-check form-check-outline form-check-primary mt-2 d-flex text-center">
                                                        <Input 
                                                            type="checkbox" 
                                                            name="cache" 
                                                            className="form-check-input" 
                                                            checked={form.cache} 
                                                            onChange={event => handleFormCheckChange(event, index)}
                                                             />
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                })}
                                <div className="loading" style={{ display: loading ? "block" : "none" }}>
                                    <div className="text-left">
                                        <h5 className="card-title placeholder-glow">
                                            <span className="placeholder col-6"></span>
                                        </h5>
                                        <p className="card-text placeholder-glow">
                                            <span className="placeholder col-7"></span>
                                            <span className="placeholder col-4"></span>
                                            <span className="placeholder col-4"></span>
                                            <span className="placeholder col-6"></span>
                                        </p>
                                    </div>
                                </div>
                                <button 
                                    type="button" 
                                    className="btn btn-soft-info btn-sm mt-4"
                                    onClick={handleAddFields}
                                >
                                    <i className="ri-file-add-line me-1 align-bottom"></i> {t('Add Seo Url')}
                                </button>
                            </div>
                        </CardBody>
                        <div className="mb-3 text-end">
                            <button 
                                type="submit" 
                                className="btn btn-info w-sm mr-2" 
                                style={{ 'marginRight' : '20px'}}
                                onClick={handSubmit}
                                >
                                {t('Update')}
                            </button>
                        </div>
                    </Card>
                </Form>
            </SubjectEdit>
        </React.Fragment>
    );
};

export default SubjectsSeo;
