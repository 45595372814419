import {
    CONTENT_RESPONSE_SUCCESS,
    CONTENT_RESPONSE_ERROR,
    RESET_CONTENT_VALUES,
    GET_CONTENT_LIST
} from "./actionType";

export const getContentList = filters => ({
    type: GET_CONTENT_LIST,
    params: filters
});

// common success
export const ContentResponseSuccess = (actionType, data) => ({
    type: CONTENT_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const ContentResponseError = (actionType, error) => ({
    type: CONTENT_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const resetContentValues = () => ({
    type: RESET_CONTENT_VALUES
});

