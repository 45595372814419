import React, { useState, useEffect} from "react";
import { Link } from 'react-router-dom';

//redux
import { useSelector, useDispatch } from "react-redux";
//i18n
import { useTranslation } from "react-i18next";

import { DropdownItem, DropdownToggle, UncontrolledDropdown, DropdownMenu, Button } from "reactstrap";

import DeleteModal from "../../../Components/Common/DeleteModal";
import SuccessModal from "../../../Components/Common/SuccessModal";

import { deleteSubject } from "../../../store/titles/subjects/actions";

const SubjectList = (props) => {
    const dispatch = useDispatch();
    const { history, list } = props;
    const { t } = useTranslation();

    const [subjList, setSubjList] = useState([]);
    const [subject, setSubject] = useState([]);

    const [subjectSelected, setSubjectSelected] = useState([]);

    // Delete Subjects
    const [deleteModal, setDeleteModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);

    const onClickDelete = (subject) => {
        setSubject(subject);
        setDeleteModal(true);
    };

    const handleDeleteSubject = () => {
        if (subject.SubjectId) {
            dispatch(deleteSubject(subject));
            setDeleteModal(false);

            list.map((item, index) => { 
                if (subject.SubjectId === item.SubjectId) {
                    list.splice(index, 1);
                }

                return true;
            });
            
            setSubjList(list);

            setTimeout(() => { 
                setSuccessModal(true);
            }, 500);
        }
    };

    const successModalAction = () => {
        // reset all after success
        setSuccessModal(false);
    };

    const multipleItems = e => {
        props.subjectsArray.push(e.target.value);
    };

    useEffect(() => {
        setSubjList(list);
    }, [list]);

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteSubject}
                onCloseClick={() => setDeleteModal(false)}
            />
            <SuccessModal
                show={successModal}
                onCloseClick={successModalAction}
                Message="Successfully deleted."
            />

            {subjList && subjList.map((subject, index) => (
                <tr key={index}>
                    <th scope="row">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="chk_child" value={subject.SubjectId}
                                onChange={e => {
                                    multipleItems(e);
                                }}
                             />
                        </div>
                    </th>
                    <td>
                        <Link className="list-name" to={`/subjects/general/${subject.SubjectId}`} >{subject.SubjectName}</Link>
                    </td>
                    <td>{subject.SubjectCode}</td>
                    <td>{(subject.parent ? subject.parent.SubjectName : '')}</td>
                    <td>
                        <div className="d-inline-flex gap-2">
                            <div className="edit">
                                <Link className="btn btn-sm btn-success edit-item-btn" to={`/subjects/general/${subject.SubjectId}`} >{t("Edit")}</Link>
                            </div>
                            <div className="remove">
                                <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" onClick={() => { onClickDelete(subject); }}>{t("Remove")}</button>
                            </div>
                        </div>
                    </td>
                </tr>
            ))}
        </React.Fragment>
    );
};


export default SubjectList;