import React, { useEffect, useState } from "react";
import { 
    Row, 
    Col, 
    CardBody, 
    Card,
    Alert, 
    Container, 
    Input, 
    Label, 
    Form, 
    FormFeedback,
    Nav, NavItem, NavLink,
    TabContent, TabPane
} from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import { Redirect, Link } from "react-router-dom";


// Formik Validation
import * as Yup from "yup";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import Dropzone from "react-dropzone";

import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useQuill } from "react-quilljs";
import classnames from 'classnames';
import "quill/dist/quill.snow.css";

// actions

// modals
import FailedModal from "../../../Components/Common/FailedModal";
import SuccessModal from "../../../Components/Common/SuccessModal";
import BreadCrumb from '../../../Components/Common/BreadCrumb';

const ThemesCreate = (props) => {

    const dispatch = useDispatch();
    
    // const addFile = (e) => {
    //     console.log(e.target.files[0]);
    // };

    document.title="Stison Publishing Solutions | Create Themes";

    return (
        <React.Fragment>
            <div className="page-content">
                
            </div>
        </React.Fragment>
    );
};

export default ThemesCreate;
