import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";
import { 
    Col, 
    Row 
} from 'reactstrap';
//i18n
import { useTranslation } from "react-i18next";

const Pagination = (props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const {
        typeFunction,
        currentPage,
        firstPageUrl,
        from,
        lastPage,
        lastPageUrl,
        links,
        nextPageUrl,
        path,
        perPage,
        prevPageUrl,
        to,
        total,
        keywords,
        queryParams,
        setQueryParams,
    } = props;

    const [page, setPage] = useState(1);

    const handleClick = (page, current_page, last_page) => {

        let pageNumber = Number(page);

        // prev and next buttons
        if (isNaN(pageNumber)) {
            pageNumber  = 1;
            let string  = page.replace(/[^a-zA-Z ]/g, "");
            let getPrev = string.match('Previous');
            let getNext = string.match('Next');

            if (getPrev && current_page > 1) pageNumber = current_page - 1;
            if (getNext && current_page !== last_page) pageNumber = current_page + 1;
        }

        let params = {page: pageNumber};
        if (keywords) params['keywords'] = keywords;

        setQueryParams({...queryParams, ...params});
        
        // components here to fetch
        dispatch(typeFunction(params));
        
        setPage(pageNumber);
    }

    return (
        <React.Fragment>
            <Row className="g-0 text-center text-sm-start align-items-center mb-4">
                <Col sm={4}>
                    <div>
                        <p className="mb-sm-0 text-muted">{t("Showing")} <span className="fw-semibold">{ from }</span> to <span className="fw-semibold">{ to }</span> of <span className="fw-semibold text-decoration-underline">{ total }</span> entries</p>
                    </div>
                </Col>

                <Col sm={8}>
                    <ul className="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                        {links && links.map((link, i) => (
                            <li key={i} className={`page-item ${link.active ? 'active' : ''}`}>
                                <Link to="#" onClick={ () => handleClick(link.label, currentPage, lastPage) } page={link.label} className="page-link" dangerouslySetInnerHTML={{__html:link.label}}></Link>
                            </li>
                        ))}
                    </ul>
                </Col>
            </Row>
        </React.Fragment>
    );
};


export default Pagination;
