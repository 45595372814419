import React, { useEffect } from "react";
import { 
    Button, 
    Card, 
    CardBody, 
    Col, 
    Container, 
    Row, 
    Form, 
    FormFeedback, 
    Input,
    Alert 
} from 'reactstrap';

import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

// import images
import logoDefault from "../../assets/images/stison-main-small.png";
import { userForgetPassword, userPasswordReset } from "../../store/auth/forgetpwd/actions"; 

const PasswordReset = props => {
    const history = useHistory();
    const dispatch = useDispatch();

    const query = new URLSearchParams(props.history.location.search);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            password: '',
            confirm_password: ''
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Please Enter Your Password"),
            confirm_password: Yup.string().when("password", {
                is: val => (val && val.length > 0 ? true : false),
                then: Yup.string().oneOf(
                    [Yup.ref("password")],
                    "Confirm Password Isn't Match"
                )
            })
        }),
        onSubmit: (values) => {
            let data = {
                email : query.get('email'),
                token : query.get('token'),
                password : values.password
            };
            dispatch(userPasswordReset(data));

            setTimeout( () => {
                validation.resetForm();
            }, 1000);
        }
    });

    const { passwordResetSuccess, passwordResetError } = useSelector(state => ({
        passwordResetSuccess: state.ForgetPassword.passwordResetSuccess,
        passwordResetError: state.ForgetPassword.passwordResetError,
    }));

    document.title = "Stison Publishing Solutions | Password Reset";

    return (
        <div className="auth-page-content">
            <Container  className="register-wrapper">
                <Row>
                    <Col lg={12}>
                        <Link to="/" className="d-block auth-logo text-center">
                            <img src={logoDefault} alt="" height="80" />
                        </Link>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col md={8} lg={6} xl={5}>
                        <Card className="mt-4">

                            <CardBody className="p-4">
                                <div className="text-center mt-2">
                                    <h5 className="text-primary">Create new password</h5>
                                    <p className="text-muted">Your new password must be different from previous used password.</p>
                                </div>

                                <div className="p-2">
                                {passwordResetError && passwordResetError ? (
                                    <Alert color="danger" style={{ marginTop: "13px" }}>
                                    {passwordResetError}
                                    </Alert>
                                ) : null}
                                {passwordResetSuccess ? (
                                    <Alert color="success" style={{ marginTop: "13px" }}>
                                    {passwordResetSuccess}
                                    </Alert>
                                ) : null}
                                        <Form
                                            onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}
                                            className="needs-validation" action="#">
                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="password-input">Password</label>
                                            <div className="position-relative auth-pass-inputgroup">
                                                    <Input
                                                        name="password"
                                                        type="password"
                                                        placeholder="Enter Password"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.password || ""}
                                                        invalid={
                                                            validation.touched.password && validation.errors.password ? true : false
                                                        }
                                                    />
                                                    {validation.touched.password && validation.errors.password ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.password}</div></FormFeedback>
                                                    ) : null}
                                            </div>
                                            <div id="passwordInput" className="form-text">Must be at least 8 characters.</div>
                                        </div>

                                        <div className="mb-3">
                                            <label className="form-label" htmlFor="confirm-password-input">Confirm Password</label>
                                            <div className="position-relative auth-pass-inputgroup mb-3">

                                                <Input
                                                        name="confirm_password"
                                                        type="password"
                                                        placeholder="Confirm Password"
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.confirm_password || ""}
                                                        invalid={
                                                            validation.touched.confirm_password && validation.errors.confirm_password ? true : false
                                                        }
                                                    />
                                                    {validation.touched.confirm_password && validation.errors.confirm_password ? (
                                                        <FormFeedback type="invalid"><div>{validation.errors.confirm_password}</div></FormFeedback>
                                                    ) : null}
                                            </div>
                                        </div>

                                        <div id="password-contain" className="p-3 bg-light mb-2 rounded">
                                            <h5 className="fs-13">Password must contain:</h5>
                                            <p id="pass-length" className="invalid fs-12 mb-2">Minimum <b>8 characters</b></p>
                                            <p id="pass-lower" className="invalid fs-12 mb-2">At <b>lowercase</b> letter (a-z)</p>
                                            <p id="pass-upper" className="invalid fs-12 mb-2">At least <b>uppercase</b> letter (A-Z)</p>
                                            <p id="pass-number" className="invalid fs-12 mb-0">A least <b>number</b> (0-9)</p>
                                        </div>

                                        <div className="mt-4">
                                            <Button color="success" className="w-100" type="submit">Reset Password</Button>
                                        </div>

                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                        <div className="mt-4 text-center">
                            <p className="mb-0">Wait, I remember my password... <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Click here </Link> </p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default PasswordReset;