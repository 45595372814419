import React, { useState, useEffect} from "react";
import { Link } from 'react-router-dom';

//redux
import { useSelector, useDispatch } from "react-redux";

//i18n
import { useTranslation } from "react-i18next";

import { 
  Card, 
  CardBody,
  CardHeader,
  Col, 
  Container, 
  Row,
  Input, 
  Label, 
  Table,
  Button,
  Modal, 
  ModalBody, 
  ModalHeader,
  Form,
  Spinner
} from 'reactstrap';

// actions
import { 
    getGroupAdminList,
    resetGaValues
} from "../../../store/general/groupadmin/actions"; 

// common
import Pagination from '../../../Components/Common/Pagination';
import SearchForm from '../../../Components/Common/SearchForm';

// modal
import FailedModal from "../../../Components/Common/FailedModal";
import SuccessModal from "../../../Components/Common/SuccessModal";
import DeleteModal from "../../../Components/Common/DeleteModal";
import EmptyErrorModal from '../../../Components/Common/EmptyErrorModal';
import BreadCrumb from '../../../Components/Common/BreadCrumb';

import GroupList from "./GroupAdminList";

const GroupAdmin = (props) => {
    const dispatch = useDispatch();
    
    const [list, setList] = useState([]);
    const { t, i18n } = useTranslation();

    // selected group name
    const [groupAdminArray, setGroupAdminArray] = useState([]);

    // Delete Subjects
    const [deleteModal, setDeleteModal] = useState(false);
    const [failedModal, setFailedModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [warningModal, setWarningModal] = useState(false);

    const [keywords, setKeywords] = useState('');
    const [queryParams, setQueryParams] = useState({});

    const { groupAdminList, response, error, success } = useSelector((state) => ({
        success: state.GroupAdmin.success,
        groupAdminList: state.GroupAdmin.groupAdminList,
        response: state.GroupAdmin.response,
        error: state.GroupAdmin.error
    }));
    
    // get Subject Data
    useEffect(() => {
        dispatch(resetGaValues());
        dispatch(getGroupAdminList());
    }, [dispatch]);

    useEffect(() => {
        let loading = document.querySelector(".loading");
            loading.style.display = 'block';
        if (groupAdminList.data && groupAdminList.data.length) {
            setList(groupAdminList.data);
            loading.style.display = 'none';
        } else if (groupAdminList.data && groupAdminList.data.length === 0) {
            setList([]);
            loading.style.display = 'none';
        }

    }, [groupAdminList.data]);

    useEffect(() => {
        setTimeout(()=> {  dispatch(getGroupAdminList(queryParams)); }, 500);
    }, [dispatch, queryParams]);

    useEffect(() => {
        if (error && !success) {
            setFailedModal(true);
        }

        if (success && !error && list) {
            setSuccessModal(true);
        }
    }, [dispatch, error, success, list]);

    const checkedAll = () => {
        let   grpArr  = [];
        const checkall = document.getElementById("checkAll");
        const element  = document.querySelectorAll("#groupAdminTable tbody tr");

        if(checkall.checked){
            element.forEach((node) => {
                node.firstChild.firstChild.firstChild.checked = true;
                grpArr.push(node.firstChild.firstChild.firstChild.value);
            });
        }else {
            element.forEach((node) => {
                grpArr = [];
                node.firstChild.firstChild.firstChild.checked = false;
            });
        }

        setGroupAdminArray(grpArr);
    };

    const onClickDeleteGroupAdmin = (group) => {
        setDeleteModal(true);
    };

    const handleSort = el => {
        let data = {
            order_by : el.target.getAttribute("name"),
            order_desc : el.target.getAttribute("type")
        };

        setQueryParams({...queryParams, ...data});

        let sortBtn = document.querySelector('.gridjs-sort');
        sortBtn.classList.add('gridjs-sort-neutral');
        sortBtn.classList.remove('gridjs-sort-desc');
        sortBtn.classList.remove('gridjs-sort-asc');

        setTimeout(() => {
            if (el.target.getAttribute("type") === 'asc') {
                el.target.setAttribute('type', 'desc');
                
                el.target.classList.remove('gridjs-sort-neutral');
                el.target.classList.remove('gridjs-sort-desc');
                el.target.classList.add('gridjs-sort-asc');
    
            } else {
                el.target.setAttribute('type', 'asc');
    
                el.target.classList.remove('gridjs-sort-neutral');
                el.target.classList.remove('gridjs-sort-asc');
                el.target.classList.add('gridjs-sort-desc');
            }
        }, 200);
    };

    const removeGroupAdmin = () => {

        setDeleteModal(false);

        if (groupAdminArray.length === 0) {
            setWarningModal( true ); 
            return false;
        }

        // dispatch(deleteMultipleGroupAdmin(groupAdminArray));
        
        let idsArray   = groupAdminArray.map(Number);
        const grpList = groupAdminList.data;

        let finalArray = grpList.filter(grp => {
            return (!idsArray.includes(grp.GroupId) ? grp : '');
        });

        setTimeout(() => {
            const element  = document.querySelectorAll("#groupAdminTable tbody tr");
            element.forEach((node) => {
                node.firstChild.firstChild.firstChild.checked = false;
            });
        }, 500);

        if (finalArray.length) {
            setList(finalArray);
        }
    };

    const successModalAction = () => {
        setSuccessModal(false);
        dispatch(resetGaValues());
        setTimeout( () => {window.location.reload(true)}, 500);
    };

    document.title="Stison Publishing Solutions | Group Admin";
  
    return (
      <React.Fragment>      
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title={t('Subjects')} pageTitle="List" />

            <SuccessModal
                show={successModal}
                onCloseClick={successModalAction}
                Message={t("Successfully deleted.")}
            />

            <FailedModal
                show={failedModal}
                onCloseClick={() => setFailedModal(false) }
            />

            <EmptyErrorModal
                show={warningModal}
                onCloseClick={() => setWarningModal(false) }
                message={t("No Items selected.")}
            />

            <DeleteModal
                show={deleteModal}
                onDeleteClick={removeGroupAdmin}
                onCloseClick={() => setDeleteModal(false)}
                Messsage={t("Are you sure you want to delete all selected records?")}
            />
            <Row>
              <Col xs={12}>
              <Card>
                  <CardHeader>
                      <div className="d-flex align-items-center">
                          <h4 className="card-title mb-0 flex-grow-1">{t("Group Admin")}</h4>
                      </div>
                  </CardHeader>
                  <CardBody >
                    <div id="groupAdminList">
                        <SearchForm 
                            typeFunction={getGroupAdminList} 
                            setKeywords={setKeywords} 
                            queryParams={queryParams} 
                            setQueryParams={setQueryParams}
                            />
                        <div className="table-responsive table-card mt-3 mb-1">
                            <table className="table align-middle table-nowrap table-striped " id="groupAdminTable">
                                <thead className="table-light">
                                    <tr>
                                        <th scope="col" className="d-table-cell">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" 
                                                onChange={e => {
                                                    checkedAll(e.target.value);
                                                }}
                                                />
                                            </div>
                                        </th>
                                        <th scope="col">{t("Group Name")} <button name="GroupName" type="desc" onClick={handleSort} style={{'cursor': 'pointer'}} className="gridjs-sort gridjs-sort-neutral"></button></th>
                                        <th scope="col">{t("Action")}</th>
                                    </tr>
                                </thead>
                                <tbody className="list form-check-all">
                                    <GroupList list={list} groupAdminArray={groupAdminArray} />
                                </tbody>
                            </table>
                        </div>
                        <div className="loading" style={{ display: "none" }}>
                            <div className="text-center">
                                <Spinner size="sm" />
                            </div>
                        </div>
                    </div>

                    {/* <Pagination 
                            typeFunction={getOrganisation}
                            currentPage={organisationList.current_page}
                            firstPageUrl={organisationList.first_page_url}
                            from={organisationList.from}
                            lastPage={organisationList.last_page}
                            lastPageUrl={organisationList.last_page_url}
                            links={organisationList.links}
                            nextPageUrl={organisationList.next_page_url}
                            path={organisationList.path}
                            perPage={organisationList.per_page}
                            prevPageUrl={organisationList.prev_page_url}
                            to={organisationList.to}
                            total={organisationList.total}
                        /> */}
                    </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
};

export default GroupAdmin;