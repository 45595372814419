import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

import { postLogin, postLogout, getAdminMenusApi } from "../../../helpers/stison_api_helper";

function* loginUser({ payload: { user, history } }) {
  try {

    const query = new URLSearchParams(history.location.search);
    const response = yield call(postLogin, {
      username: user.username,
      password: user.password,
    });

    localStorage.setItem("authUser", JSON.stringify(response.data.user));
    localStorage.setItem("authUserOrganisation", JSON.stringify(response.data.organisation));
    localStorage.setItem("accessToken", JSON.stringify(response.token));

    if (response.status === "success") {
      yield put(loginSuccess(response.data.user));

      // set admin menus
      const adminMenus = yield call(getAdminMenusApi, {});
      if (Object.keys(adminMenus).length) 
        localStorage.setItem("adminMenus", JSON.stringify(adminMenus));

      if (query.get('referrer')) {
        window.location.href = query.get('referrer');
      } else {
        history.push("/dashboard");
      }
    } else {
      yield put(apiError(response));
    }
  
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser() {
  try {

    localStorage.removeItem("authUser");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("authUserOrganisation");
    localStorage.removeItem("adminMenus");

    yield put(logoutUserSuccess(LOGOUT_USER, true));
    
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
