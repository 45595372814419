import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import ParticlesAuth from "../ParticlesAuth";

// Import Images
import error from "../../../assets/images/error.svg";

const Basic404 = () => {
    document.title ="Stison Publishing Solutions | 404 Error";
    return (
        <React.Fragment>
            <div className="auth-page-wrapper">
                <div className="auth-page-content">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <br/>
                                <div className="text-center pt-5">
                                    <div className="">
                                        <img src={error} alt="" className="error-basic-img move-animation" />
                                    </div>
                                    <div className="mt-n4">
                                        <h1 className="display-1 fw-medium">404</h1>
                                        <h3 className="text-uppercase">Sorry, Page not Found 😭</h3>
                                        <p className="text-muted mb-4">The page you are looking for not available!</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Basic404;