import {
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    GET_TASK_PENDING,
    DELETE_TASK,
    GET_TASK_DATA,
    GET_TASKS,
    DELETE_TASK_SUCCESS,
    DELETE_TASK_FAIL,
    ADD_TASK_FAIL,
    ADD_TASK_SUCCESS,
    UPDATE_TASK_SUCCESS,
    UPDATE_TASK_FAIL,
} from "./actionType";
import {ADD_NEW_SUBJECT, ADD_SUBJECT_FAIL, ADD_SUBJECT_SUCCESS, RESET_FLAG_VALUES} from "../titles/subjects/actionType";
import {ADD_NEW_TASK, UPDATE_TASK_DATA} from "../../helpers/url_helper";


export const getTaskPending = (filters) => ({
    type: GET_TASK_PENDING,
    params: filters
});

// common success
export const TaskApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const TaskApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const deleteTask = task => ({
    type: DELETE_TASK,
    payload: task,
});

export const addNewTask = task => ({
    type: ADD_NEW_TASK,
    payload: task,
});

export const addTaskSuccess = task => ({
    type: ADD_TASK_SUCCESS,
    payload: task,
});

export const addTaskFail = error => ({
    type: ADD_TASK_FAIL,
    payload: error,
});


export const updateTask = task => ({
    type: UPDATE_TASK_DATA,
    payload: task,
});

export const updateTaskSuccess = task => ({
    type: UPDATE_TASK_SUCCESS,
    payload: task,
});

export const updateTaskFail = error => ({
    type: UPDATE_TASK_FAIL,
    payload: error,
});



export const deleteTaskSuccess = (actionType, data) => ({
    type: DELETE_TASK_SUCCESS,
    payload: {actionType, data},
});

export const deleteTaskFail = (actionType, error) => ({
    type: DELETE_TASK_FAIL,
    payload: {actionType, error},
});



export const getKanbanTask = filters => ({
    type: GET_TASKS,
    params: filters
});

export const getTaskData = taskData => ({
    type: GET_TASK_DATA,
    payload: taskData
})

// common success
export const KanbanApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const KanbanResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const resetTaskValues = () => ({
    type: RESET_FLAG_VALUES
});

