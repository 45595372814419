// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_GROUP_ADMIN_LIST = "GET_GROUP_ADMIN_LIST";

export const RESET_GA_VALUES = "RESET_GA_VALUES";

export const GET_GROUP_ADMIN_DATA = "GET_GROUP_ADMIN_DATA";

export const UPDATE_GROUP_ADMIN = "UPDATE_GROUP_ADMIN";
export const UPDATE_GROUP_ADMIN_SUCCESS = "UPDATE_GROUP_ADMIN_SUCCESS";
export const UPDATE_GROUP_ADMIN_FAIL = "UPDATE_GROUP_ADMIN_FAIL";