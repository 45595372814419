import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

import { GET_MAIL_USER, UPDATE_MAIL_USER, UPDATE_MAIL_USER_FAIL, UPDATE_MAIL_USER_SUCCESS, RESET_FLAG_NOTIFICATION, GET_REPORTS_VIEW, GET_ADMIN_MENUS, GET_ADMIN_TAB_MENUS, UPDATE_MULTIPLE_MAIL_USER, DELETE_MAIL_USER } from "./actionType";

import {
   CommonApiResponseSuccess,
   CommonApiResponseError,
   updateMailUserSuccess,
   updateMailUserFail
} from "./actions";

import {
    getMailUserApi,
    updateMailUserApi,
    getReportsViewApi,
    getAdminMenusApi,
    getAdminTabMenusApi,
    deleteMailUserApi
} from "../../helpers/stison_api_helper";

function* getMailUser({ params }) {

    let filters = {};

    // map object for filter parameters in API
    if (params) Object.entries(params).map(([k,v]) => filters[k] = v);

    try {
        const response = yield call(getMailUserApi, filters);
        yield put(CommonApiResponseSuccess(GET_MAIL_USER, response));
    } catch (error) {
        yield put(CommonApiResponseError(GET_MAIL_USER, error));
    }
}

function* getReportsView({ params }) {

    let filters = {};

    // map object for filter parameters in API
    if (params) Object.entries(params).map(([k,v]) => filters[k] = v);

    try {
        const response = yield call(getReportsViewApi, filters);
        yield put(CommonApiResponseSuccess(GET_REPORTS_VIEW, response));
    } catch (error) {
        yield put(CommonApiResponseError(GET_REPORTS_VIEW, error));
    }
}

function* getAdminMenus({ params }) {

    let filters = {};

    // map object for filter parameters in API
    if (params) Object.entries(params).map(([k,v]) => filters[k] = v);

    try {
        const response = yield call(getAdminMenusApi, filters);
        yield put(CommonApiResponseSuccess(GET_ADMIN_MENUS, response));
    } catch (error) {
        yield put(CommonApiResponseError(GET_ADMIN_MENUS, error));
    }
}

function* getAdminTabMenus({ params }) {

    let filters = {};

    // map object for filter parameters in API
    if (params) Object.entries(params).map(([k,v]) => filters[k] = v);

    try {
        const response = yield call(getAdminTabMenusApi, filters);
        yield put(CommonApiResponseSuccess(GET_ADMIN_TAB_MENUS, response));
    } catch (error) {
        yield put(CommonApiResponseError(GET_ADMIN_TAB_MENUS, error));
    }
}

// update mail
function* updateMailUser({ payload: mail}) {
    try {
        const response = yield call(updateMailUserApi, mail);
        yield put(updateMailUserSuccess(UPDATE_MAIL_USER_SUCCESS, response));
    } catch (error) {
        yield put(updateMailUserFail(UPDATE_MAIL_USER_FAIL, error));
    }
}

// update subject // update multipple
function* updateMultipleMailUser({ payload: mail}) {
    try {
        const response = yield call(updateMultipleMailUser, mail);
        yield put(updateMailUserSuccess(UPDATE_MAIL_USER_SUCCESS, response));
    } catch (error) {
        yield put(updateMailUserSuccess(UPDATE_MAIL_USER_FAIL, error));
    }
}

// update mail
function* deleteMailUser({ payload: mail}) {
    try {
        const response = yield call(deleteMailUserApi, mail);
        yield put(updateMailUserSuccess(UPDATE_MAIL_USER_SUCCESS, response));
    } catch (error) {
        yield put(updateMailUserFail(UPDATE_MAIL_USER_FAIL, error));
    }
}

export function* watchGetMailUser() {
    yield takeEvery(GET_MAIL_USER, getMailUser);
}

export function* watchUpdateMailUser() {
    yield takeEvery(UPDATE_MAIL_USER, updateMailUser);
}

export function* watchUpdateMultipleMailUser() {
    yield takeEvery(UPDATE_MULTIPLE_MAIL_USER, updateMultipleMailUser);
}

export function* watchDeleteMultipleMailUser() {
    yield takeEvery(DELETE_MAIL_USER, deleteMailUser);
}


export function* watchGetReportsView() {
    yield takeEvery(GET_REPORTS_VIEW, getReportsView);
}

export function* watchGetAdminMenus() {
    yield takeEvery(GET_ADMIN_MENUS, getAdminMenus);
}

export function* watchGetAdminTabMenus() {
    yield takeEvery(GET_ADMIN_TAB_MENUS, getAdminTabMenus);
}


function* CommonSaga() {

    yield all([
        fork(watchGetMailUser),
        fork(watchUpdateMailUser),
        fork(watchUpdateMultipleMailUser),
        fork(watchDeleteMultipleMailUser),
        fork(watchGetReportsView),
        fork(watchGetAdminMenus),
        fork(watchGetAdminTabMenus)
    ]);
}
  
export default CommonSaga;