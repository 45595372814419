import React, { useEffect, useState, useRef, createRef } from "react";
import { 
    Row, 
    Col, 
    CardBody, 
    Card,
    CardHeader,
    Alert, 
    Container, 
    Input, 
    Label, 
    Form,
} from "reactstrap";

import BreadCrumb from '../../../../Components/Common/BreadCrumb';

import {
    getContentSubjects,
    updateContentSubjects,
    updateContentSubjectsApi,
    resetFlagValues,
    deleteContentSubject
} from "../../../../store/titles/subjects/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";

import "quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";

import { v4 as uuidv4 } from "uuid";

import FailedModal from "../../../../Components/Common/FailedModal";
import SuccessModal from "../../../../Components/Common/SuccessModal";
import ContentModal from "../../../../Components/Common/Titles/ContentModal";
import DeleteModal from "../../../../Components/Common/DeleteModal";

import AutoCompleteInput from "../../../../Components/Common/AutoCompleteInput";

import SubjectEdit from "../SubjectEdit";

const SubjectsBooks = (props) => {

    const dispatch  = useDispatch();
    const { t, i18n } = useTranslation();
    const { history } = props;
    const { id } = useParams();
    const { contentSubjects, response, success, error, isLoading, isSubjectUpdate, isContentSubjectsError } = useSelector((state) => state.Subjects);

    const [queryParams, setQueryParams] = useState({subject_id: id});
    const [list, setList] = useState([]);
    const [rowIndex, setRowIndex] = useState();
    const [deleteIndex, setDeleteIndex] = useState();
    
     // Delete Subjects
    const [deleteModal, setDeleteModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [failedModal, setFailedModal] = useState(false);

    // Content Modal
    const [contentModal, setContentModal] = useState(false);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);

    const [formFields, setFormFields] = useState([]);
    const [typeModal, setTypeModal]   = useState(false);

    const typeaheadRef = useRef(null);

    useEffect(() => {
        dispatch(getContentSubjects(queryParams));
    }, [dispatch, queryParams]);

    useEffect(() => {
        if (contentSubjects && contentSubjects.length > 0) {
            const fields = contentSubjects.map((r) => ({
                content_subject_id: r.ContentSubjectId,
                content_id: r.content.ContentId,
                title: `${r.content.TitleNameFull || ''}${r.content.SubTitle ? ' - ' + r.content.SubTitle : ''}`,
                books: r.content.books || [],
            }));

            const data = contentSubjects.map((r) => r.content);
    
            setLoading(false);
            setFormFields(fields);
            setList(data);
            setShow(true);

        } else if (!isLoading) {
            setLoading(false);
            setShow(true);
            setFormFields([
                {
                    content_id: '',
                    title: '',
                    content_subject_id: 0,
                },
            ]);
        }

    }, [contentSubjects, id, isLoading]);

    useEffect(() => {

       
        if (success && !error) {
            setSuccessModal(true)
        }

        if (isSubjectUpdate) {
            dispatch(getContentSubjects(queryParams))
        }

        if (error && isContentSubjectsError) {
            setFailedModal(true);
        }

    }, [dispatch, error, success, isSubjectUpdate, isContentSubjectsError, queryParams]);

    // temporary function
    const formDescription = (text) => {
        switch (text) {
            case 'Electronic book text' :
                    return 'EPUB';
            default : 
                return text;
        }
    };

    const handleAddFields = () => {
        let fields = {content_id: '', title: '', content_subject_id : uuidv4()};
        const newFormFields = [...formFields, fields];
        setFormFields(newFormFields);
    }

    // auto delete
    const removeFields = () => {
        const newFields = [...formFields];
        setDeleteModal( false );

        if (Object.keys(newFields[deleteIndex]).length) {
            const fetchData = async () => {
                dispatch(deleteContentSubject({ ContentSubjectId : newFields[deleteIndex].content_subject_id }))
                await dispatch(resetFlagValues())
            };
        
            fetchData();

            setSuccessModal( true );
        }
    }

    const handSubmit = (e) => {
        e.preventDefault();
    
        const data = formFields.map((item) => ({
            content_id: item.content_id,
            subject_id: parseInt(id),
            content_subject_id: item.content_subject_id || 0,
        }));
    
        if (data.length > 0) {
            const fetchData = async () => {
                dispatch(updateContentSubjects(data));
                await dispatch(resetFlagValues())
            };
        
            fetchData();
        }

        // setSuccessModal( true );
    };

    const handleSelectContent = (item, index) => {
        let updatedFormFields = [...formFields];
        updatedFormFields[index] = {
            content_id : item.ContentId,
            title : item.Title,
            content_subject_id : '',
            books : []
        };

        setFormFields( [] );
        setTimeout( () => setFormFields(updatedFormFields), 50);
        setContentModal(false);

        // set type ahead text
        //https://stackoverflow.com/questions/55995760/how-to-add-refs-dynamically-with-react-hooks
        typeaheadRef.current.state.text = item.Title;
    };

    const handleShowContentModal = (index) => {
        setRowIndex(index);
        setContentModal(true);
    }

    const successModalAction = () => {
        // reset all after success
        setSuccessModal( false );
        dispatch(resetFlagValues())
    };

    const failedModalAction = () => {
        // reset all after success
        setFailedModal( false );
        dispatch(resetFlagValues())
    };

    const onClickDelete = (index) => {
        setDeleteIndex(index);
        setDeleteModal(true);
    };

    return (
        <React.Fragment>
            <SubjectEdit>
                <ContentModal
                    show={contentModal}
                    onSelectClick={handleSelectContent}
                    onCloseClick={() => setContentModal(false)}
                    rowIndex={rowIndex}
                />

                <SuccessModal
                    show={successModal}
                    onCloseClick={successModalAction}
                    Message={t('Successfully updated subject.')}
                />

                <DeleteModal
                        show={deleteModal}
                        onDeleteClick={removeFields}
                        onCloseClick={() => setDeleteModal(false)}
                        Messsage={t('Are you sure you wish to remove this row?')}
                    />

                <FailedModal
                        show={failedModal}
                        onCloseClick={failedModalAction}
                        failedMesssage={t('Duplicate content subjects entry.')}
                    />

                <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    return false;
                }} 
                className="needs-validation" action="#">
                    <Card>
                        <CardHeader>
                            <h5 className="card-title mb-0">{t('Content Subjects')}</h5>
                        </CardHeader>
                        <CardBody>
                            <div>
                                <Row className="align-items-start mt-2">
                                    <Col xxl={1} md={1} >
                                        <div className="mb-3 d-flex">
                                            {/* <div className="d-block flex-shrink-0" style={{'opacity': '0'}}>
                                               <h6>{t('Delete')} </h6>
                                            </div> */}
                                            <div className="d-block flex-shrink-0">
                                                <h6>{t('Book Name')}</h6>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div style={{ display: show ? "block" : "none" }}>
                                    {formFields.map((form, index) => {
                                        return (<div key={form.content_subject_id}>
                                            <Row className="align-items-start mt-2" >
                                                <Col xxl={7} md={7} >
                                                    <div className="mb-3 d-flex" style={{'position':'relative'}}>
                                                        <div className="d-block flex-shrink-0">
                                                            <button className="me-3 btn btn-sm btn-soft-danger align-bottom" onClick={() => onClickDelete(index)}>
                                                                <i className="ri-delete-bin-5-fill fs-16"></i>
                                                            </button>
                                                        </div>
                                                        <Input 
                                                            type="hidden" 
                                                            name="content_id"
                                                            id="content_id"
                                                            // defaultValue={form.content_id}
                                                            value={form.content_id || ''}
                                                                />
                                                        <Input 
                                                            type="hidden" 
                                                            name="content_subject_id"
                                                            id="content_subject_id"
                                                            // defaultValue={form.content_subject_id}
                                                            value={form.content_subject_id || ''}
                                                            />

                                                        {/* //auto complete */}
                                                        <AutoCompleteInput 
                                                            customInputName="title"
                                                            customInputClass="me-3 form-control"  
                                                            customInputId="title"
                                                            placeholder="Content Title"
                                                            customInputValue={form.title || ''}
                                                            customInputIndex={index}
                                                            customFormFields={formFields}
                                                            setFormFields={setFormFields}
                                                            typeModal={typeModal}
                                                            setTypeModal={setTypeModal}
                                                            customId={id}
                                                            typeaheadRef={typeaheadRef}
                                                        />

                                                        <div className="d-block flex-shrink-0">
                                                            <button className=" btn btn-sm btn-soft-info align-bottom" onClick={() => handleShowContentModal(index)}>
                                                                <i className=" ri-file-search-line fs-16"></i>
                                                            </button>
                                                            <Link className="btn btn-sm btn-soft-success " to={`/subjects`} style={{'marginLeft': '10px'}}>
                                                                <i className="  ri-edit-box-line fs-16"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col  xxl={5} md={5}>
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1">
                                                            <div className="hstack flex-wrap mt-1" style={{'gap': '0.2rem'}}>
                                                                {form.books && form.books.map((book, i) => {
                                                                    return (<div key={i}>
                                                                        <div className="text-muted ">
                                                                            {book.ISBN13}:{" "}
                                                                            <span className="text-body fw-medium">
                                                                                {book.ProductFormDesc ? formDescription(book.ProductFormDesc) : book.EpubTypeName}
                                                                            </span>
                                                                            {" "}
                                                                            <div className="vr align-middle"></div>
                                                                        </div>
                                                                    </div>)
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>)
                                    })}
                                </div>
                                <div className="loading" style={{ display: loading ? "block" : "none" }}>
                                    <div className="text-left">
                                        <h5 className="card-title placeholder-glow">
                                            <span className="placeholder col-6"></span>
                                        </h5>
                                        <p className="card-text placeholder-glow">
                                            <span className="placeholder col-7"></span>
                                            <span className="placeholder col-4"></span>
                                            <span className="placeholder col-4"></span>
                                            <span className="placeholder col-6"></span>
                                        </p>
                                    </div>
                                </div>
                                <button 
                                    type="button" 
                                    className="btn btn-soft-info btn-sm mt-4 add-btn"
                                    onClick={handleAddFields}
                                    >
                                    <i className="ri-file-add-line me-1 align-bottom"></i> {t('Add another book')}
                                </button>
                            </div>
                            <br/>
                           
                        </CardBody>
                        
                        <div className="mb-3 text-end">
                            <button 
                                type="submit" 
                                className="btn btn-info w-sm mr-2" 
                                style={{ 'marginRight' : '20px', 'color': '#ffffff'}}
                                onClick={handSubmit}
                                >
                                {t('Update')}
                            </button>
                        </div>
                    </Card>
                </Form>
            </SubjectEdit>
        </React.Fragment>
    );
};

export default SubjectsBooks;
