import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { APIClient } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";

import {
    GET_TASK_PENDING,
    GET_TASKS,
    GET_TASK_DATA,
    DELETE_TASK_SUCCESS,
    DELETE_TASK_FAIL,
    DELETE_TASK,
    UPDATE_TASK
} from "./actionType";

import {
    TaskApiResponseSuccess,
    TaskApiResponseError,
    KanbanApiResponseSuccess,
    KanbanResponseError,
    deleteTaskSuccess,
    deleteTaskFail,
    addTaskFail,
    addTaskSuccess,
    updateTaskSuccess, updateTaskFail
} from "./actions";

import {
    deleteSubjectData, deleteTaskData,
    getKanbanListAPI,
    getTaskPendingAPI,
    getTaskDataApi, addNewSubjectData, addNewTaskData, updateTaskData
} from "../../helpers/stison_api_helper";
import {addSubjectFail, addSubjectSuccess} from "../titles/subjects/actions";
import {ADD_NEW_SUBJECT} from "../titles/subjects/actionType";
import {ADD_NEW_TASK, UPDATE_TASK_DATA} from "../../helpers/url_helper";
import {watchAddSubject} from "../titles/subjects/saga";

// get task list
function* getTaskPendingList({ params }) {

    let filters = {};
    const authOrg = JSON.parse(localStorage.getItem("authUserOrganisation"));
    const organisationId = (authOrg !== null) ? authOrg.OrganisationId : null;

    filters["organisation"] = organisationId;

    // map object for filter parameters in API
    if (params) Object.entries(params).map(([k,v]) => filters[k] = v);

    try {
        const response = yield call(getTaskPendingAPI, filters);
        yield put(TaskApiResponseSuccess(GET_TASK_PENDING, response));
    } catch (error) {
        yield put(TaskApiResponseError(GET_TASK_PENDING, error));
    }
}

function* getTaskData({payload: task})
{
    try {
        const response = yield call(getTaskDataApi, task);
        yield put(KanbanApiResponseSuccess(GET_TASK_DATA, response));
    } catch (error) {
        yield put(KanbanApiResponseSuccess(GET_TASK_DATA, error));
    }
}
function* onDeleteTask({ payload: task })
{
        try {
            const response = yield call(deleteTaskData, task);
            yield put(deleteTaskSuccess(DELETE_TASK_SUCCESS, response));
        } catch (error) {
            yield put(deleteTaskFail(DELETE_TASK_FAIL, error));
        }
}

function* getKanbanList({ params }) {
    try {
        const response = yield call(getKanbanListAPI,params);
        yield put(KanbanApiResponseSuccess(GET_TASKS, response));
    } catch (error) {
        yield put(KanbanResponseError(GET_TASKS, error));
    }
}

// create
function* onAddTask({ payload: task }) {
    try {
        const response = yield call(addNewTaskData, task);

        yield put(addTaskSuccess(response));
    } catch (error) {
        yield put(addTaskFail(error));
    }
}


// update
function* onUpdateTask({ payload: task }) {
    try {
        const response = yield call(updateTaskData, task);
        yield put(updateTaskSuccess(response));
    } catch (error) {
        yield put(updateTaskFail(error));
    }
}


export function* watchGetKanbanList() {
    yield takeEvery(GET_TASKS, getKanbanList);
}

export function* watchGetTaskPendingList() {
    yield takeEvery(GET_TASK_PENDING, getTaskPendingList);
}

export function* watchDeleteTask() {
    yield takeEvery(DELETE_TASK, onDeleteTask);
}

export function* watchTaskData() {
    yield takeEvery(GET_TASK_DATA, getTaskData);
}


export function* watchAddTask() {
    yield takeEvery(ADD_NEW_TASK, onAddTask);
}
export function* watchUpdateTask() {
    yield takeEvery(UPDATE_TASK_DATA, onUpdateTask);
}


function* TaskSaga() {

    yield all([
        fork(watchGetTaskPendingList),
        fork(watchGetKanbanList),
        fork(watchDeleteTask),
        fork(watchTaskData),
        fork(watchAddTask),
        fork(watchUpdateTask),
    ]);
}

export default TaskSaga;