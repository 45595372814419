import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";

//redux
import { useSelector, useDispatch } from "react-redux";
import { 
    Col, 
    Row 
} from 'reactstrap';

//i18n
import { useTranslation } from "react-i18next";

const SearchForm = (props) => {
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const {
        typeFunction,
        setKeywords,
        queryParams,
        setQueryParams,
        moduleName
    } = props;

    const search = () => {
        const searchInput = document.getElementById('keywords');
        const searchValue = searchInput.value.trim(); // Trim the input value
    
        if (!searchValue) {
            return; // Do nothing if the input is empty
        }
    
        const keys = { keywords: searchValue };
    
        setQueryParams((prevParams) => ({ ...prevParams, ...keys }));
        dispatch(typeFunction(keys));
        setKeywords(searchValue);
    };
    
    const clear = () => {
        const searchInput = document.getElementById('keywords');
        searchInput.value = '';
    
        setTimeout(() => {
            dispatch(typeFunction());
            setQueryParams({});
            setKeywords('');
        }, 100);
    };
    
    return (
        <React.Fragment>
            <Row className="g-4 mb-3 ">
                <form>
                    <div className="row g-3">
                        <div className="col-xxl-5 col-sm-12">
                            <div className="search-box">
                                    <input type="text"
                                        id="keywords"
                                        name="keywords"
                                        className="form-control search bg-light border-light"
                                        placeholder={t("Search for subjects")}
                                        onInput={event => {
                                            if (event.key === 'Enter') {
                                                event.preventDefault();
                                                search();
                                            }
                                        }}
                                     />
                                <i className="ri-search-line search-icon"></i>
                            </div>
                        </div> 

                        <div className="col-xxl-3 col-sm-4 ">
                            <button 
                                    type="button" 
                                    className="btn btn-primary " 
                                    style={{ 'marginRight' : '10px'}} 
                                    onClick={search}
                                >
                                <i className="ri-equalizer-fill me-1 align-bottom"></i>
                                {t("Search")}
                            </button>

                            <button 
                                    type="button" 
                                    className="btn btn-soft-primary" 
                                    onClick={clear}
                                >
                                <i className="ri-eraser-line me-1 align-bottom"> </i>
                                {t("Clear")}
                            </button>
                        </div>
                    </div>
                </form>
            </Row>
        </React.Fragment>
    );
};


export default SearchForm;
