import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
    GET_GROUP_ADMIN_LIST,
    GET_GROUP_ADMIN_DATA,
    UPDATE_GROUP_ADMIN_SUCCESS,
    UPDATE_GROUP_ADMIN_FAIL,
    UPDATE_GROUP_ADMIN,
} from "./actionType";

import {
    GroupAdminApiResponseSuccess, 
    GroupAdminApiResponseError,
    updateGroupAdminSuccess,
    updateGroupAdminFail, 
} from "./actions";

import { 
    getGroupAdminListApi,
    getGroupAdminDataApi,
    updateGroupAdminDataApi
} from "../../../helpers/stison_api_helper";

// get group admin list
function* getGroupAdminList({ params }) {
    let filters = {};

    // map object for filter parameters in API
    if (params) Object.entries(params).map(([k,v]) => filters[k] = v);

    try {
        const response = yield call(getGroupAdminListApi, filters);
        yield put(GroupAdminApiResponseSuccess(GET_GROUP_ADMIN_LIST, response));
    } catch (error) {
        yield put(GroupAdminApiResponseError(GET_GROUP_ADMIN_LIST, error));
    }
}

// get single group admin 
function* getGroupAdminData({ payload: group }) {
    try {
        const response = yield call(getGroupAdminDataApi, group);
        yield put(GroupAdminApiResponseSuccess(GET_GROUP_ADMIN_DATA, response));
    } catch (error) {
        yield put(GroupAdminApiResponseSuccess(GET_GROUP_ADMIN_DATA, error));
    }
}

// update group admin
function* onUpdateGroupAdmin({ payload: group }) {
    try {
        const response = yield call(updateGroupAdminDataApi, group);
        yield put(updateGroupAdminSuccess(UPDATE_GROUP_ADMIN_SUCCESS, response));
    } catch (error) {
        yield put(updateGroupAdminFail(UPDATE_GROUP_ADMIN_FAIL, error));
    }
}

export function* watchGetGroupAdminList() {
    yield takeEvery(GET_GROUP_ADMIN_LIST, getGroupAdminList);
}

export function* watchGetGroupAdminData() {
    yield takeEvery(GET_GROUP_ADMIN_DATA, getGroupAdminData);
}

export function* watchUpdateGroupAdmin() {
    yield takeEvery(UPDATE_GROUP_ADMIN, onUpdateGroupAdmin);
}

function* GroupAdminSaga() {

    yield all([
        fork(watchGetGroupAdminList),
        fork(watchGetGroupAdminData),
        fork(watchUpdateGroupAdmin),
    ]);
}
  
export default GroupAdminSaga;