import flagus from "../assets/images/flags/gb.svg";
import flagspain from "../assets/images/flags/es.svg";
import flaggermany from "../assets/images/flags/de.svg";
import flagitaly from "../assets/images/flags/it.svg";
import flagfrench from "../assets/images/flags/fr.svg";

const languages = {
  sp: {
    label: "Española",
    flag: flagspain,
  },
  gr: {
    label: "Deutsche",
    flag: flaggermany,
  },
  it: {
    label: "Italiana",
    flag: flagitaly,
  },
  en: {
    label: "English",
    flag: flagus,
  },
  fr: {
    label: "français",
    flag: flagfrench,
  },
}

export default languages
