import React, { useEffect, useState, useMemo } from 'react';
import { 
    Card, 
    CardBody, 
    Col, 
    Nav, 
    NavItem, 
    NavLink,
    Row, 
    TabContent, 
    TabPane,
    Container
} from 'reactstrap';
import classnames from 'classnames';
import { useHistory, useParams, Link } from 'react-router-dom';
//redux
import { useSelector, useDispatch } from "react-redux";

import { useTranslation } from 'react-i18next';

import { getAdminTabMenus } from '../../../store/actions';

import {
    getSubjectData
} from "../../../store/titles/subjects/actions";

import BreadCrumb from '../../../Components/Common/BreadCrumb';

const SubjectEdit = (props) => {
    
    let tabCounter = 0;
    const dispatch  = useDispatch();
    const { id } = useParams();
    const history = useHistory();

    const { t, i18n } = useTranslation();
    
    const [tabMenus, setTabMenus] = useState([]);
    const [title, setTitle] = useState('');

    //Tab 
    const [activeTab, setActiveTab] = useState(0);

    const { subjectData, adminTabMenus } = useSelector((state) => ({
        subjectData: state.Subjects.subjectData,
        adminTabMenus: state.Common.adminTabMenus
    }));

    const query = useMemo( () => {
        return new URLSearchParams(history.location.search);
    },[history]);

    useEffect(() => {
        dispatch(getAdminTabMenus({'tab_name' : 'Subject'}));
    }, [dispatch]);

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    useEffect(() => {
        if (typeof subjectData !== 'undefined' && subjectData.SubjectName) setTitle(subjectData.SubjectName);
    }, [subjectData]);

    useEffect(() => {
        if (typeof adminTabMenus !== 'undefined' && adminTabMenus) setTabMenus(adminTabMenus);
    }, [adminTabMenus]);

    useEffect(() => {
        setTimeout(() => {
            if (query.get('active') !== null)  {
                setActiveTab(parseInt(query.get('active')));
            } else {
                setActiveTab(0);
            }
        }, 100);
       
    }, [query]);

    document.title=t(`Stison Publishing Solutions | Edit Subject`);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t(`Edit Subject`)} pageTitle="Detail" />
                    <Row>
                        <Col lg={12}>
                            <Card className="mt-n4 mx-n4">
                                <div className="bg-soft-warning">
                                    <CardBody className="pb-0 px-4">
                                        <Row className="mb-3">
                                            <div className="col-md">
                                                <Row className="align-items-center g-3">
                                                    <div className="col-md mt-4 ">
                                                        <div>
                                                            <h4 className="fw-bold">{ title ? t('Editing') + ' - ' + t(title) : t('Create Subject') }</h4>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                            <div className="col-md-auto">
                                                <div className="hstack gap-1 flex-wrap">
                                                    <button type="button" className="btn py-0 fs-20 text-body" style={{'padding': '0.2rem 0.5rem'}}>
                                                        <i className="ri-dashboard-2-line"></i>
                                                    </button>
                                                    <button type="button" className="btn py-0 fs-20" style={{'padding': '0.2rem 0.5rem'}}>
                                                        <i className=" ri-image-add-fill"></i>
                                                    </button>
                                                    
                                                    <button type="button" className="btn py-0 fs-20 text-body" style={{'padding': '0.2rem 0.5rem'}}>
                                                        <i className="ri-download-cloud-fill"></i>
                                                    </button>

                                                    <button type="button" className="btn py-0 fs-20 text-body" style={{'padding': '0.2rem 0.5rem'}}>
                                                        
                                                        <Link  to="/subjects/create"><i className=" ri-file-add-line"></i> </Link>
                                                    </button>
                                                </div>
                                            </div>
                                        </Row>

                                        <Nav className="nav-tabs-custom border-bottom-0" role="tablist">

                                            {tabMenus && ((tabMenus || []).map((tabs) => {
                                                const currentCounter = tabCounter++; // Increment the counter and assign it to currentCounter
                                                return (
                                                    <NavItem key={currentCounter}>
                                                        <Link 
                                                            className={classnames({ active: activeTab === currentCounter }, "nav-link fw-semibold")} 
                                                            to={`/subjects/${tabs.AdminTabName.toLowerCase()}/${id}?active=${currentCounter}`}
                                                        >
                                                            {`${t(tabs.AdminTabName)}`} {/* Add the counter */}
                                                        </Link>
                                                    </NavItem>
                                                );
                                            }))}
                                            
                                        </Nav>
                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            {props.children}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default SubjectEdit;
