import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";

import SubjectsSaga from "./titles/subjects/saga";
import OrganisationSaga from "./general/organisation/saga";
import TaskSaga from "./task/saga";

import ContentSaga from "./titles/content/saga"; 
import CommonSaga from "./common/saga";
import GroupAdminSaga from "./general/groupadmin/saga";

import ThemesSaga from "./titles/themes/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(SubjectsSaga),
    fork(OrganisationSaga),
    fork(TaskSaga),
    fork(ContentSaga),
    fork(CommonSaga),
    fork(GroupAdminSaga),
    fork(ThemesSaga),
  ]);
}
