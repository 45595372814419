import {
    GET_THEMES_LIST,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    RESET_THEMES_VALUES,
    GET_THEME_DATA,
    GET_PARENT_THEMES,
    THEME_SUCCESS,
    THEME_FAIL,
} from "./actionType";

const INIT_STATE = {
    themesList: [],
    themeData: [],
    parentData: [],
    contenThemes: [],
    seoThemes: [],
    error: "",
    response: "",
    success: false,
    isLoading: true,
    isThemeUpdate: false,
    contentBooksSearch: []
};

const Themes = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_THEMES_LIST:
                    return {
                        ...state,
                        themesList: action.payload.data,
                    };

                case GET_THEME_DATA: 
                    return {
                        ...state,
                        themeData: action.payload.data,
                    };

                case GET_PARENT_THEMES: 
                    return {
                        ...state,
                        parentData: action.payload.data,
                    };
                    
                case THEME_SUCCESS:
                    return {
                        ...state,
                        response: action.payload.data,
                    };
        
                default:
                    return { ...state };
            }

        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_THEMES_LIST:
                    return {
                        ...state,
                        error: action.payload.error,
                    };

                case GET_THEME_DATA: 
                    return {
                        ...state,
                        error: action.payload.error,
                    };

                case GET_PARENT_THEMES: 
                    return {
                        ...state,
                        error: action.payload.error,
                    };

                case THEME_FAIL:
                    return {
                        ...state,
                        error: action.payload.data,
                    };
                
                default:
                    return { ...state };
            }
        
        case THEME_SUCCESS:
            return {
                ...state,
                themeData: action.payload.data,
                success: true,
                isThemeUpdate: true
            };

        case THEME_FAIL:
            return {
                ...state,
                error: action.payload,
                success: false
            };

        case RESET_THEMES_VALUES :
            return {
                ...state,
                themesList: [],
                themeData: [],
                parentData: [],
                contenThemes: [],
                seoThemes: [],
                error: "",
                response: "",
                success: false,
                isLoading: true,
                isThemeUpdate: false,
                contentBooksSearch: []
            };
        default:
            return { ...state };
    }
};

export default Themes;