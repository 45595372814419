import {
    GET_GROUP_ADMIN_LIST,
    GET_GROUP_ADMIN_DATA,
    UPDATE_GROUP_ADMIN,
    UPDATE_GROUP_ADMIN_SUCCESS,
    UPDATE_GROUP_ADMIN_FAIL,
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    RESET_GA_VALUES
} from "./actionType";

export const getGroupAdminList = filters => ({
    type: GET_GROUP_ADMIN_LIST,
    params: filters
});

export const getGroupAdminData = group => ({
    type: GET_GROUP_ADMIN_DATA,
    payload: group
});

// common success
export const GroupAdminApiResponseSuccess = (actionType, data) => ({
    type: API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

// common error
export const GroupAdminApiResponseError = (actionType, error) => ({
    type: API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const updateGroupAdmin = group => ({
    type: UPDATE_GROUP_ADMIN,
    payload: group,
});

export const updateGroupAdminSuccess = group => ({
    type: UPDATE_GROUP_ADMIN_SUCCESS,
    payload: group,
});

export const updateGroupAdminFail = error => ({
    type: UPDATE_GROUP_ADMIN_FAIL,
    payload: error,
});

export const resetGaValues = () => ({
    type: RESET_GA_VALUES
});
