import { call, put, takeEvery, all, fork } from "redux-saga/effects";

// Crypto Redux States
import {
    GET_CONTENT_LIST,
} from "./actionType";

import {
    ApiResponseSuccess, ApiResponseError, ContentResponseSuccess, ContentResponseError,
} from "./actions";

import { 
    getBooksApi, 
} from "../../../helpers/stison_api_helper";

// get subject list
function* getContentList({ params }) {
    let filters = {};

    // map object for filter parameters in API
    if (params) Object.entries(params).map(([k,v]) => filters[k] = v);

    try {
        const response = yield call(getBooksApi, filters);
        yield put(ContentResponseSuccess(GET_CONTENT_LIST, response));
    } catch (error) {
        yield put(ContentResponseError(GET_CONTENT_LIST, error));
    }
}

export function* watchGetContentList() {
    yield takeEvery(GET_CONTENT_LIST, getContentList);
}

function* ContentSaga() {

    yield all([
        fork(watchGetContentList),
    ]);
}
  
export default ContentSaga;