import React, { useState, useEffect } from 'react';

//redux
import { useSelector, useDispatch } from "react-redux";
import { 
    Card, 
    CardBody, 
    CardHeader, 
    Col, 
    Container, 
    Row,
    Button,
    Alert,
    Spinner
} from 'reactstrap';
import { Link } from 'react-router-dom';
//i18n
import { useTranslation } from "react-i18next";

// actions
import { 
    getSubjectList,
    deleteMultipleSubjects,
    resetFlagValues
 } from "../../../store/titles/subjects/actions";

// common
import Pagination from '../../../Components/Common/Pagination';
import SearchForm from '../../../Components/Common/SearchForm';

// modal
import FailedModal from "../../../Components/Common/FailedModal";
import SuccessModal from "../../../Components/Common/SuccessModal";
import DeleteModal from "../../../Components/Common/DeleteModal";
import EmptyErrorModal from '../../../Components/Common/EmptyErrorModal';
import BreadCrumb from '../../../Components/Common/BreadCrumb';

// component imports
import SubjectList from './SubjectList';

const Subjects = (props) => {
    const dispatch = useDispatch();

    const [list, setList] = useState([]);
    const { t, i18n } = useTranslation();

    // selected subjects
    const [subjectsArray, setSubjectsArray] = useState([]);

    // Delete Subjects
    const [deleteModal, setDeleteModal] = useState(false);
    const [failedModal, setFailedModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [warningModal, setWarningModal] = useState(false);

    const [keywords, setKeywords] = useState('');
    const [queryParams, setQueryParams] = useState({});

    const { subjectList, response, success, error } = useSelector((state) => state.Subjects);
    
    // get Subject Data
    useEffect(() => {
        const fetchData = async () => {
            dispatch(resetFlagValues());
            await dispatch(getSubjectList(queryParams));
            const loading = document.querySelector(".loading");
            loading.style.display = 'none';
        };
    
        fetchData();
    
    }, [dispatch, queryParams]);

    useEffect(() => {
        let loading = document.querySelector(".loading");
        loading.style.display = 'block';
        
        if (subjectList.data && subjectList.data.length) {
            setList(subjectList.data);
            loading.style.display = 'none';
        } else if (subjectList.data && subjectList.data.length === 0) {
            setList([]);
            loading.style.display = 'none';
        }

    }, [subjectList.data]);

    useEffect(() => {
        if (error && !success) {
            setFailedModal(true);
        }

        if (success && !error && list) {
            setSuccessModal(true);
        }
    }, [dispatch, error, success, list]);

     // Checked All Subjects
    const checkedAll = () => {
        let   subjArr  = [];
        const checkall = document.getElementById("checkAll");
        const element  = document.querySelectorAll("#subjectsTable tbody tr");

        if(checkall.checked){
            element.forEach((node) => {
                node.firstChild.firstChild.firstChild.checked = true;
                subjArr.push(node.firstChild.firstChild.firstChild.value);
            });
        }else {
            element.forEach((node) => {
                subjArr = [];
                node.firstChild.firstChild.firstChild.checked = false;
            });
        }

        setSubjectsArray(subjArr);
    };

    const onClickDeleteSubjects = (subject) => {
        setDeleteModal(true);
    };

    const handleSort = el => {
        let data = {
            order_by : el.target.getAttribute("name"),
            order_desc : el.target.getAttribute("type")
        };

        setQueryParams({...queryParams, ...data});
        // dispatch(getSubjectList(queryParams));

        let sortBtn = document.querySelector('.gridjs-sort');
        sortBtn.classList.add('gridjs-sort-neutral');
        sortBtn.classList.remove('gridjs-sort-desc');
        sortBtn.classList.remove('gridjs-sort-asc');

        setTimeout(() => {
            if (el.target.getAttribute("type") === 'asc') {
                el.target.setAttribute('type', 'desc');
                
                el.target.classList.remove('gridjs-sort-neutral');
                el.target.classList.remove('gridjs-sort-desc');
                el.target.classList.add('gridjs-sort-asc');
    
            } else {
                el.target.setAttribute('type', 'asc');
    
                el.target.classList.remove('gridjs-sort-neutral');
                el.target.classList.remove('gridjs-sort-asc');
                el.target.classList.add('gridjs-sort-desc');
            }
        }, 200);
    };

    const removeSubjects = () => {

        setDeleteModal(false);

        if (subjectsArray.length === 0) {
            setWarningModal( true ); 
            return false;
        }

        dispatch(deleteMultipleSubjects(subjectsArray));
        
        let idsArray   = subjectsArray.map(Number);
        const subjList = subjectList.data;

        let finalArray = subjList.filter(subj => {
            return (!idsArray.includes(subj.SubjectId) ? subj : '');
        });

        setTimeout(() => {
            const element  = document.querySelectorAll("#subjectsTable tbody tr");
            element.forEach((node) => {
                node.firstChild.firstChild.firstChild.checked = false;
            });
        }, 500);

        if (finalArray.length) {
            setList(finalArray);
        }
    };

    const successModalAction = () => {
        setSuccessModal(false);
        dispatch(resetFlagValues());
        setTimeout( () => {window.location.reload(true)}, 500);
    };
    
    document.title="Stison Publishing Solutions | Subjects";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t('Subjects')} pageTitle="List" />

                    <SuccessModal
                        show={successModal}
                        onCloseClick={successModalAction}
                        Message={t("Successfully deleted.")}
                    />

                    <FailedModal
                        show={failedModal}
                        onCloseClick={() => setFailedModal(false) }
                    />

                    <EmptyErrorModal
                        show={warningModal}
                        onCloseClick={() => setWarningModal(false) }
                        message={t("No Items selected.")}
                    />

                    <DeleteModal
                        show={deleteModal}
                        onDeleteClick={removeSubjects}
                        onCloseClick={() => setDeleteModal(false)}
                        Messsage={t("Are you sure you want to delete all selected records?")}
                    />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <div className="d-flex align-items-center">
                                        <h4 className="card-title mb-0 flex-grow-1" style={{'textTransform' : 'none'}}>{t("Subjects")}</h4>
                                        <div className="flex-shrink-0">
                                            <Link className="btn btn-md btn-primary add-btn me-1" id="create-btn" to="/subjects/create"><i className="ri-add-line align-bottom me-1"></i> {t("Create")} </Link>
                                            <Button className="btn btn-soft-danger"
                                                    onClick={onClickDeleteSubjects}
                                            ><i className="ri-delete-bin-2-line"></i></Button>
                                        </div>
                                    </div>
                                </CardHeader>

                                <CardBody>
                                    <div id="subjectList">
                                        <SearchForm 
                                            typeFunction={getSubjectList} 
                                            setKeywords={setKeywords} 
                                            queryParams={queryParams} 
                                            setQueryParams={setQueryParams}
                                            />

                                        <div className="table-responsive table-card mt-3 mb-1">
                                            <table className="table align-middle table-nowrap table-striped " id="subjectsTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" className="d-table-cell">
                                                            <div className="form-check">
                                                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" 
                                                                onChange={e => {
                                                                    checkedAll(e.target.value);
                                                                }}
                                                                />
                                                            </div>
                                                        </th>
                                                        <th scope="col">{t("Subject")} <button name="SubjectName" type="desc" onClick={handleSort} style={{'cursor': 'pointer'}} className="gridjs-sort gridjs-sort-neutral"></button></th>
                                                        <th scope="col">{t("Code")} <button  name="SubjectCode" type="desc" onClick={handleSort} style={{'cursor': 'pointer'}} className="gridjs-sort gridjs-sort-neutral"></button></th>
                                                        <th scope="col">{t("Parent")} <button name="Parent" type="desc" onClick={handleSort} style={{'cursor': 'pointer'}} className="gridjs-sort gridjs-sort-neutral"></button></th>
                                                        <th scope="col">{t("Action")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    <SubjectList list={list} subjectsArray={subjectsArray} />
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="loading" style={{ display: "none" }}>
                                            <div className="text-center">
                                                <Spinner size="sm" className="flex-shrink-0"> Loading... </Spinner>
                                            </div>
                                        </div>
                                    </div>
                                    <Pagination 
                                        typeFunction={getSubjectList}
                                        currentPage={subjectList.current_page}
                                        firstPageUrl={subjectList.first_page_url}
                                        from={subjectList.from}
                                        lastPage={subjectList.last_page}
                                        lastPageUrl={subjectList.last_page_url}
                                        links={subjectList.links}
                                        nextPageUrl={subjectList.next_page_url}
                                        path={subjectList.path}
                                        perPage={subjectList.per_page}
                                        prevPageUrl={subjectList.prev_page_url}
                                        to={subjectList.to}
                                        total={subjectList.total}
                                        keywords={keywords}
                                        queryParams={queryParams} 
                                        setQueryParams={setQueryParams} 
                                    />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Subjects;