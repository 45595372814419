import { APIClient } from "./api_helper";
import * as url from "./url_helper";
import {GET_TASK_DATA, GET_TASK_LIST} from "./url_helper";

const api = new APIClient();

// Auth Methods
export const postLogin  = data => api.create(url.LOGIN_STISON, data);
export const postLogout = data => api.create(url.LOGOUT_STISON, data);
export const postRegister = data => api.create(url.REGISTER_STISON, data);
export const postForgetPwd = data => api.create(url.FORGET_PASSWORD, data);
export const postPwdReset = data => api.create(url.PASSWORD_RESET, data);

// Subjects
export const getSubjectListApi = filters => api.get(url.GET_SUBJECT_LIST, filters);
export const getParentSubjectsApi = filters => api.get(url.GET_PARENT_SUBJECTS, filters);
export const addNewSubjectData = subject => api.create(url.ADD_NEW_SUBJECT, subject);
export const getSubjectDataApi = subject => api.get(`${url.GET_SUBJECT_DATA}${subject}`);
export const updateSubjectData = subject => api.update(`${url.UPDATE_SUBJECT_DATA}${subject.SubjectId}`, subject);
export const deleteSubjectData = subject => api.delete(`${url.DELETE_SUBJECT}${subject.SubjectId}`);
export const deleteMultipledata = subjects => api.create(url.DELETE_MULTIPLE_SUBJECTS, subjects);

// Content
export const getContentApi = filters => api.get(url.GET_CONTENT, filters);
export const getContentSubjectsApi = filters => api.get(url.GET_CONTENT_SUBJECTS, filters);
export const updateContentSubjectsApi = content_subjects => api.create(url.GET_CONTENT_SUBJECTS, content_subjects);
export const deleteContentSubjectApi = content_subject => api.delete(`${url.GET_CONTENT_SUBJECTS}/${content_subject.ContentSubjectId}`);

// Books
export const getBooksApi = filters => api.get(url.GET_BOOKS, filters);

// SEO URL
export const getSeoUrlApi = filters => api.get(url.GET_SEO_URL, filters);
export const updateSeoSubjectsApi = filters => api.create(url.GET_SEO_URL, filters);
export const deleteSeoUrlApi = seo => api.delete(`${url.GET_SEO_URL}/${seo.SEOURLId}`);

// Organisation
export const getOrganisationApi = filters => api.get(url.GET_ORGANISATION, filters);
export const getOrganisationPendingApi = filters => api.get(url.GET_ORGANISATION_PENDING, filters);

// Tasks
export const addNewTaskData    = task => api.create(url.ADD_NEW_TASK, task);
export const updateTaskData    = task => api.update(`${url.UPDATE_TASK_DATA}`, task);
export const getTaskPendingAPI = filters => api.get(url.GET_TASK_PENDING, filters);

export const deleteTaskData = task => api.delete(`${url.DELETE_TASK}${task.TaskId}`);
export const getKanbanListAPI = filters => api.get(url.GET_TASK_LIST, filters);
export const getTaskDataApi  = task => api.get(`${url.GET_TASK_DATA}${task}`);

// Mail
export const getMailUserApi = filters => api.get(url.GET_MAIL_USER, filters);
export const updateMailUserApi = mailIds => api.update(`${url.GET_MAIL_USER}/${mailIds}`);
export const updateMultipleMailUserApi = filters => api.create(url.GET_MAIL_USER, filters);
export const deleteMailUserApi = mailIds => api.delete(`${url.GET_MAIL_USER}/${mailIds}`);
export const getReportsViewApi = filters => api.get(url.GET_REPORTS_VIEW, filters);

// Group Admin
export const getGroupAdminListApi = filters => api.get(url.GET_GROUP_ADMIN_LIST, filters);
export const getGroupAdminDataApi = group => api.get(`${url.GET_GROUP_ADMIN_LIST}/${group}`);
export const updateGroupAdminDataApi = group => api.update(`${url.GET_GROUP_ADMIN_LIST}/${group.GroupId}`, group);

export const getAdminMenusApi = filters => api.get(url.GET_ADMIN_MENUS, filters);
export const getAdminTabMenusApi = filters => api.get(url.GET_ADMIN_TAB_MENUS, filters);

// Themes 
export const getThemesListApi = filters => api.get(url.GET_THEMES_LIST, filters);

export const getThemeDataApi = theme => api.get(`${url.GET_THEME_DATA}${theme}`);
export const getParentThemesApi = filters => api.get(url.GET_PARENT_THEMES, filters);
export const updateThemeDataApi = theme => api.update(`${url.UPDATE_THEME_DATA}${theme.ThemeId}`, theme);
export const deleteThemeData = theme => api.delete(`${url.DELETE_THEME}/${theme.ThemeId}`);
export const deleteMultipleThemeData = themes => api.create(url.DELETE_MULTIPLE_THEME, themes);