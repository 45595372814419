import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
//redux
import { useSelector, useDispatch } from "react-redux";
import { 
    Card, 
    CardBody, 
    CardHeader, 
    Col, 
    Container, 
    Row,
    Button,
    Alert,
    Spinner,
    Modal, ModalBody 
} from 'reactstrap';

import { Link } from 'react-router-dom';
// 
import { getContentList, resetContentValues  } from "../../../store/titles/content/actions";
import Pagination from '../../../Components/Common/Pagination';
import SearchForm from '../../../Components/Common/SearchForm';

const ContentModal = ({ show, onSelectClick, onCloseClick, rowIndex }) => {
  const dispatch = useDispatch();
  const [contentItems, setContentItems] = useState([]);

  const { contentList, response, error, success } = useSelector((state) => ({
    success: state.Content.success,
    contentList: state.Content.contentList,
    response: state.Content.response,
    error: state.Content.error
  }));

  const [keywords, setKeywords] = useState('');
  const [queryParams, setQueryParams] = useState({});

  useEffect(() => {
    if (show) dispatch(getContentList());
  }, [dispatch, show]);

  useEffect(() => {
      let load = document.querySelector(".contentloading");
      if (load !== null) load.style.display = 'block';

      if (contentList.data && contentList.data.length) {
          setContentItems(contentList.data);
          if (load !== null) load.style.display = 'none';
      }
  }, [contentList.data]);
  
  const onClickDelete = (content) => {

  };

   // Checked All Subjects
  const checkedAll = () => {
     
  };

  const handleSort = el => {
      let data = {
          order_by : el.target.getAttribute("name"),
          order_desc : el.target.getAttribute("type")
      };

      setQueryParams({...queryParams, ...data});
      // dispatch(getSubjectList(queryParams));

      let sortBtn = document.querySelector('.gridjs-sort');
      sortBtn.classList.add('gridjs-sort-neutral');
      sortBtn.classList.remove('gridjs-sort-desc');
      sortBtn.classList.remove('gridjs-sort-asc');

      setTimeout(() => {
          if (el.target.getAttribute("type") === 'asc') {
              el.target.setAttribute('type', 'desc');
              
              el.target.classList.remove('gridjs-sort-neutral');
              el.target.classList.remove('gridjs-sort-desc');
              el.target.classList.add('gridjs-sort-asc');

          } else {
              el.target.setAttribute('type', 'asc');

              el.target.classList.remove('gridjs-sort-neutral');
              el.target.classList.remove('gridjs-sort-asc');
              el.target.classList.add('gridjs-sort-desc');
          }
      }, 200);
  };
  
  const multipleItems = e => {
     
  };
  return (
    <Modal style={{'maxWidth' : '1300px'}} size="xl" isOpen={show} toggle={onCloseClick} centered={true}>
        <ModalBody className="py-3 px-5">

                <div id="subjectList">
                    <SearchForm 
                            typeFunction={getContentList} 
                            setKeywords={setKeywords} 
                            queryParams={queryParams} 
                            setQueryParams={setQueryParams}
                        />

                    <div className="table-responsive table-card mt-3 mb-1">
                        <table className="table align-middle table-nowrap table-striped " id="subjectsTable">
                            <thead className="table-light">
                                <tr>
                                    <th scope="col" className="d-table-cell">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="checkAll" value="option" 
                                            onChange={e => {
                                                checkedAll(e.target.value);
                                            }}
                                            />
                                        </div>
                                    </th>
                                    <th scope="col">Book Title <button name="BookTitle" type="desc" onClick={handleSort} style={{'cursor': 'pointer'}} className="gridjs-sort gridjs-sort-neutral"></button></th>
                                    <th scope="col">ISBN13 <button  name="ISBN13" type="desc" onClick={handleSort} style={{'cursor': 'pointer'}} className="gridjs-sort gridjs-sort-neutral"></button></th>
                                    <th scope="col">Form <button name="Form" type="desc" onClick={handleSort} style={{'cursor': 'pointer'}} className="gridjs-sort gridjs-sort-neutral"></button></th>
                                    <th scope="col">Pub. Date <button name="PubDate" type="desc" onClick={handleSort} style={{'cursor': 'pointer'}} className="gridjs-sort gridjs-sort-neutral"></button></th>
                                    <th scope="col">Content ID <button name="ContentId" type="desc" onClick={handleSort} style={{'cursor': 'pointer'}} className="gridjs-sort gridjs-sort-neutral"></button></th>
                                    <th scope="col">Publisher <button name="Publisher" type="desc" onClick={handleSort} style={{'cursor': 'pointer'}} className="gridjs-sort gridjs-sort-neutral"></button></th>
                                    <th scope="col"> AI/Onix/Split/Merge </th>
                                </tr>
                            </thead>
                            <tbody className="list form-check-all">
                              {contentItems && contentItems.map((content, index) => (
                                    <tr key={index}>
                                        <th scope="row">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" name="chk_child" value={content.ContentId}
                                                    onChange={e => {
                                                        multipleItems(e);
                                                    }}
                                                />
                                            </div>
                                        </th>
                                        <td>
                                            {/* <Link className="" to={`/content/${content.ContentId}`} >{content.Title}</Link> */}
                                            <Button color="default" data-bs-toggle="modal" onClick={() => { onSelectClick(content, rowIndex); }}>{content.Title}</Button>
                                        </td>
                                        <td>{content.ISBN13}</td>
                                        <td>{content.ProductForm}</td>
                                        <td>{content.PublicationDate}</td>
                                        <td>{content.ContentId}</td>
                                        <td>{content.OrganisationName}</td>
                                        <td>
                                            <div className="d-inline-flex gap-2">
                                              <Link to="#" className="text-muted d-inline-block">
                                                <i className="ri-image-2-line fs-20"></i>
                                              </Link>
                                              <Link to="#" className="text-muted d-inline-block">
                                                <i className="ri-download-cloud-fill fs-20"></i>
                                              </Link>
                                              <Link to="#" className="text-muted d-inline-block align-bottom">
                                                <i className="las la-cubes fs-20 " style={{'lineHeight': '1.5'}}></i>
                                              </Link>
                                              <Link to="#" className="text-muted d-inline-block align-bottom">
                                                <i className="ri-inbox-line fs-20 " style={{'lineHeight': '1.5'}}></i>
                                              </Link>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="contentloading" style={{ display: "block" }}>
                            <div className="text-center">
                                <Spinner size="sm" />
                            </div>
                        </div>
                    </div>
                    
                </div>
                <Pagination 
                    typeFunction={getContentList}
                    currentPage={contentList.current_page}
                    firstPageUrl={contentList.first_page_url}
                    from={contentList.from}
                    lastPage={contentList.last_page}
                    lastPageUrl={contentList.last_page_url}
                    links={contentList.links}
                    nextPageUrl={contentList.next_page_url}
                    path={contentList.path}
                    perPage={contentList.per_page}
                    prevPageUrl={contentList.prev_page_url}
                    to={contentList.to}
                    total={contentList.total}
                    keywords={keywords}
                    queryParams={queryParams} 
                    setQueryParams={setQueryParams} 
                />
        </ModalBody>
        <div className="modal-footer">
            <button
              type="button"
              className="btn btn-link link-success fw-medium"
              data-bs-dismiss="modal"
              onClick={onCloseClick}
            >
            <i className="ri-close-line me-1 align-middle"></i>Close
          </button>
        </div>
    </Modal>
  );
};

ContentModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any,
};

export default ContentModal;