import {
    API_RESPONSE_SUCCESS,
    API_RESPONSE_ERROR,
    GET_TASK_PENDING,
    GET_TASKS, GET_TASK_DATA, ADD_TASK, ADD_TASK_SUCCESS, ADD_TASK_FAIL, UPDATE_TASK_SUCCESS, UPDATE_TASK_FAIL
} from "./actionType";
import {
    ADD_SUBJECT_FAIL,
    ADD_SUBJECT_SUCCESS, RESET_FLAG_VALUES,
    UPDATE_SUBJECT_FAIL,
    UPDATE_SUBJECT_SUCCESS
} from "../titles/subjects/actionType";

const INIT_STATE = {
    taskList: [],
    taskData: [],
    error: "",
    response: "",
    success: false,
};

const Task = (state = INIT_STATE, action) => {
    switch (action.type) {
        case API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case GET_TASK_PENDING:
                    return {
                        ...state,
                        taskList: action.payload.data,
                    };
                case GET_TASKS:
                    return {
                        ...state,
                        taskList: action.payload.data,
                    };
                case GET_TASK_DATA:
                    return {
                        ...state,
                        taskData: action.payload.data,
                    };

                default:
                    return { ...state };

            }
        case API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case GET_TASK_PENDING:
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                case GET_TASKS:
                    return {
                        ...state,
                        error: action.payload.error,
                    };
                case GET_TASK_DATA:
                    return {
                        ...state,
                        error: action.payload.data,
                    };
                default:
                    return { ...state };
            }
            case ADD_TASK_SUCCESS:
                return {
                    ...state,
                    success: true,
                };

            case ADD_TASK_FAIL:
                return {
                    ...state,
                    error: action.payload,
                    success: false,
                };
        case UPDATE_TASK_SUCCESS:
            return {
                ...state,
                taskData: action.payload.data,
                success: true,
            };

        case RESET_FLAG_VALUES :
            return {
                ...state,
                error: "",
                response: "",
                success: false,
                taskData:[],

            };

        case UPDATE_TASK_FAIL:
            return {
                ...state,
                error: action.payload,
                success: false,
            };


        default:
            return { ...state };
    }
};

export default Task;