//REGISTER
export const POST_FAKE_REGISTER = "/auth/signup";

//LOGIN
export const POST_FAKE_LOGIN = "/auth/signin";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/auth/forgot-password";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/user";

// AUTH
export const LOGIN_STISON = "/login";
export const LOGOUT_STISON = "/logout";
export const REGISTER_STISON = "/register";
export const FORGET_PASSWORD = "/forgot-password";
export const PASSWORD_RESET  = "/password/reset";

//SUBJECTS
export const GET_SUBJECT_LIST = "/subjects";
export const GET_PARENT_SUBJECTS = "/subjects/parent";
export const ADD_NEW_SUBJECT = "/subjects/create";
export const GET_SUBJECT_DATA  = "/subjects/";
export const DELETE_SUBJECT  = "/subjects/";
export const UPDATE_SUBJECT_DATA = "/subjects/update/";
export const DELETE_MULTIPLE_SUBJECTS = "/subjects/delete";

export const GET_ORGANISATION = "/organisation";
export const GET_ORGANISATION_PENDING = "/organisation/pending";

// TASK
export const ADD_NEW_TASK    = '/tasks/create'
export const UPDATE_TASK_DATA    = '/tasks/update'
export const GET_TASK_PENDING = "/task/pending";
export const DELETE_TASK = "/task/delete/"
// KANBAN
export const GET_TASK_LIST = "/task/kanban/list";
export const GET_TASK_DATA   = "/task/";

// Content
export const GET_CONTENT = "/content";
export const GET_CONTENT_SUBJECTS = "/content-subject";

// Books
export const GET_BOOKS = "/books";

// SEO
export const GET_SEO_URL = "/seo-url";

// Mail
export const GET_MAIL_USER = "/mail-user";
export const GET_REPORTS_VIEW = "/reports-view";

// GROUP ADMIN
export const GET_GROUP_ADMIN_LIST = "/admin-groups";
export const GET_ADMIN_MENUS = "/admin-groups/get-admin-menus";
export const GET_ADMIN_TAB_MENUS = "/admin-groups/get-admin-tab-menus";

export const GET_THEMES_LIST = "/themes";
export const GET_THEME_DATA  = "/themes/";
export const GET_PARENT_THEMES = "/themes/parent";

export const UPDATE_THEME_DATA = "/themes/update/";

export const DELETE_THEME  = "/themes";
export const DELETE_MULTIPLE_THEME = "/themes/delete";