import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { APIClient } from "../../../helpers/api_helper";
import * as url from "../../../helpers/url_helper";

import { GET_ORGANISATION, GET_ORGANISATION_PENDING_LIST } from "./actionType";

import { 
    OrganisationApiResponseSuccess, 
    OrganisationApiResponseError 
} from "./actions";

import { 
    getOrganisationPendingApi,
    getOrganisationApi
} from "../../../helpers/stison_api_helper";

function* getOrganisation({ params }) {

    let filters = {};

    // map object for filter parameters in API
    if (params) Object.entries(params).map(([k,v]) => filters[k] = v);

    try {
        const response = yield call(getOrganisationApi, filters);
        yield put(OrganisationApiResponseSuccess(GET_ORGANISATION, response));
    } catch (error) {
        yield put(OrganisationApiResponseError(GET_ORGANISATION, error));
    }
}

// get organisation pending list
function* getOrganisationPendingList({ params }) {

    let filters = {};

    // map object for filter parameters in API
    if (params) Object.entries(params).map(([k,v]) => filters[k] = v);
    console.log(params);
    try {
        const response = yield call(getOrganisationPendingApi, filters);

        console.log(response);
        yield put(OrganisationApiResponseSuccess(GET_ORGANISATION_PENDING_LIST, response));
    } catch (error) {
        yield put(OrganisationApiResponseError(GET_ORGANISATION_PENDING_LIST, error));
    }
}

export function* watchGetOrganisationPendingList() {
    yield takeEvery(GET_ORGANISATION_PENDING_LIST, getOrganisationPendingList);
}

export function* watchGetOrganisation() {
    yield takeEvery(GET_ORGANISATION, getOrganisation);
}


function* OrganisationSaga() {

    yield all([
        fork(watchGetOrganisationPendingList),
        fork(watchGetOrganisation)
    ]);
}
  
export default OrganisationSaga;