import { useEffect, useState } from "react";
import { getLoggedinUser, getAccessToken, getAdminMenusSetup } from "../../helpers/api_helper";

const useProfile = () => {

  const userProfileSession = getLoggedinUser();
  const accessTokenSession = getAccessToken();

  var token = accessTokenSession;

  const [loading, setLoading] = useState(userProfileSession ? false : true);
  const [userProfile, setUserProfile] = useState(
    userProfileSession ? userProfileSession : null
  );

  useEffect(() => {
    const userProfileSession = getLoggedinUser();
    var token = accessTokenSession;
    setUserProfile(userProfileSession ? userProfileSession : null);
    setLoading(token ? false : true);
  }, [accessTokenSession]);

  return { userProfile, loading, token };
};

export { useProfile };