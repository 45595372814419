import React from "react";
import { Redirect } from "react-router-dom";

//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import PasswordReset from "../pages/Authentication/PasswordReset";
import Cover404 from '../pages/AuthenticationInner/Errors/Cover404';
import Basic404 from '../pages/AuthenticationInner/Errors/Basic404';
import Alt404 from '../pages/AuthenticationInner/Errors/Alt404';

// User Profile
import UserProfile from "../pages/Authentication/user-profile";

// stison routes
//Dashboard
import Dashboard from "../pages/Dashboard/Dashboard";
import ReportOutput from "../pages/Reports/ReportOutput";

import Subjects from "../pages/Titles/Subjects/Subjects";
import SubjectEdit from "../pages/Titles/Subjects/SubjectEdit";
import SubjectCreate from "../pages/Titles/Subjects/SubjectCreate";

// SubjectsTab
import SubjectsGeneral from "../pages/Titles/Subjects/Tabs/SubjectsGeneral";
import SubjectsBooks from "../pages/Titles/Subjects/Tabs/SubjectsBooks";
import SubjectsSeo from "../pages/Titles/Subjects/Tabs/SubjectsSeo";

import Organisation from "../pages/General/Organisation/Organisation";
import OrganisationPending from "../pages/General/Organisation/OrganisationPending";

import Task from "../pages/Task/Tasks";
import TaskForm from "../pages/Task/TaskForm";
import TaskKanban from  "../pages/Task/TaskKanban";

import GroupAdmin from "../pages/General/GroupAdmin/GroupAdmin";
import GroupAdminEdit from "../pages/General/GroupAdmin/GroupAdminEdit";

import GroupAdminGroupDetails from "../pages/General/GroupAdmin/Tabs/GroupAdminGroupDetails";
import GroupAdminMenu from "../pages/General/GroupAdmin/Tabs/GroupAdminMenu";
import GroupAdminTabs from "../pages/General/GroupAdmin/Tabs/GroupAdminTabs";
import GroupAdminUserDetails from "../pages/General/GroupAdmin/Tabs/GroupAdminUserDetails";
import Themes from "../pages/Titles/Themes/Themes";
import ThemesCreate from "../pages/Titles/Themes/ThemesCreate";
import ThemesEdit from "../pages/Titles/Themes/ThemesEdit";
import ThemesGeneral from "../pages/Titles/Themes/Tabs/ThemesGeneral";

const authProtectedRoutes = [
  //User Profile
  { path: "/profile", component: UserProfile },

  { path: "/dashboard", component: Dashboard },
  { path: "/subjects", component: Subjects },
  { path: "/subjects/create", component: SubjectCreate },
  // { path: "/subjects/:id", component: SubjectEdit },
  { path: "/subjects/general/:id", component: SubjectsGeneral },
  { path: "/subjects/books/:id", component: SubjectsBooks },
  { path: "/subjects/seo/:id", component: SubjectsSeo },

  { path: "/organisation", component: Organisation },
  { path: "/organisation/pending", component: OrganisationPending },
  // { path: "/tasks", component:  Task},
  { path: "/tasks/:id", component:  TaskForm},
  { path: "/tasks/create", component:  TaskForm},
  { path: "/tasks", component: TaskKanban},
  { path: "/404", component: Basic404 },

  { path: "/report-output/:id", component: ReportOutput },

  //Group Admin
  {  path: "/group-admin", component: GroupAdmin },
  {  path: "/group-admin/group-details/:id", component: GroupAdminGroupDetails },
  {  path: "/group-admin/menu/:id", component: GroupAdminMenu },
  {  path: "/group-admin/tabs/:id", component: GroupAdminTabs },
  {  path: "/group-admin/user-details/:id", component: GroupAdminUserDetails },

  { path: "/themes", component: Themes },
  { path: "/themes/create", component: ThemesCreate },
  { path: "/themes/general/:id", component: ThemesGeneral },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
];

const errorPagesRoutes = [
  // Authentication Page
  { path: "/auth-404", component: Basic404 },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPasswordPage },
  { path: "/password/reset", component: PasswordReset },
  { path: "/register", component: Register },

  { path: "/auth-404", component: Cover404 },
];

export { authProtectedRoutes, publicRoutes, errorPagesRoutes};