import PropTypes from "prop-types";
import React from "react";
import { Modal, ModalBody } from "reactstrap";

const SuccessModal = ({ show, onCloseClick, Message }) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true} backdrop={'static'}>
      <ModalBody className="text-center p-5">
        <lord-icon
            src="https://cdn.lordicon.com/lupuorrc.json"
            trigger="loop"
            colors="primary:#121331,secondary:#08a88a"
            style={{ width: "120px", height: "120px" }}>
        </lord-icon>

        <div className="mt-4">
            <h4 className="mb-3">{Message}</h4>
            <div className="hstack gap-2 justify-content-center">

                <button
                    type="button"
                    className="btn w-sm btn-success"
                    data-bs-dismiss="modal"
                    onClick={onCloseClick}
                >
                    Close
                </button>
            </div>
        </div>
    </ModalBody>
    </Modal>
  );
};

SuccessModal.propTypes = {
  onCloseClick: PropTypes.func,
  show: PropTypes.any,
};

export default SuccessModal;
