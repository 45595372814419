import React, { useEffect, useState } from "react";
import { 
    Row, 
    Col, 
    CardBody, 
    Card,
    Alert, 
    Container, 
    Input, 
    Label, 
    Form, 
    FormFeedback,
    Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";

import BreadCrumb from '../../../../Components/Common/BreadCrumb';

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";

import { Editor} from '@tinymce/tinymce-react';

import FailedModal from "../../../../Components/Common/FailedModal";
import SuccessModal from "../../../../Components/Common/SuccessModal";

import ThemesEdit from "../ThemesEdit";
import { getParentThemes, getThemeData, updateTheme, resetThemesValues } from "../../../../store/actions";

const ThemesGeneral = (props) => {
    const dispatch  = useDispatch();
    const { t, i18n } = useTranslation();
    const { history, id, setTitle } = props;

    // themeList Temporary Parent Data
    const { themeData, parentData, error, success, isThemeUpdate } = useSelector((state) => state.Themes);
    
    const themeId = props.match.params.id;

    const [checked, setChecked] = useState(true);
    const [failedModal, setFailedModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [parentThemeList, setParentThemeList] = useState([]);
    const [txtEditor, setTxtEditor] = useState('');

    useEffect(() => {
        dispatch(getThemeData(themeId));
    }, [dispatch, themeId]);

    // get Parent Theme Data
    useEffect(() => {
        dispatch(getParentThemes());
     }, [dispatch]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            themecode: themeData?.ThemeCode || "",
            themename:  themeData?.ThemeName || "",
            parent: themeData?.Parent || "",
            showonweb:  themeData?.ShowOnWeb &&  themeData?.ShowOnWeb === 1 ? true : false,
        },
        validationSchema: Yup.object({
            themecode: Yup.string().required("Please Enter Theme Code"),
            themename: Yup.string().required("Please Enter Theme Name"),
        }),
        onSubmit: (values, { setSubmitting }) => {

            let { ThemeId, ThemeCode, ThemeName, Parent } = themeData;

            let themeFormData = {
                ThemeId,
                ThemeCode: values.themecode,
                ThemeName: values.themename,
                Parent: values.parent,
                ThemeDesc: txtEditor,
                ShowOnWeb: checked,
            };

            const fetchData = async () => {
                dispatch(updateTheme(themeFormData));
            };
        
            fetchData();
        }
    });

    useEffect(() => {
        if (typeof themeData !== 'undefined' && themeData.ThemeDesc) setTxtEditor(themeData.ThemeDesc);
    }, [themeData]);

    useEffect( () => {
        if (parentData) setParentThemeList(parentData);
    }, [parentData]);

    useEffect(() => {
        if (error && themeData) {
            setFailedModal(true);
            setTimeout(() => {
                dispatch(resetThemesValues());
            }, 1000);
        }
        
        if (success && !error) {
            setSuccessModal(true);
        }

        if (isThemeUpdate) {
            const fetchData = async () => {
                dispatch(resetThemesValues());
                await dispatch(getThemeData(themeId))
                await dispatch(getParentThemes());
            };
        
            fetchData();
        }

    }, [dispatch, error, success, themeData, isThemeUpdate, themeId]);

    const changeChecked = (e) =>  {
        setChecked(e.target.checked);
    };

    const successModalAction = () => {
        setSuccessModal(false);
    };

    const handleChangeEditor = (e) => {
        setTxtEditor(e.target.getContent());
    };
    return (
        <React.Fragment>
            <ThemesEdit>
                <FailedModal
                    show={failedModal}
                    onCloseClick={() => setFailedModal(false)}
                /> 

                <SuccessModal
                    show={successModal}
                    onCloseClick={successModalAction}
                    Message="Successfully updated theme."
                />

                <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }} 
                className="needs-validation" action="#">
                    
                    <Row>
                        <Col lg={8}>
                            <Card>
                                <CardBody>
                                    <div className="mb-3">
                                        <Label className="form-label" htmlFor="themecode">{t('Code')}<span className="text-danger">*</span></Label>
                                        <Input
                                            id="themecode"
                                            name="themecode"
                                            className="form-control"
                                            placeholder={t('Code')}
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.themecode || ""}
                                            invalid={
                                                validation.touched.themecode && validation.errors.themecode ? true : false
                                            }
                                        />
                                        {validation.touched.themecode && validation.errors.themecode ? (
                                            <FormFeedback type="invalid"><div>{validation.errors.themecode}</div></FormFeedback>
                                        ) : null}
                                    </div>

                                    <div className="mb-3">
                                        <Label className="form-label" htmlFor="themename">{t('theme Name')}<span className="text-danger">*</span></Label>
                                        <Input
                                            id="themename"
                                            name="themename"
                                            className="form-control"
                                            placeholder={t('theme Name')}
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.themename || ""}
                                            invalid={
                                                validation.touched.themename && validation.errors.themename ? true : false
                                            }
                                        />
                                        {validation.touched.themename && validation.errors.themename ? (
                                            <FormFeedback type="invalid"><div>{validation.errors.themename}</div></FormFeedback>
                                        ) : null}
                                    </div>

                                    <div className="mb-3">
                                        <Label className="form-label">{t('Theme Desc')}</Label>
                                        <Editor
                                            initialValue={themeData?.ThemeDesc}
                                            init={{
                                                plugins: 'link image code',
                                                toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                                            }}
                                            onChange={handleChangeEditor}
                                            apiKey='plgopn4aim97g0b5a7yexve7zj5hv8l698dp5c0agq6eillf'
                                        />
                                    </div>
                                </CardBody>
                                <div className="mb-3 text-end">
                                    <button type="submit" className="btn btn-primary w-sm mr-2" style={{ 'marginRight' : '10px'}}>
                                        {t('Update')}
                                    </button>

                                    <Link className="btn btn-warning w-sm me-1" to="/themes">{t('Cancel')}</Link>
                                </div>
                            </Card>
                        </Col>

                        <Col lg={4}>
                            <Card>
                                <CardBody>
                                    <div className=" mb-3">
                                        <Label className="form-label " htmlFor="parent">{t('Parent')}</Label>
                                        <Input
                                            name="parent"
                                            type="select"
                                            className="form-select"
                                            id="parent"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                                validation.values.parent || ''
                                            }
                                            >
                                            <option value="">{t('Select Parent')}</option>
                                            {parentThemeList && parentThemeList.map(theme => (
                                                (themeId !== theme.ThemeId ? <option key={theme.ThemeId} value={theme.ThemeId}>{theme.ThemeName}</option> : '')
                                            ))};
                                        </Input>
                                        {validation.touched.status &&
                                        validation.errors.status ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.status}
                                        </FormFeedback>
                                        ) : null}
                                    </div>

                                    <div className="form-check form-check-outline form-check-right form-check-primary mt-3 ">
                                        <Label className="form-label " htmlFor="showOnWeb">{t('Show on web')}</Label>
                                        <Input 
                                            id="showonweb"
                                            name="showonweb" 
                                            className="form-check-input" 
                                            type="checkbox"
                                            onClick={changeChecked}
                                            defaultChecked={themeData?.ShowOnWeb}
                                            style={{'marginLeft': '5px' }}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </ThemesEdit>
        </React.Fragment>
    );
};

export default ThemesGeneral;
