// Actions
export const API_RESPONSE_SUCCESS = "API_RESPONSE_SUCCESS";
export const API_RESPONSE_ERROR = "API_RESPONSE_ERROR";
export const GET_TASK_PENDING = "GET_TASK_PENDING";
export const DELETE_TASK = "DELETE_TASK";
export const ADD_TASK = "ADD_TASK";
export const ADD_TASK_SUCCESS = "ADD_TASK_SUCCESS";
export const ADD_TASK_FAIL = "ADD_TASK_FAIL";

export const UPDATE_TASK = "UPDATE_TASK";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_FAIL = "UPDATE_TASK_FAIL";


export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_FAIL = "DELETE_TASK_FAIL";

export const GET_TASKS = 'GET_TASKS';
export const GET_TASK_DATA = 'GET_TASK_DATA';

