import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";

//redux
import { useSelector, useDispatch } from "react-redux";
import { Link } from 'react-router-dom';

import { 
    Col, 
    Dropdown, 
    DropdownMenu, 
    DropdownToggle,
    Nav,
    NavItem, 
    NavLink,
    Row, 
    TabContent, 
    TabPane, 
    Modal, 
    ModalBody, 
    Button, 
    ModalHeader 
} from 'reactstrap';
import classnames from 'classnames';

//SimpleBar
import SimpleBar from "simplebar-react";

import { getMailUser, resetFlagNotification, updateMailUser, updateMultipleMailUser, getReportsView, deleteMailUser } from "../../store/common/actions";
import SuccessModal from "./SuccessModal";

import CustomModal from "./CustomModal";

const NotificationDropdown = () => {

    const dispatch = useDispatch();
    const divRef = useRef(null);

    const [mailUserItems, setMailUserItems] = useState([]);
    const [mailUserCount, setMailUserCount] = useState(0);
    const [mailContent, setMailContent] = useState({});

    const [mailSelected, setMailSelected] = useState([]);
    const [typeAction, setTypeAction] = useState('');

    const { mailUserList, response, error, success } = useSelector((state) => ({
        success: state.Common.success,
        mailUserList: state.Common.mailUserList,
        response: state.Common.response,
        error: state.Common.error
    }));
    
    //Dropdown Toggle
    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
    };

    //Tab 
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const [modal_animationZoom, setmodal_animationZoom] = useState(false);
    function tog_animationZoom() {
        setmodal_animationZoom(!modal_animationZoom);
    }

    useEffect(() => {
        dispatch(getMailUser());
    }, [dispatch]);

    useEffect(() => {

        let count = 0;
        let nores = document.querySelector(".noresult");
        if (nores != null) nores.style.display = 'block';

        if (mailUserList.data && mailUserList.data.length) {
            setMailUserItems(mailUserList.data);
        
            const unopenedCount = mailUserList.data.filter(item => item.Opened === 0).length;
            setMailUserCount(unopenedCount);
            if (nores != null) nores.style.display = 'none';
        }

    }, [mailUserList]);

    useEffect(() => {
        if (mailSelected.length > 0 && success && !error) {
          let count = 0;
          let elIndex = [];
          let data = [...mailUserItems];
          let convertToIntItems = mailSelected.map(Number);
      
          mailUserItems.forEach((it, index) => {
            if (convertToIntItems.includes(it.MailUserId)) elIndex.push(index);
          });
      
          // Update state based on typeAction
          setMailUserItems(prevData => {
            if (typeAction === 'read') {
              // Update the 'Opened' property for read action
              return prevData.map((item, index) => ({
                ...item,
                Opened: elIndex.includes(index) ? 1 : item.Opened,
              }));
            } else if (typeAction === 'remove') {
              // Remove items for remove action
              return prevData.filter((_, index) => !elIndex.includes(index));
            }
          });
      
          // Update count based on 'Opened' property
          count = data.reduce((acc, item) => (item.Opened === 0 ? acc + 1 : acc), 0);
      
          setTimeout(() => {
            setMailUserCount(count);
            setMailSelected([]);
            dispatch(resetFlagNotification());
            uncheckCheckBoxes();
          }, 500);
        }
    }, [dispatch, mailUserItems, mailSelected, typeAction, error, success]);

    const handleClickMailUser = (index) => {
        let data = [...mailUserItems];
        if (data[index].MailUserId)
        {
            let mSelect = [...mailSelected];
            if (!mSelect.includes(data[index].MailUserId)) mSelect.push(data[index].MailUserId);
            setMailSelected(mSelect);
        }

        setMailContent(data[index]);
        setmodal_animationZoom(true);
    }

    const multipleItems = e => {
        let mSelect = [...mailSelected];

        if (e.target.checked) {
            if (!mSelect.includes(e.target.value)) mSelect.push(e.target.value);
        } else {
            if (mSelect.includes(e.target.value)) {
                let itemIndex = mSelect.indexOf(e.target.value);
                mSelect.splice(itemIndex, 1);
            }
        }

        setMailSelected(mSelect);
    };

    const readSelectedMail = () => {
        let data = {
            type: 'read',
            MailUserId : mailSelected
        };

        setTypeAction('read');
        if (mailSelected.length > 0) { 
            let mailIds = mailSelected.join(',');
            dispatch(updateMailUser(mailIds));
        }
    };

    const removeSelectedMail = () => {
        let data = {
            type: 'remove',
            MailUserId : mailSelected
        };

        setTypeAction('remove');
        if (mailSelected.length > 0) { 
            let mailIds = mailSelected.join(',');
            dispatch(deleteMailUser(mailIds));
        }
        uncheckCheckBoxes();
    };

    const uncheckCheckBoxes = () => {
        const element  = document.querySelectorAll(".notification-checkbox");
        element.forEach((node) => {
            node.checked = false;
        });
    };

    const closeReadModal = () => {
        readSelectedMail();
        tog_animationZoom();
    };

    const deleteReadModal = () => {
        removeSelectedMail();
        tog_animationZoom();
    };

    return (
        <React.Fragment>
            <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                    <i className='bx bx-bell fs-22'></i>
                    {mailUserCount !== 0 ? <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">{mailUserCount}<span className="visually-hidden">unread messages</span></span>
                        : ''}
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                    <div className="dropdown-head bg-primary bg-pattern rounded-top">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold text-white"> Messages </h6>
                                </Col>
                                <div className="col-auto dropdown-tabs">
                                    <span className="badge badge-soft-light fs-13"> {mailUserCount} New</span>
                                </div>
                            </Row>
                        </div>

                        <div className="px-2 pt-2" style={{'display': 'none'}}>
                            <Nav className="nav-tabs dropdown-tabs nav-tabs-custom">
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({ active: activeTab === '1' })}
                                        onClick={() => { toggleTab('1'); }}
                                    >
                                        All (4)
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </div>

                    </div>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1" className="py-2 ps-2">
                            <SimpleBar style={{ maxHeight: "300px" }} className="pe-2">

                                {mailUserItems.length > 0 &&
                                    <div className="col-auto dropdown-tabs text-end mb-2">
                                        <span
                                            style={{'cursor': 'pointer'}}
                                            className="badge badge-outline-info fs-9"
                                            onClick={readSelectedMail}
                                        > Mark as read</span>{" "}
                                        <span
                                            style={{'cursor': 'pointer'}}
                                            className="badge badge-outline-danger fs-9"
                                            onClick={removeSelectedMail}
                                        > Remove selected</span>
                                    </div>
                                }

                                {mailUserItems.length > 0 && mailUserItems.map((item, index) => (
                                    <div key={index} 
                                        className={`${'text-reset notification-item d-block dropdown-item position-relative'}${ item.Opened === 0? ' active' : ''}`}
                                        >
                                        <div className="d-flex">
                                            <div className="avatar-xs me-3">
                                                <span className="avatar-title bg-soft-info text-info rounded-circle fs-16">
                                                    <i className="bx bx-message-square-dots"></i>
                                                </span>
                                            </div>

                                            <div className="flex-1" onClick={() => {handleClickMailUser(index)}}>
                                                <Link to="#" className="stretched-link"><h6 className="mt-0 mb-1 fs-13 fw-semibold">{item.PersonName}</h6></Link>
                                                <div className="fs-13 text-muted">
                                                    <p className="mb-1">{item.Subject}.</p>
                                                </div>
                                                <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                    <span><i className="mdi mdi-clock-outline"></i> {item.Created}</span>
                                                </p>
                                            </div>

                                            <div className="px-2 fs-15">
                                                <div className="form-check notification-check">
                                                    <input className="notification-checkbox form-check-input" type="checkbox" 
                                                        value={item.MailUserId}
                                                        onChange={e => {
                                                            multipleItems(e);
                                                        }}
                                                    />
                                                    <label className="form-check-label" htmlFor="all-notification-check02"></label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                
                                <div className="noresult" style={{ display: "none" }}>
                                    <div className="text-center">
                                        no result..
                                    </div>
                                </div>
                            </SimpleBar>

                        </TabPane>
                    </TabContent>
                </DropdownMenu>
            </Dropdown>
            {/* <Modal id="flipModal" isOpen={modal_animationZoom} toggle={() => { closeReadModal(); }} modalClassName="zoomIn" centered >
                <ModalHeader className="modal-title" id="flipModalLabel" toggle={() => { closeReadModal(); }}>
                    {mailContent.Subject}
                </ModalHeader>
                <ModalBody>
                    <div ref={divRef} dangerouslySetInnerHTML={{__html:mailContent.Message}}></div>
                </ModalBody>
                <div className="modal-footer">
                    <Button color="light" onClick={() => { closeReadModal(); }}> Close </Button>
                    <Button color="danger" onClick={() => { deleteReadModal(); }}> Delete </Button>
                </div>
            </Modal> */}

            <CustomModal
                id="flipModal"
                isOpen={modal_animationZoom}
                toggle={() => { closeReadModal(); }}
                modalClassName="zoomIn"
                centered
                header={
                    <ModalHeader className="modal-title" id="flipModalLabel" toggle={() => { closeReadModal(); }}>
                        {mailContent.Subject}
                    </ModalHeader>
                }
                body={<div ref={divRef} dangerouslySetInnerHTML={{__html:mailContent.Message}}></div>}
                footer={
                    <div className="modal-footer">
                        <Button color="light" onClick={() => { closeReadModal(); }}> Close </Button>
                        <Button color="danger" onClick={() => { deleteReadModal(); }}> Delete </Button>
                    </div>
                }
                callBack={() => {
                    if (Object.keys(mailContent).length >0 && modal_animationZoom && divRef.current)
                    {
                        const myDivComponent = divRef.current;
                        const hyperLink      = myDivComponent.querySelector('a');
                        if (hyperLink)
                        {
                            const searchParams   = new URLSearchParams(hyperLink.href);
                            const reportOutputId = searchParams.get('id');
                            hyperLink.href       =`/report-output/${reportOutputId}`;
                        }
                    }
                }}
            />
        </React.Fragment>
    );
};

export default NotificationDropdown;