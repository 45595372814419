import axios from "axios";
import { api } from "../config";

// default
axios.defaults.baseURL = api.API_URL;

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";

// content type
const token = JSON.parse(localStorage.getItem("authUser")) ? JSON.parse(localStorage.getItem("authUser")).token : null;

if (token) axios.defaults.headers.common["Authorization"] = "Bearer " + token;

axios.interceptors.request.use(
  (config) => {
    const userIpAddress = getUserIp();
    if (userIpAddress)
    {
      config.headers['X-User-IP'] = userIpAddress.ip;
    }
    // Do something before the request is sent
    // console.log('Request is about to be sent:', config);
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.response.status) {
      case 500:
          message = "Internal Server Error";
        break;
      case 401:
          message = "Invalid credentials";

          // force logout when token expires
          localStorage.removeItem("authUser");
          localStorage.removeItem("accessToken");
          localStorage.removeItem("authUserOrganisation");
      
          if (window.location.pathname !== '/login') 
            window.location.href = `${window.location.protocol}//${window.location.host}/auth-404`;

        break;
      case 404:
          message = "Sorry! the data you are looking for could not be found";
          window.location.href = `${window.location.protocol}//${window.location.host}/404`;
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

class APIClient {
  /**
   * Fetches data from given url
   */
  constructor () {
    if (localStorage.getItem("user_ip") === null)
    {
      axios.get('https://jsonip.com')
      .then((response) => {
        localStorage.setItem("user_ip", JSON.stringify(response));
      })
      .catch((error) => {
        console.error('Error getting IP address:', error);
      });
    }
  }
  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
  get = (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}
const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

const getAccessToken = () => {
  const token = localStorage.getItem("accessToken");
  if (!token) {
    return null;
  } else {
    return JSON.parse(token);
  }
};

const getAdminMenusSetup = () => {
  const menus = localStorage.getItem("adminMenus");
  if (!menus) {
    return null;
  } else {
    return JSON.parse(menus);
  }
};

const getUserIp = () => {
  const user_ip = localStorage.getItem("user_ip");
  if (!user_ip) {
    return null;
  } else {
    return JSON.parse(user_ip);
  }
}

export { APIClient, setAuthorization, getLoggedinUser, getAccessToken, getAdminMenusSetup };