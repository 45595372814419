import React, { useEffect, useState } from "react";
import { 
    Row, 
    Col, 
    CardBody, 
    Card,
    Alert, 
    Container, 
    Input, 
    Label, 
    Form, 
    FormFeedback,
    Nav, NavItem, NavLink,
    TabContent, TabPane
} from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import { Redirect, Link } from "react-router-dom";


// Formik Validation
import * as Yup from "yup";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import Dropzone from "react-dropzone";

import { useFormik } from "formik";
import { isEmpty } from "lodash";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useQuill } from "react-quilljs";
import classnames from 'classnames';
import "quill/dist/quill.snow.css";

// actions
import { addNewSubject, getParentSubjects, resetFlagValues } from "../../../store/titles/subjects/actions";

// modals
import FailedModal from "../../../Components/Common/FailedModal";
import SuccessModal from "../../../Components/Common/SuccessModal";
import BreadCrumb from '../../../Components/Common/BreadCrumb';

const SubjectCreate = (props) => {

    const dispatch = useDispatch();
    const { quill, quillRef } = useQuill();

    const {history} = props;

    const currentOrg   = localStorage.getItem("authUserOrganisation");
    const organisation = JSON.parse(currentOrg);

    const [parentSubjectList, setParentSubjectList] = useState([]);
    const [checked, setChecked] = useState(true);

    const [failedModal, setFailedModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);

    // subjectList Temporary Parent Data
    const { subjectList, parentList, error, success } = useSelector((state) => state.Subjects);

    // get Parent Subject Data
    useEffect(() => {
        dispatch(getParentSubjects());
     }, [dispatch]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            subjectcode: '',
            subjectname: '',
            subjectdescription: '',
            showonweb: '',
            parent: '',
            // subjectfile: '',
        },
        validationSchema: Yup.object({
            subjectcode: Yup.string().required("Please Enter Subject Code"),
            subjectname: Yup.string().required("Please Enter Subject Name"),
        }),
        onSubmit: (values) => {
            let data = {
                OrganisationId: organisation.OrganisationId,
                SubjectCode: values.subjectcode,
                SubjectName: values.subjectname,
                SubjectDesc: quillRef.current.firstChild.innerHTML,
                Parent: (values.parent ? values.parent : null),
                ShowOnWeb: checked,
                // AssetFile: values.subjectfile
            };

            dispatch(addNewSubject(data));

            setTimeout(() => {
                validation.resetForm();
                quill.setContents([{ insert: '\n' }]);
            }, 1000);
        }
    });

    useEffect( () => {
        if (parentList) setParentSubjectList(parentList);
    }, [parentList]);
    
    const changeChecked = (e) =>  {
        setChecked(e.target.checked);
    };

    useEffect(() => {
        if (error && !success) {
            setFailedModal(true);
        }

        if (success && !error && subjectList) {
            setSuccessModal(true);
        }

        setTimeout(() => {
            dispatch(resetFlagValues());
        }, 1000);

    }, [dispatch, error, success, subjectList]);

    const successModalAction = () => {
        setSuccessModal(false);
        setTimeout(() => { history.push("/subjects") }, 500);
    };

    //Tab 
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    document.title="Stison Publishing Solutions | Create Subject";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Create Subject" pageTitle="Create" />
                    <SuccessModal
                        show={successModal}
                        onCloseClick={successModalAction}
                        Message="Successfully added new subject."
                    />

                    <FailedModal
                        show={failedModal}
                        onCloseClick={() => setFailedModal(false) }
                    />

                    <Row>
                        <Col lg={12}>
                            <Card className="mt-n4 mx-n4">
                                <div className="bg-soft-warning">
                                    <CardBody className="pb-0 px-4">
                                        <Row className="mb-3">
                                            <div className="col-md">
                                                <Row className="align-items-center g-3">
                                                    {/* <div className="col-md-auto">
                                                        <div className="avatar-md">
                                                            <div className="avatar-title bg-white rounded-circle">
                                                                <i className=' ri-book-mark-fill' style={{'color' : '#495057', 'fontSize' : '2em'}}></i>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-md mt-4">
                                                        <div>
                                                            <h4 className="fw-bold">Create Subject</h4>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                            <div className="col-md-auto">
                                                <div className="hstack gap-1 flex-wrap">
                                                    <button type="button" className="btn py-0 fs-20 text-body" style={{'padding': '0.2rem 0.5rem'}}>
                                                        <i className="ri-dashboard-2-line"></i>
                                                    </button>
                                                    <button type="button" className="btn py-0 fs-20" style={{'padding': '0.2rem 0.5rem'}}>
                                                        <i className=" ri-image-add-fill"></i>
                                                    </button>
                                                 
                                                    <button type="button" className="btn py-0 fs-20 text-body" style={{'padding': '0.2rem 0.5rem'}}>
                                                        <i className="ri-download-cloud-fill"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </Row>

                                        <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '1' }, "fw-semibold")}
                                                    onClick={() => { toggleTab('1'); }}
                                                    href="#">
                                                    <i className=" ri-add-fill me-1 align-bottom"></i>{" "}
                                                    Create
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <TabContent activeTab={activeTab} className="text-muted">
                                <TabPane tabId="1">
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                        className="needs-validation" >
                                            
                                        <Row>
                                            <Col lg={8}>
                                                <Card>
                                                    <CardBody>
                                                        <Col lg={6}>
                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="subjectcode">Subject Code <span className="text-danger">*</span></Label>
                                                                <Input
                                                                    id="subjectcode"
                                                                    name="subjectcode"
                                                                    className="form-control"
                                                                    placeholder="Enter Subject Code"
                                                                    type="text"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.subjectcode || ""}
                                                                    invalid={
                                                                        validation.touched.subjectcode && validation.errors.subjectcode ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.subjectcode && validation.errors.subjectcode ? (
                                                                    <FormFeedback type="invalid"><div>{validation.errors.subjectcode}</div></FormFeedback>
                                                                ) : null}
                                                            </div>

                                                            <div className="mb-3">
                                                                <Label className="form-label" htmlFor="subjectname">Subject Name <span className="text-danger">*</span></Label>
                                                                <Input
                                                                    id="subjectname"
                                                                    name="subjectname"
                                                                    className="form-control"
                                                                    placeholder="Enter Subject Name"
                                                                    type="text"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.subjectname || ""}
                                                                    invalid={
                                                                        validation.touched.subjectname && validation.errors.subjectname ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.subjectname && validation.errors.subjectname ? (
                                                                    <FormFeedback type="invalid"><div>{validation.errors.subjectname}</div></FormFeedback>
                                                                ) : null}
                                                            </div>
                                                        </Col>

                                                        {/* <div className="mb-3">
                                                            <h5 className="fs-14 mb-1">Subject Image</h5>
                                                            <p className="text-muted">Add Subject Image.</p>
                                                                <input
                                                                    className="form-control"
                                                                    id="subjectfile"
                                                                    name="subjectfile"
                                                                    type="file"
                                                                    accept="image/png, image/gif, image/jpeg"
                                                                    onChange={addFile}
                                                                />
                                                        </div> */}
                                        
                                                        <div className="mb-3">
                                                            <Label className="form-label">Subject Description</Label>
                                                            <div>
                                                                <div ref={quillRef} />
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                    <div className="text-end mb-4">
                                                        <button type="submit" className="btn btn-success w-sm mr-2" style={{ 'marginRight' : '10px'}} disabled={validation.isSubmitting}>Create</button>

                                                        <Link className="btn btn-warning w-sm me-1" to="/subjects">Cancel</Link>
                                                    </div>
                                                </Card>
                                            </Col>

                                            <Col lg={4}>
                                                <div className="card subjects-action">
                                                    <div className="card-header">
                                                        <h5 className="card-title mb-0">Parent</h5>
                                                    </div>
                                                    <CardBody>
                                                        <div className="text-center mb-4">
                                                            <Input
                                                                name="parent"
                                                                type="select"
                                                                className="form-select"
                                                                id="parent"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={
                                                                    validation.values.parent || ''
                                                                }
                                                                >
                                                                <option value="">Select Parent</option>
                                                                {parentSubjectList && parentSubjectList.map(subject => (
                                                                    <option key={subject.SubjectId} value={subject.SubjectId}>{subject.SubjectName}</option>
                                                                ))};
                                                            </Input>
                                                            {validation.touched.status &&
                                                            validation.errors.status ? (
                                                            <FormFeedback type="invalid">
                                                                {validation.errors.status}
                                                            </FormFeedback>
                                                            ) : null}
                                                        </div>

                                                        <div className="mt-3">
                                                                <Label className="form-label" htmlFor="showOnWeb"> Show on web </Label>

                                                                <Input 
                                                                    id="showonweb"
                                                                    name="showonweb" 
                                                                    className="form-check-input" 
                                                                    type="checkbox"
                                                                    onClick={changeChecked}
                                                                    style={{'marginLeft': '5px' }}
                                                                />
                                                            </div>
                                                    </CardBody>
                                                </div>
                                            </Col> 
                                        </Row>
                                    </Form> 
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default SubjectCreate;
