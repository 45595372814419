import React, { useEffect, useState, useMemo } from 'react';
import { 
    Card, 
    CardBody, 
    Col, 
    Nav, 
    NavItem, 
    NavLink,
    Row, 
    TabContent, 
    TabPane,
    Container
} from 'reactstrap';
import classnames from 'classnames';
import { useHistory, useParams, Link } from 'react-router-dom';
//redux
import { useSelector, useDispatch } from "react-redux";

import { useTranslation } from 'react-i18next';

import { getGroupAdminData } from '../../../store/general/groupadmin/actions';

import BreadCrumb from '../../../Components/Common/BreadCrumb';

const GroupAdminEdit = (props) => {
    
    const dispatch  = useDispatch();
    const { id } = useParams();
    const history = useHistory();

    const { t, i18n } = useTranslation();

    const { groupAdminData } = useSelector((state) => ({
        groupAdminData: state.GroupAdmin.groupAdminData
    }));

    const query = useMemo( () => {
        return new URLSearchParams(history.location.search);
    },[history]);

    useEffect(() => {
        dispatch(getGroupAdminData(id));
    }, [dispatch,id]);

    const [title, setTitle] = useState('');

    //Tab 
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    useEffect(() => {
        if (typeof groupAdminData !== 'undefined' && groupAdminData.GroupName) setTitle(groupAdminData.GroupName);
    }, [groupAdminData]);

    useEffect(() => {
        if (query.get('active') !== null)  {
            setActiveTab(query.get('active'));
        } else {
            setActiveTab('1');
        }
    }, [query]);
    
    document.title=t(`Stison Publishing Solutions | Edit Group Admin`);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={t(`Edit Group Admin`)} pageTitle="Detail" />
                    <Row>
                        <Col lg={12}>
                            <Card className="mt-n4 mx-n4">
                                <div className="bg-soft-warning">
                                    <CardBody className="pb-0 px-4">
                                        <Row className="mb-3">
                                            <div className="col-md">
                                                <Row className="align-items-center g-3">
                                                    <div className="col-md mt-4 ">
                                                        <div>
                                                            <h4 className="fw-bold">{ title ? t('Editing') + ' - ' + t(title) : t('Create Group Admin') }</h4>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                        </Row>
                                        <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                                            <NavItem>
                                                <Link 
                                                    className={classnames({ active: activeTab === '1' }, "nav-link fw-semibold")} 
                                                    to={`/group-admin/group-details/${id}?active=1`}
                                                    >
                                                    <i className="ri-group-line me-1 align-bottom"></i>{" "}
                                                    {t('Group Details')} 
                                                </Link>
                                            </NavItem>
                                            <NavItem>
                                                <Link 
                                                    className={classnames({ active: activeTab === '2' }, "nav-link fw-semibold")}
                                                    to={`/group-admin/menu/${id}?active=2`}
                                                    >
                                                    <i className="ri-menu-line me-1 align-bottom"></i>{" "}
                                                    {t('Menu')} 
                                                </Link>
                                            </NavItem>
                                            <NavItem>
                                                <Link 
                                                    className={classnames({ active: activeTab === '3' }, "nav-link fw-semibold")} 
                                                    to={`/group-admin/tabs/${id}?active=3`}
                                                    >
                                                    <i className="ri-menu-5-fill me-1 align-bottom"></i>{" "}
                                                    {t('Tabs')} 
                                                </Link>
                                            </NavItem>
                                            <NavItem>
                                                <Link 
                                                    className={classnames({ active: activeTab === '4' }, "nav-link fw-semibold")} 
                                                    to={`/group-admin/user-details/${id}?active=4`}
                                                    >
                                                    <i className="ri-user-search-fill me-1 align-bottom"></i>{" "}
                                                    {t('User Details')} 
                                                </Link>
                                            </NavItem>
                                        </Nav>
                                    </CardBody>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            {props.children}
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default GroupAdminEdit;
