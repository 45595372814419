import React, { useEffect, useState } from "react";
import { 
    Row, 
    Col, 
    CardBody, 
    Card,
    Alert, 
    Container, 
    Input, 
    Label, 
    Form, 
    FormFeedback,
    Table,
    ListGroup,
    ListGroupItem
} from "reactstrap";

import BreadCrumb from '../../../../Components/Common/BreadCrumb';

// import {
//     getSubjectData, 
//     getParentSubjects,
//     updateSubject,
//     resetFlagValues,
//     resetSubjectValues
// } from "../../../../store/titles/subjects/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";

import { Editor} from '@tinymce/tinymce-react';

import FailedModal from "../../../../Components/Common/FailedModal";
import SuccessModal from "../../../../Components/Common/SuccessModal";

import GroupAdminEdit from "../GroupAdminEdit";

const GroupAdminMenu = (props) => {
    const dispatch  = useDispatch();
    const { t, i18n } = useTranslation();
    const { history, id, setTitle } = props;

    return (
        <React.Fragment>
            <GroupAdminEdit>
                <Row>
                    <Col lg={12} >
                        <Card>
                            <CardBody>
                                <div className="table-responsive">
                                    <Table className="align-middle table-nowrap mb-0">
                                        <tbody>
                                            {/* <tr>
                                                <td>
                                                    <div className="form-check">
                                                        <Input className="form-check-input" type="checkbox" id="inlineCheckbox2" />
                                                        <Label> checkbox 1</Label>
                                                    </div>
                                                    <ul className="list-unstyled">
                                                        <li>
                                                            <div className="form-check">
                                                                <Input className="form-check-input" type="checkbox" id="inlineCheckbox2" />
                                                                <Label> checkbox 1</Label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="form-check">
                                                                <Input className="form-check-input" type="checkbox" id="inlineCheckbox2" />
                                                                <Label> checkbox 1</Label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="form-check">
                                                                <Input className="form-check-input" type="checkbox" id="inlineCheckbox2" />
                                                                <Label> checkbox 1</Label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="form-check">
                                                                <Input className="form-check-input" type="checkbox" id="inlineCheckbox2" />
                                                                <Label> checkbox 1</Label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="form-check">
                                                                <Input className="form-check-input" type="checkbox" id="inlineCheckbox2" />
                                                                <Label> checkbox 1</Label>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="form-check">
                                                                <Input className="form-check-input" type="checkbox" id="inlineCheckbox2" />
                                                                <Label> checkbox 1</Label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </td>
                                                <td>
                                                    <div className="form-check">
                                                        <Input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option1" />
                                                        checkbox 1
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form-check">
                                                        <Input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option1" />
                                                        checkbox 1
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form-check">
                                                        <Input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option1" />
                                                        checkbox 1
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form-check">
                                                        <Input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option1" />
                                                        checkbox 1
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form-check">
                                                        <Input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option1" />
                                                        checkbox 1
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form-check">
                                                        <Input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option1" />
                                                        checkbox 1
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form-check">
                                                        <Input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option1" />
                                                        checkbox 1
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form-check">
                                                        <Input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option1" />
                                                        checkbox 1
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form-check">
                                                        <Input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option1" />
                                                        checkbox 1
                                                    </div>
                                                </td>
                                            </tr> */}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </GroupAdminEdit>
        </React.Fragment>
    );
};

export default GroupAdminMenu;
