import React, { useState, useEffect} from "react";
import { Link } from 'react-router-dom';

//redux
import { useSelector, useDispatch } from "react-redux";
//i18n
import { useTranslation } from "react-i18next";

import DeleteModal from "../../../Components/Common/DeleteModal";
import SuccessModal from "../../../Components/Common/SuccessModal";
import { DropdownItem, DropdownToggle, UncontrolledDropdown, DropdownMenu } from "reactstrap";

import { deleteTheme } from "../../../store/titles/themes/actions";

const ThemesList = (props) => {
    const dispatch = useDispatch();
    const { history, list } = props;
    const { t } = useTranslation();

    const [thList, setThList] = useState([]);
    const [themes, setThemes] = useState([]);

    // Delete Subjects
    const [deleteModal, setDeleteModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);

    const onClickDelete = (themes) => {
        setThemes(themes);
        setDeleteModal(true);
    };

    const handleDeleteTheme = () => {
        if (themes.ThemeId) {
            dispatch(deleteTheme(themes));
            setDeleteModal(false);

            list.map((item, index) => { 
                if (themes.ThemeId === item.ThemeId) {
                    list.splice(index, 1);
                }

                return true;
            });
            
            setThList(list);

            setTimeout(() => { 
                setSuccessModal(true);
            }, 500);
        }
    };

    const successModalAction = () => {
        // reset all after success
        setSuccessModal(false);
    };

    const multipleItems = e => {
        props.subjectsArray.push(e.target.value);
    };

    useEffect(() => {
        setThList(list);
    }, [list]);

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteTheme}
                onCloseClick={() => setDeleteModal(false)}
            />
            <SuccessModal
                show={successModal}
                onCloseClick={successModalAction}
                Message="Successfully deleted."
            />

            {thList && thList.map((theme, index) => (
                <tr key={index}>
                    <th scope="row">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" name="chk_child" value={theme.ThemeId}
                                onChange={e => {
                                    multipleItems(e);
                                }}
                             />
                        </div>
                    </th>
                    <td>
                        <Link className="list-name" to={`/themes/general/${theme.ThemeId}`} >{theme.ThemeName}</Link>
                    </td>
                    <td>{theme.ThemeCode}</td>
                    <td>{(theme.parent ? theme.parent.ThemeName : '')}</td>
                    <td>
                        <div className="d-inline-flex gap-2">
                            <div className="edit">
                                <Link className="btn btn-sm btn-success edit-item-btn" to={`/themes/general/${theme.ThemeId}`} >{t("Edit")}</Link>
                            </div>
                            <div className="remove">
                                <button className="btn btn-sm btn-danger remove-item-btn" data-bs-toggle="modal" onClick={() => { onClickDelete(theme); }}>{t("Remove")}</button>
                            </div>
                        </div>
                    </td>
                </tr>
            ))}
        </React.Fragment>
    );
};


export default ThemesList;