import React, { useEffect } from 'react';
import { withRouter, useLocation } from 'react-router-dom';

//redux
import { useSelector } from "react-redux";

import { useProfile } from '../Components/Hooks/UserHooks';

import { publicRoutes } from '../Routes/allRoutes';

const NonAuthLayout = (props) => {

    const { history } = props;
    const { userProfile, loading } = useProfile();
    const location = useLocation();

    const availablePublicRoutesPaths = publicRoutes.map((r) => r.path);

    // check user already loggedin
    if (userProfile && !loading && availablePublicRoutesPaths.includes(location.pathname)) history.push("/dashboard");

    const {
        layoutModeType,
    } = useSelector(state => ({
        layoutModeType: state.Layout.layoutModeType,
    }));

    useEffect(() => {
        if (layoutModeType === "dark") {
            document.body.setAttribute("data-layout-mode", "dark");
        } else {
            document.body.setAttribute("data-layout-mode", "light");
        }
    }, [layoutModeType]);
    
    return (
        <div>
            {props.children}
        </div>
    );
};

export default withRouter(NonAuthLayout);