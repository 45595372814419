import React, { useEffect, useState } from "react";
import { 
    Row, 
    Col, 
    CardBody, 
    Card,
    Alert, 
    Container, 
    Input, 
    Label, 
    Form, 
    FormFeedback,
    Nav, NavItem, NavLink, TabContent, TabPane
} from "reactstrap";

import BreadCrumb from '../../../../Components/Common/BreadCrumb';

import {
    getSubjectData, 
    getParentSubjects,
    updateSubject,
    resetFlagValues,
    resetSubjectValues
} from "../../../../store/titles/subjects/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { useTranslation } from "react-i18next";

import { Editor} from '@tinymce/tinymce-react';

import FailedModal from "../../../../Components/Common/FailedModal";
import SuccessModal from "../../../../Components/Common/SuccessModal";

import SubjectEdit from "../SubjectEdit";

const SubjectsGeneral = (props) => {
    const dispatch  = useDispatch();
    const { t, i18n } = useTranslation();
    const { history, id, setTitle } = props;

    // subjectList Temporary Parent Data
    const { subjectData, parentList, error, success, isSubjectUpdate } = useSelector((state) => state.Subjects);
    
    const subjectId = props.match.params.id;

    const [checked, setChecked] = useState(true);
    const [failedModal, setFailedModal] = useState(false);
    const [successModal, setSuccessModal] = useState(false);
    const [parentSubjectList, setParentSubjectList] = useState([]);
    const [txtEditor, setTxtEditor] = useState('');

    useEffect(() => {
        dispatch(getSubjectData(subjectId));
    }, [dispatch, subjectId]);

    // get Parent Subject Data
    useEffect(() => {
        dispatch(getParentSubjects());
     }, [dispatch]);

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            subjectcode: subjectData?.SubjectCode || "",
            subjectname:  subjectData?.SubjectName || "",
            parent: subjectData?.Parent || "",
            showonweb:  subjectData?.ShowOnWeb &&  subjectData?.ShowOnWeb === 1 ? true : false,
        },
        validationSchema: Yup.object({
            subjectcode: Yup.string().required("Please Enter Subject Code"),
            subjectname: Yup.string().required("Please Enter Subject Name"),
        }),
        onSubmit: (values, { setSubmitting }) => {

            let { SubjectId, SubjectCode, SubjectName, Parent } = subjectData;

            let subjectFormData = {
                SubjectId,
                SubjectCode: values.subjectcode,
                SubjectName: values.subjectname,
                Parent: values.parent,
                SubjectDesc: txtEditor,
                ShowOnWeb: checked,
            };

            const fetchData = async () => {
                dispatch(updateSubject(subjectFormData));
            };
        
            fetchData();
        }
    });

    useEffect(() => {
        if (typeof subjectData !== 'undefined' && subjectData.SubjectDesc) setTxtEditor(subjectData.SubjectDesc);
    }, [subjectData]);

    useEffect( () => {
        if (parentList) setParentSubjectList(parentList);
    }, [parentList]);

    useEffect(() => {
        if (error && subjectData) {
            setFailedModal(true);
            setTimeout(() => {
                dispatch(resetFlagValues());
            }, 1000);
        }
        
        if (success && !error) {
            setSuccessModal(true);
        }

        if (isSubjectUpdate) {
            const fetchData = async () => {
                dispatch(resetFlagValues());
                await dispatch(getSubjectData(subjectId))
                await dispatch(getParentSubjects());
            };
        
            fetchData();
        }

    }, [dispatch, error, success, subjectData, isSubjectUpdate, subjectId]);

    const changeChecked = (e) =>  {
        setChecked(e.target.checked);
    };

    const successModalAction = () => {
        setSuccessModal(false);
    };

    const handleChangeEditor = (e) => {
        setTxtEditor(e.target.getContent());
    };
    return (
        <React.Fragment>
            <SubjectEdit>
                <FailedModal
                    show={failedModal}
                    onCloseClick={() => setFailedModal(false)}
                /> 

                <SuccessModal
                    show={successModal}
                    onCloseClick={successModalAction}
                    Message="Successfully updated subject."
                />

                <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }} 
                className="needs-validation" action="#">
                    
                    <Row>
                        <Col lg={8}>
                            <Card>
                                <CardBody>
                                    <div className="mb-3">
                                        <Label className="form-label" htmlFor="subjectcode">{t('Code')}<span className="text-danger">*</span></Label>
                                        <Input
                                            id="subjectcode"
                                            name="subjectcode"
                                            className="form-control"
                                            placeholder={t('Code')}
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.subjectcode || ""}
                                            invalid={
                                                validation.touched.subjectcode && validation.errors.subjectcode ? true : false
                                            }
                                        />
                                        {validation.touched.subjectcode && validation.errors.subjectcode ? (
                                            <FormFeedback type="invalid"><div>{validation.errors.subjectcode}</div></FormFeedback>
                                        ) : null}
                                    </div>

                                    <div className="mb-3">
                                        <Label className="form-label" htmlFor="subjectname">{t('Subject Name')}<span className="text-danger">*</span></Label>
                                        <Input
                                            id="subjectname"
                                            name="subjectname"
                                            className="form-control"
                                            placeholder={t('Subject Name')}
                                            type="text"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.subjectname || ""}
                                            invalid={
                                                validation.touched.subjectname && validation.errors.subjectname ? true : false
                                            }
                                        />
                                        {validation.touched.subjectname && validation.errors.subjectname ? (
                                            <FormFeedback type="invalid"><div>{validation.errors.subjectname}</div></FormFeedback>
                                        ) : null}
                                    </div>

                                    <div className="mb-3">
                                        <Label className="form-label">{t('Subject Desc')}</Label>
                                        <Editor
                                            initialValue={subjectData?.SubjectDesc}
                                            init={{
                                                plugins: 'link image code',
                                                toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                                            }}
                                            onChange={handleChangeEditor}
                                            apiKey='plgopn4aim97g0b5a7yexve7zj5hv8l698dp5c0agq6eillf'
                                        />
                                    </div>
                                </CardBody>
                                <div className="mb-3 text-end">
                                    <button type="submit" className="btn btn-primary w-sm mr-2" style={{ 'marginRight' : '10px'}}>
                                        {t('Update')}
                                    </button>

                                    <Link className="btn btn-warning w-sm me-1" to="/subjects">{t('Cancel')}</Link>
                                </div>
                            </Card>
                        </Col>

                        <Col lg={4}>
                            <Card>
                                <CardBody>
                                    <div className=" mb-3">
                                        <Label className="form-label " htmlFor="parent">{t('Parent')}</Label>
                                        <Input
                                            name="parent"
                                            type="select"
                                            className="form-select"
                                            id="parent"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={
                                                validation.values.parent || ''
                                            }
                                            >
                                            <option value="">{t('Select Parent')}</option>
                                            {parentSubjectList && parentSubjectList.map(subject => (
                                                (subjectId !== subject.SubjectId ? <option key={subject.SubjectId} value={subject.SubjectId}>{subject.SubjectName}</option> : '')
                                            ))};
                                        </Input>
                                        {validation.touched.status &&
                                        validation.errors.status ? (
                                        <FormFeedback type="invalid">
                                            {validation.errors.status}
                                        </FormFeedback>
                                        ) : null}
                                    </div>

                                    <div className="form-check form-check-outline form-check-right form-check-primary mt-3 ">
                                        <Label className="form-label " htmlFor="showOnWeb">{t('Show on web')}</Label>
                                        <Input 
                                            id="showonweb"
                                            name="showonweb" 
                                            className="form-check-input" 
                                            type="checkbox"
                                            onClick={changeChecked}
                                            defaultChecked={subjectData?.ShowOnWeb}
                                            style={{'marginLeft': '5px' }}
                                        />
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </SubjectEdit>
        </React.Fragment>
    );
};

export default SubjectsGeneral;
