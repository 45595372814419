import React, { useState, useEffect } from 'react';

//redux
import { useSelector, useDispatch } from "react-redux";
import { 
  Card, 
  CardBody,
  CardHeader,
  Col, 
  Container, 
  Row,
  Input, 
  Label, 
  Table,
  Button,
  Modal, 
  ModalBody, 
  ModalHeader,
  Form,
  Spinner
} from 'reactstrap';


import { Link } from 'react-router-dom';   
import { useProfile } from '../../../Components/Hooks/UserHooks';
import { getOrganisation } from '../../../store/general/organisation/actions';

import BreadCrumb from '../../../Components/Common/BreadCrumb';
import Pagination from '../../../Components/Common/Pagination';

const Organisation = (props) => {
    const dispatch = useDispatch();

    const { userProfile, loading } = useProfile();
    const { organisationList, response, error } = useSelector((state) => ({
        organisationList: state.Organisation.organisationList,
        response: state.Organisation.response,
    }));

    const [list, setList] = useState([]);

    useEffect(() => {
        dispatch(getOrganisation());
    }, [dispatch]);

    useEffect(() => {
      if (organisationList.data && organisationList.data.length) setList(organisationList.data);
  }, [organisationList]);

    const checkOrg = () => {

    };

    document.title="Stison Publishing Solutions | Organisation";
  
    return (
      <React.Fragment>      
        <div className="page-content">
          <Container fluid>
            <BreadCrumb title="Organisation" pageTitle="Page" />
            <Row>
              <Col xs={12}>
              <Card>
                  <CardHeader>
                      <div className="d-flex align-items-center">
                          <h4 className="card-title mb-0 flex-grow-1">Organisation</h4>
                          <div className="flex-shrink-0">
                              <Button className="btn btn-soft-danger"
                              ><i className="ri-delete-bin-2-line"></i></Button>
                          </div>
                      </div>
                  </CardHeader>
                  <CardBody >
                    <div id="pendingList">
                        <Row className="g-4 mb-3 ">
                            <form>
                                <div className="row g-3">
                                <div className="col-xxl-5 col-sm-12">
                                    <div className="search-box">
                                    <input type="text" className="form-control search bg-light border-light" placeholder="Search for organisation" />
                                    <i className="ri-search-line search-icon"></i>
                                    </div>
                                </div>

                                <div className="col-xxl-1 col-sm-4">
                                    <button type="button" className="btn btn-primary"> <i className="ri-equalizer-fill me-1 align-bottom"></i>
                                        Search
                                    </button>
                                </div>
                                </div>
                            </form>
                        </Row>

                        <div className="table-responsive table-card mt-3 mb-1">
                            <table className="table align-middle table-nowrap table-striped " id="organisationTable">
                                <thead className="table-light">
                                    <tr>
                                        <th scope="col" style={{ width: "50px" }}>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="checkAll" value="option" 
                                                onChange={e => {
                                                    checkOrg(e.target.value);
                                                }}
                                                />
                                            </div>
                                        </th>
                                        <th className="sort" data-sort="organisation">Organisation Name</th>
                                        <th className="sort" data-sort="account">Account #</th>
                                        <th className="sort" data-sort="phone">Phone</th>
                                        <th className="sort" data-sort="web">Web</th>
                                        <th className="sort" data-sort="address">Address</th>
                                        <th className="sort" data-sort="created">Created</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className="list form-check-all">
                                    {list && list.map(organisation => (
                                        <tr key={organisation.OrganisationId}>
                                            <th scope="row">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" name="chk_child" value={organisation.OrganisationId}
                                                        onChange={e => {
                                                            checkOrg(e);
                                                        }}
                                                    />
                                                </div>
                                            </th>
                                            <td><Link className="btn btn-link p-0" to="#" >{organisation.OrganisationName}</Link></td>
                                            <td>{organisation.AccountNumber}</td>
                                            <td>{organisation.TelephoneNumber}</td>
                                            <td>{organisation.Website}</td>
                                            <td></td>
                                            <td></td>
                                            <td>
                                                <div className="hstack gap-3 flex-wrap">
                                                    <Link to="#" className="link-success fs-15" 
                                                    ><i className="ri-edit-2-line"></i></Link>
                                                    <Link to="#" className="link-danger fs-15"><i className="ri-delete-bin-line"></i></Link>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className="noresult" style={{ display: "none" }}>
                                <div className="text-center">
                                    <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                        colors="primary:#121331,secondary:#08a88a" style={{ width: "75px", height: "75px" }}>
                                    </lord-icon>
                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                    <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                                        orders for you search.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Pagination 
                            typeFunction={getOrganisation}
                            currentPage={organisationList.current_page}
                            firstPageUrl={organisationList.first_page_url}
                            from={organisationList.from}
                            lastPage={organisationList.last_page}
                            lastPageUrl={organisationList.last_page_url}
                            links={organisationList.links}
                            nextPageUrl={organisationList.next_page_url}
                            path={organisationList.path}
                            perPage={organisationList.per_page}
                            prevPageUrl={organisationList.prev_page_url}
                            to={organisationList.to}
                            total={organisationList.total}
                        />
                    </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
};

export default Organisation;